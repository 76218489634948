import { API_HOST, API_VERSION } from "../config";
import axios from "axios";
import TokenHandler from "./base";
import { IService, IPaginationModal } from "types/App";

async function fetchAll(paginationModel?: IPaginationModal) {
  const token = TokenHandler.getToken();
  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/itineraries`);
  url.search = new URLSearchParams(filteredPagination).toString();

  const params = {
    ...(paginationModel?.query && { query: paginationModel.query }),
    ...(paginationModel?.sort && { sort: paginationModel.sort }),
  };
  const response = await axios.get(url.toString(), {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function fetch(id: string) {
  const token = TokenHandler.getToken();
  const response = await axios.get(`${API_HOST}/itineraries/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function create(data: any) {
  const token = TokenHandler.getToken();
  const response = await axios.post(`${API_HOST}/itineraries`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function update(id: string, data: any) {
  const token = TokenHandler.getToken();
  const response = await axios.put(`${API_HOST}/itineraries/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function remove(id: string) {
  const token = TokenHandler.getToken();
  const response = await axios.delete(`${API_HOST}/itineraries/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

const itinerariesService: IService = {
  fetch,
  fetchAll,
  create,
  update,
  remove,
};

export default itinerariesService;
