import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  Snackbar,
  FormLabel, 
  Icon,
  IconButton,
} from "@mui/material";
import LoadingOverlay from "components/LoadingOverlay";
import { useAuth } from "context/Auth";
import tagGroupsService from "services/tagGroups";
import { useDropzone } from "react-dropzone";
import { upload } from "services/images";
import { ITagGroup } from "types/tagGroups";
import { ITag } from "types/tags";
import { TagGrid, renderHeader, renderTagRow } from "./TagGroup.Styles";
import AddTagModal from "./AddTagModal";
import { useGlobalContext } from "context/GlobalContext";
import BasicLayout from "components/Layouts/BasicLayout";
import { ChevronLeft } from "@mui/icons-material";
import Image from "components/Images/Image";
import { IImage } from "types/image";

const slugify = (text: string) => {
  return text
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, "")
    .replace(/\s+/g, "-");
};

const TagGroup: React.FC = () => {
  const [tagGroup, setTagGroup] = useState<ITagGroup | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [addTagModalOpen, setAddTagModalOpen] = useState(false);
  const [error, setError] = useState("");
  const { loading: contextLoading, tags, refetchTags } = useGlobalContext();

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleSave = async () => {
    try {
      setLoading(true);
      const newTagGroup: ITagGroup = {
        ...tagGroup,
      };

      if (!newTagGroup?.title || !newTagGroup?.description || !tagGroup?.thumbnail) {
        throw new Error("Please fill in all fields");
      }

      if (id === "create") {
        // TODO Check to make sure it doesn't already exist
        newTagGroup.createdBy = user;
        newTagGroup.createdDate = new Date().toISOString();
        await tagGroupsService.create(newTagGroup);
      } else {
        newTagGroup.updatedBy = user;
        newTagGroup.updatedAt = new Date().toISOString();
        await tagGroupsService.update(newTagGroup.id, newTagGroup);
      }
      refetchTags();
      //on successful save, navigate back to tag groups
      navigate("/tag-groups");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarMessage(null);
  };

  const handleModalConfirm = (selectedTagId: string) => {
    const newTag = tags.find((tag) => tag.id === selectedTagId);
    selectedTagId &&
      setTagGroup(
        tagGroup?.tags?.length > 0
          ? { ...tagGroup, tags: [newTag, ...tagGroup?.tags] }
          : { ...tagGroup, tags: [newTag] }
      );
    setAddTagModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      await tagGroupsService.remove(id);
      refetchTags();
      navigate("/tag-groups");
    } catch (error) {
      console.error("Error deleting tag group:", error);
    }
  };

  const handleRemoveTag = (tagToRemove: ITag) => {
    const currentTags = [...tagGroup.tags];
    const filteredTags = currentTags.filter((tag) => tag.id !== tagToRemove.id);
    setTagGroup({ ...tagGroup, tags: [...filteredTags] });
  };

  //shifts tag up or down in array
  const moveTag = ({ index, newIndex }: { index: number; newIndex: number }) => {
    const currentTagArray = [...tagGroup.tags];
    if (
      index < 0 ||
      newIndex < 0 ||
      index >= currentTagArray.length ||
      newIndex >= currentTagArray.length
    ) {
      return;
    }
    currentTagArray.splice(newIndex, 0, currentTagArray.splice(index, 1)[0]);
    setTagGroup({ ...tagGroup, tags: [...currentTagArray] });
  };

  //load tag group if not creating new tag group and fetch all existing tags
  useEffect(() => {
    const fetchTagGroup = async () => {
      try {
        const tagGroup: ITagGroup = await tagGroupsService.fetch(id);
        setTagGroup(tagGroup);
      } catch (e) {
        console.error("Error fetching tag group:", e);
      } finally {
        setLoading(false);
      }
    };

    if (id && id !== "create") {
      fetchTagGroup();
    }
  }, [id]);

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Tag Group</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3">Tag Group Details</Typography>
        </Grid>
        <Grid container direction={"row"} spacing={2}>
          <Grid item xs={12} md={5} marginTop={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px '}}>
            <Image
              onAccept={(image: IImage) => setTagGroup({ ...tagGroup, image })}
              src={tagGroup?.thumbnail}
              alt={tagGroup?.title}
              bucketPath="cityview-cms/tags"
            />
          </Grid>
          <Grid item xs={12} md={7} spacing={2}>
            <Grid item>
              <FormControl fullWidth>
                <FormLabel required htmlFor="tag-group-name">
                  Name
                </FormLabel>
                <TextField
                  id="tag-group-name"
                  value={tagGroup?.title || ""}
                  required
                  onChange={(e) =>
                    setTagGroup({
                      ...tagGroup,
                      title: e.target.value,
                      slug: slugify(e.target.value),
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <FormLabel required htmlFor="tag-group-name">
                  Slug
                </FormLabel>
                <TextField id="tag-group-name" value={tagGroup?.slug || ""} disabled />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <FormLabel required htmlFor="description">
                  Description
                </FormLabel>
                <TextField
                  id="description"
                  value={tagGroup?.description || ""}
                  required
                  onChange={(e) => setTagGroup({ ...tagGroup, description: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end" marginTop={2}>
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                onClick={() => setAddTagModalOpen(true)}
              >
                <span style={{ lineHeight: "16px" }}>ADD TAG</span>
                <Icon>add</Icon>
              </Button>
            </Grid>
          </Grid>
          <Typography variant="h4" marginLeft={2}>Tags</Typography>
          <Grid id={"tags"} container marginLeft={2} marginTop={2} style={{ border: "1px solid #ccc", padding: "0" }}>
            <TagGrid>
              {renderHeader()}
              {tagGroup?.tags?.map((tag: ITag, index) => {
                return tag && renderTagRow(tag, index, moveTag, handleRemoveTag);
              })}
            </TagGrid>
          </Grid>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} />
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
        <LoadingOverlay loading={loading || contextLoading} />
        <Snackbar
          open={!!snackbarMessage}
          message={snackbarMessage}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        />
        <Grid item xs={12} />
        {id && id !== "create" && (
          <Grid item xs={12}>
            <Typography variant="h4" color="alert">
              Danger Zone
            </Typography>
            <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
              Delete
            </Button>
          </Grid>
        )}
        <AddTagModal
          handleModalConfirm={handleModalConfirm}
          tags={tags}
          addTagModalOpen={addTagModalOpen}
          setAddTagModalOpen={setAddTagModalOpen}
          tagGroup={tagGroup}
        />
      </Grid>
    </BasicLayout>
  );
};

export default TagGroup;
