import { useMemo, useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import LoginSignUp from "components/Layouts/LoginSignUp";

// Images
import { Box, FormControl, Input, InputLabel, TextField, Typography } from "@mui/material";
import { useAuth } from "../../context/Auth";
import LoadingOverlay from "components/LoadingOverlay";
import Logo from "assets/images/logos/logo_blue.png";
import darrenFaulknerImg from "assets/images/darren-faulkner-3FzYpZjE5Ec-unsplash.jpg";
import Form from "pages/Form";
import { Link as MUILink } from "@mui/material";

function Basic(): JSX.Element {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const { error, login, loading, isAuthenticated } = useAuth();

  const submitDisabled = useMemo(() => !email || !password, [email, password]);

  const handleLogin = async () => {
    try {
      await login(email, password);
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <Grid container height="100vh">
      <Grid item xs={12} md={4} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <LoginSignUp>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
              {loading ? (
                <LoadingOverlay />
              ) : (
                <img
                  key="title"
                  src={Logo}
                  width={300}
                  alt="CityView CMS Logo"
                />
              )}
            </Box>
            <Grid item xs={12} marginTop={2}>
              <Typography variant="h4" sx={{ textAlign: "center" }}>
                Sign In To Your Account
              </Typography>
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                Don't have an account?{" "}
                <MUILink component={Link} to="/sign-up" color="blue">
                  Sign Up
                </MUILink>
              </Typography>
            </Grid>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!submitDisabled) {
                  handleLogin();
                }
              }}
            >
              <MDBox pt={4} pb={3} px={12}>
                <Grid container justifyContent="center">
                  {error && (
                    <Grid item xs={12}>
                      <MDTypography style={{ textAlign: "center" }} variant="body2" color="error">
                        {error.message || "Something went wrong"}
                      </MDTypography>
                    </Grid>
                  )}
                  
                  <Grid item xs={12}>
                    <Typography variant="body2">Email</Typography>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} marginTop={2}>
                    <Typography variant="body2">Password</Typography>
                    <FormControl fullWidth>
                      <TextField
                        type="password"
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} marginTop={2}>
                    <MDButton
                      disabled={submitDisabled}
                      type="submit"
                      variant="contained"
                      fullWidth
                      color="info"
                    >
                      Sign In
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </form>
          </Grid>
        </LoginSignUp>
      </Grid>

      <Grid
        item
        md={8}
        sx={{
          backgroundImage: `url(${darrenFaulknerImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </Grid>
  );
}

export default Basic;
