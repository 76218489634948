import { IPaginationModal } from "types/App";
import { API_HOST, API_VERSION } from "../config";
import axios from "axios";
import TokenHandler from "./base";

async function fetchAll(paginationModel?: IPaginationModal) {
  const token = TokenHandler.getToken();
  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/authors`);

  url.search = new URLSearchParams(filteredPagination).toString();

  const params = {
    ...(paginationModel?.query && { query: paginationModel.query }),
    ...(paginationModel?.sort && { sort: paginationModel.sort }),
  };
  const response = await axios.get(url.toString(), {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}

async function fetch(id: string) {
  const token = TokenHandler.getToken();
  const response = await axios.get(`${API_HOST}/authors/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function create(data: any) {
  const token = TokenHandler.getToken();
  const path = new URL(`${API_HOST}/authors`);
  const response = await axios.post(path.toString(), data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function update(id: string, data: any) {
  const token = TokenHandler.getToken();
  const path = new URL(`${API_HOST}/authors/${id}`);
  const response = await axios.put(path.toString(), data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function remove(id: string) {
  const token = TokenHandler.getToken();
  const response = await axios.delete(`${API_HOST}/authors/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function upload(file: any) {
  const formData = new FormData();
  formData.append("file", file);

  const token = TokenHandler.getToken();
  const response = await axios.post(`${API_HOST}/authors/upload`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

const authorsService = {
  fetch,
  fetchAll,
  create,
  update,
  remove,
  upload,
};

export default authorsService;
