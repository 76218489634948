import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import { IFilterOptions } from "types/paginatedDataGrid";
import BasicLayout from "components/Layouts/BasicLayout";
import invitationsService from "services/invitations";

const Invitations: React.FC = () => {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "member.name", headerName: "Member", flex: 1, valueGetter: (params) => params.row.member?.name },
    { field: "event.title", headerName: "Event", flex: 1, valueGetter: (params) => params.row.event?.title },
    { field: "rsvp.status", headerName: "RSVP Status", flex: 1, valueGetter: (params) => params.row.rsvp?.status },
    { field: "createdDate", headerName: "Created Date", flex: 1, type: "dateTime" },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={5} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Invitations</Typography>
        </Grid>
        <Grid item xs={7} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/invitations/create">
            <Icon>add</Icon>
            Create Invitation
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              collectionName="invitations"
              defaultSortField="createdDate"
              defaultSortDirection="desc"
              columns={columns}
              service={invitationsService}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Invitations;