import { IUser as User } from 'types/users.d';
import { IEvent } from 'types/events.d'
import dayjs from "dayjs";

import isBetween from "dayjs/plugin/isBetween";
import { ALL_ADMINS } from "./Dashboard";
dayjs.extend(isBetween);

export enum DateRanges {
  ThisWeek = "This Week",
  LastWeek = "Last Week",
  ThisMonth = "This Month",
  Total = "Total",
}

export const getEventCountBreakdown = ({ events }: { events: IEvent[] }) => {
  const eventCount: { botCreatedEvents: number; userCreatedEvents: number; totalEvents: number } = {
    botCreatedEvents: 0,
    userCreatedEvents: 0,
    totalEvents: 0,
  };
  events.forEach((event) => {
    eventCount.totalEvents += 1;
    if (event.createdBy) {
      eventCount.userCreatedEvents += 1;
    } else {
      eventCount.botCreatedEvents += 1;
    }
  });
  return eventCount;
};

const getDateRange = (rangeString: DateRanges, optionalStartDate?: Date) => {
  const dateRange: { startDate: Date; endDate: Date } = {
    startDate: optionalStartDate,
    endDate: undefined,
  };
  const dateArray = [];
  const today = new Date();

  switch (rangeString) {
    case DateRanges.ThisWeek:
      dateRange.endDate = today;
      dateRange.startDate = dayjs(today).subtract(6, "day").toDate();
      break;
    case DateRanges.LastWeek:
      dateRange.endDate = dayjs(today).subtract(7, "day").toDate();
      dateRange.startDate = dayjs(today).subtract(13, "day").toDate();
      break;
    case DateRanges.ThisMonth:
      dateRange.endDate = dayjs(today).toDate();
      dateRange.startDate = dayjs(today).startOf("month").toDate();
      break;
    case DateRanges.Total:
      if (!optionalStartDate) {
        dateRange.endDate = dayjs(today).toDate();
        dateRange.startDate = dayjs(today).startOf("month").toDate();
      } else {
        dateRange.endDate = dayjs(today).toDate();
        dateRange.startDate = dayjs(optionalStartDate).toDate();
      }
      break;
    default:
      dateRange.endDate = today;
      dateRange.startDate = dayjs(today).subtract(6, "day").toDate();
  }

  //build array of dates in range
  let current = dayjs(dateRange.startDate);
  while (!current.isAfter(dateRange.endDate, "day")) {
    dateArray.push(current);
    current = current.add(1, "days");
  }

  return { dateArray, dateRange };
};

export const getAllAdmins = ({ events }: { events: IEvent[] }) => {
  const allUsers: User[] = [];
  events.forEach((event) => {
    //get all users that have updated an event
    const nextUpdatedUser = event?.updatedBy as User;
    const nextCreatedUser = event?.createdBy as User;

    if (nextUpdatedUser) {
      const existingUpdatedUserIndex = allUsers.findIndex(
        (testUser) => nextUpdatedUser?.name === testUser.name
      );

      if (existingUpdatedUserIndex === -1) {
        allUsers.push(nextUpdatedUser);
      }
      //get all users that have created an event
    }
    if (nextCreatedUser) {
      const existingCreatedUserIndex = allUsers.findIndex(
        (testUser) => nextCreatedUser?.name === testUser.name
      );
      if (existingCreatedUserIndex === -1) {
        allUsers.push(nextCreatedUser);
      }
    }
  });

  return allUsers;
};

export const getAdminActivitySummary = ({
  events,
  dateRange: rangeString,
  admin,
}: {
  events: IEvent[];
  dateRange: DateRanges;
  admin: string;
}) => {
  const getEarliestEventCreatedDate = () => {
    let earliestDate = new Date();
    events.forEach((event) => {
      if (event.createdDate && event.createdBy) {
        if (dayjs(event.createdDate).isBefore(dayjs(earliestDate))) {
          earliestDate = dayjs(event.createdDate).toDate();
        }
      }
    });
    return earliestDate;
  };

  //if the date range is "Total", get the earliest event created date
  const optionalStartDate =
    rangeString === DateRanges.Total ? getEarliestEventCreatedDate() : undefined;

  const dateRangeSummary = getDateRange(rangeString, optionalStartDate);

  const adminSummary: {
    updated: { date: string; count: number }[];
    created: { date: string; count: number }[];
  } = { updated: [], created: [] };

  dateRangeSummary.dateArray.forEach((date) => {
    adminSummary.updated.push({ date: date.toISOString(), count: 0 });
    adminSummary.created.push({ date: date.toISOString(), count: 0 });
  });

  events.forEach((event) => {
    if (!event.updatedBy && !event.createdBy) return;

    //get count for updated events
    if (event.updatedBy && event.updatedDate) {
      //only count events updated by the admin unless 'All' is selected
      if (event.updatedBy?.name !== admin && admin !== ALL_ADMINS) {
        return;
      }
      if (
        dayjs(event.updatedDate).isBetween(
          dateRangeSummary.dateRange.startDate,
          dateRangeSummary.dateRange.endDate,
          "day",
          "[]"
        )
      ) {
        const updatedIndex = adminSummary.updated.findIndex((update) => {
          return dayjs(update.date).isSame(event.updatedDate, "day");
        });
        // Add index validation
        if (updatedIndex >= 0 && updatedIndex < adminSummary.updated.length) {
          adminSummary.updated[updatedIndex].count += 1;
        }
      }
    }

    //get created count
    if (event.createdBy && event.createdDate) {
      //only count events created by the admin unless 'All' is selected
      if (
        typeof event.createdBy !== "string" &&
        event.createdBy?.name !== admin &&
        admin !== ALL_ADMINS
      ) {
        return;
      }
      if (
        dayjs(event.createdDate).isBetween(
          dateRangeSummary.dateRange.startDate,
          dateRangeSummary.dateRange.endDate,
          "day",
          "[]"
        )
      ) {
        const createdIndex = adminSummary.created.findIndex((create) =>
          dayjs(create.date).isSame(event.createdDate, "day")
        );
        // Add index validation
        if (createdIndex >= 0 && createdIndex < adminSummary.created.length) {
          adminSummary.created[createdIndex].count += 1;
        }
      }
    }
  });
  return adminSummary;
};

// export const getEventsCountByDayOfWeek = ({ events }: { events: IEvent[] }) => {
//   const eventsByDayOfWeek: { dayOfWeek: string; count: number }[] = [
//     { dayOfWeek: "Sunday", count: 0 },
//     { dayOfWeek: "Monday", count: 0 },
//     { dayOfWeek: "Tuesday", count: 0 },
//     { dayOfWeek: "Wednesday", count: 0 },
//     { dayOfWeek: "Thursday", count: 0 },
//     { dayOfWeek: "Friday", count: 0 },
//     { dayOfWeek: "Saturday", count: 0 },
//   ];
//   events.forEach((event) => {
//     const dayOfWeek = new Date(event.start).toLocaleString("en-US", { weekday: "long" });
//     const existingDayOfWeekIndex = eventsByDayOfWeek.findIndex(
//       (testDay) => dayOfWeek === testDay.dayOfWeek
//     );
//     if (existingDayOfWeekIndex >= 0) {
//       eventsByDayOfWeek[existingDayOfWeekIndex].count += 1;
//     } else {
//       eventsByDayOfWeek.push({ dayOfWeek, count: 1 });
//     }
//   });
//   return eventsByDayOfWeek;
// };
