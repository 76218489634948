/**
 * A global context to store the locations, tags, and tagGroups.
 */
import React, { createContext, useCallback, useState, useEffect, useMemo, ReactNode } from "react";
import tagGroupsService from "services/tagGroups";
import tagsService from "services/tags";
import locationsService from "services/locations";
import videosService from "services/videos";
import { ILocationData } from "types/locations.d";
import { ITag } from "types/tags";
import { ITagGroup } from "types/tagGroups";
import { IVideoData } from "types/videos.d";
import { IEvent } from "types/events.d";
import eventsService from "services/events";
import { IGroup } from "types/groups.d";
import { useAuth } from "./Auth";
import groupsService from "services/groups";

export const GlobalContext = createContext<{
  loading: boolean;
  locations: ILocationData[];
  tags: ITag[];
  tagGroups: ITagGroup[];
  videos: IVideoData[];
  events: IEvent[];
  groups: IGroup[];
  menuIsOpen: boolean;
  menuSectionExpanded: string | null;
  setMenuSectionExpanded: (value: string | null) => void;
  setMenuIsOpen: (value: boolean) => void;
  fetchDependencies: () => Promise<void>;
  refetchLocations: () => Promise<void>;
  refetchVideos: () => Promise<void>;
  refetchTags: () => Promise<void>;
  refetchEvents: () => Promise<void>;
  refetchGroups: () => Promise<void>;
}>(null);

export const GlobalContextProvider = ({ children }: { children: ReactNode }) => {
  const [loadingState, setLoadingState] = useState({
    locations: false,
    tags: false,
    videos: false,
    events: false,
    organizations: false,
  });
  const [locations, setLocations] = useState<ILocationData[]>([]);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [tags, setTags] = useState<ITag[]>([]);
  const [tagGroups, setTagGroups] = useState<ITagGroup[]>([]);
  const [videos, setVideos] = useState<IVideoData[]>([]);
  const [events, setEvents] = useState<IEvent[]>([]);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(true);
  const [menuSectionExpanded, setMenuSectionExpanded] = useState<string | null>(null);

  const { user } = useAuth();

  const fetchLocations = useCallback(async () => {
    setLoadingState((prev) => {
      return { ...prev, locations: true };
    });
    try {
      const _locations = await locationsService.fetchAll({ page: 0, pageSize: 2000 });
      setLocations(_locations.data);
    } catch (e) {
      console.error("Error fetching locations:", e);
    }
    setLoadingState((prev) => {
      return { ...prev, locations: false };
    });
  }, []);

  const fetchGroups = useCallback(async () => {
    setLoadingState((prev) => {
      return { ...prev, organizations: true };
    });
    try {
      const _organizations = await groupsService.fetchAll({ page: 0, pageSize: 2000 });
      setGroups(_organizations.data);
    } catch (e) {
      console.error("Error fetching organizations:", e);
    }
    setLoadingState((prev) => {
      return { ...prev, organizations: false };
    });
  }, []);

  const fetchEvents = useCallback(async () => {
    setLoadingState((prev) => {
      return { ...prev, events: true };
    });
    try {
      const _events = await eventsService.fetchAll({ page: 0, pageSize: 2000 });
      setEvents(_events.data);
    } catch (e) {
      console.error("Error fetching events:", e);
    }
    setLoadingState((prev) => {
      return { ...prev, events: false };
    });
  }, []);

  const fetchVideos = useCallback(async () => {
    setLoadingState((prev) => {
      return { ...prev, videos: true };
    });
    try {
      const _videos = await videosService.fetchAll({ page: 0, pageSize: 2000 });
      setVideos(_videos.data);
    } catch (e) {
      console.error("Error fetching videos:", e);
    }
    setLoadingState((prev) => {
      return { ...prev, videos: false };
    });
  }, []);

  const fetchTags = useCallback(async () => {
    setLoadingState((prev) => {
      return { ...prev, tags: true };
    });
    try {
      const _tagGroups = await tagGroupsService.fetchAll({ page: 0, pageSize: 2000 });
      setTagGroups(_tagGroups.data);
      const _tags = await tagsService.fetchAll({ page: 0, pageSize: 2000 });
      setTags(
        _tags.data.map((tag: any) => {
          return {
            ...tag,
            tagGroup: _tagGroups.data.find((tg: any) => tg.tags.some((t: any) => t.id === tag.id)),
          };
        })
      );
    } catch (e) {
      console.error("Error fetching tags:", e);
    }
    setLoadingState((prev) => {
      return { ...prev, tags: false };
    });
  }, []);

  const fetchDependencies = useCallback(async () => {
    if (!user) return;
    fetchLocations();
    fetchVideos();
    fetchTags();
    fetchEvents();
    fetchGroups();
  }, [fetchLocations, fetchVideos, fetchTags, fetchEvents, fetchGroups, user]);

  useEffect(() => {
    fetchDependencies();
  }, [fetchDependencies]);

  //if any of the fetches are loading, this will be true
  const loading = useMemo(
    () => Object.values(loadingState).some((value) => value === true),
    [loadingState]
  );

  const value = useMemo(() => {
    return {
      loading,
      locations,
      tags,
      tagGroups,
      videos,
      events,
      groups,
      menuIsOpen,
      menuSectionExpanded,
      setMenuIsOpen,
      setMenuSectionExpanded,
      fetchDependencies,
      refetchLocations: fetchLocations,
      refetchVideos: fetchVideos,
      refetchTags: fetchTags,
      refetchEvents: fetchEvents,
      refetchGroups: fetchGroups,
    };
  }, [
    loading,
    locations,
    tags,
    tagGroups,
    videos,
    events,
    groups,
    menuIsOpen,
    menuSectionExpanded,
    fetchDependencies,
    fetchLocations,
    fetchVideos,
    fetchTags,
    fetchEvents,
    fetchGroups,
  ]);

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export const useGlobalContext = () => React.useContext(GlobalContext);
