import { Editor, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Image from '@tiptap/extension-image';
import React, { useEffect, useState } from "react";
import { upload } from "services/images";
import { useDropzone } from "react-dropzone";

const MenuBar = ({ editor, showHtml, setShowHtml }: { editor: Editor | null, showHtml: boolean, setShowHtml: (show: boolean) => void}) => {

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true, // Prevent automatic click behavior
    noKeyboard: true,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles.length > 0 && editor) {
        const file = acceptedFiles[0];  // Assuming a single file selection
        if (file.type.startsWith('image/')) {
          const image = await upload(file, "your-bucket-path");
          editor.chain().focus().setImage({ src: image.url }).run();
        }
      }
    }
  });

  if (!editor) {
    return null;
  }

  return (
    <div className="control-group">
      <div className="button-group">
        {/* <button onClick={() => editor.chain().focus().clearNodes().run()}>
          <span className="material-symbols-outlined">format_clear</span>
        </button> */}
        <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
          <span className="material-symbols-outlined">format_clear</span>
        </button>

        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          <span className="material-symbols-outlined">format_bold</span>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
        >
          <span className="material-symbols-outlined">format_italic</span>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive("strike") ? "is-active" : ""}
        >
          <span className="material-symbols-outlined">format_strikethrough</span>
        </button>
        {/* <button
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={!editor.can().chain().focus().toggleCode().run()}
          className={editor.isActive("code") ? "is-active" : ""}
        >
          <Icon>code</Icon>
        </button> */}

        <button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive("paragraph") ? "is-active" : ""}
        >
          <span className="material-symbols-outlined">format_paragraph</span>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
        >
          <span className="material-symbols-outlined">format_h1</span>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
        >
          <span className="material-symbols-outlined">format_h2</span>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}
        >
          <span className="material-symbols-outlined">format_h3</span>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          className={editor.isActive("heading", { level: 4 }) ? "is-active" : ""}
        >
          <span className="material-symbols-outlined">format_h4</span>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
          className={editor.isActive("heading", { level: 5 }) ? "is-active" : ""}
        >
          <span className="material-symbols-outlined">format_h5</span>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
          className={editor.isActive("heading", { level: 6 }) ? "is-active" : ""}
        >
          <span className="material-symbols-outlined">format_h6</span>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          <span className="material-symbols-outlined">format_list_bulleted</span>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
        >
          <span className="material-symbols-outlined">format_list_numbered</span>
        </button>
        {/* <button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive("codeBlock") ? "is-active" : ""}
        >
          Code block
        </button> */}
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive("blockquote") ? "is-active" : ""}
        >
          <span className="material-symbols-outlined">format_quote</span>
        </button>
        <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
          <span className="material-symbols-outlined">horizontal_rule</span>
        </button>
        {/* <button onClick={() => editor.chain().focus().setHardBreak().run()}>
          <span className="material-symbols-outlined">keyboard_return</span>
        </button> */}
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <span className="material-symbols-outlined">undo</span>
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <span className="material-symbols-outlined">redo</span>
        </button>
        {/* <button
          onClick={() => editor.chain().focus().setColor("#958DF1").run()}
          className={editor.isActive("textStyle", { color: "#958DF1" }) ? "is-active" : ""}
        >
          Purple
        </button> */}
        
        <button
          onClick={() => setShowHtml(!showHtml)}
          className={showHtml ? "is-active" : ""}
        >
          <span className="material-symbols-outlined">code</span>
        </button>

        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <button type="button" onClick={open} style={{  border: 'none', outline: 'none', verticalAlign: 'middle'}}>
            <span className="material-symbols-outlined">cloud_upload</span>
          </button>
        </div>
      </div>
    </div>
  );
};

interface TipTapProps {
  content: JSON;
  onChange: (value: JSON) => void;
  bucketPath?: string;
  height?: number;
}

//https://stackoverflow.com/questions/73424668/tiptap-react-update-editor-initial-content-based-on-dropdown-selection
const TipTap = (props: TipTapProps) => {
  const { content, onChange, bucketPath, height = 300 } = props;
  const [showHtml, setShowHtml] = useState(false);
  const editor = useEditor({
    extensions: [
      StarterKit,
      Image.configure({
        inline: true,
        allowBase64: false, // Disable base64 images
      }),
    ],
    editorProps: {
      attributes: {
        style: `height: ${height}px;`
      }
    },
    content: content || "",
    onUpdate({ editor }) {
      const value = editor.getJSON() as JSON;
      onChange(value);
    },
  });

  useEffect(() => {
    if (!editor) return;
    let { from, to } = editor.state.selection;
    editor.commands.setContent(content, false, {
      preserveWhitespace: "full",
    });
    editor.commands.setTextSelection({ from, to });
  }, [editor, content]);

  const onImageUpload = async (file: File) => {
    const imageUrl = await upload(file, bucketPath );
    return imageUrl;
  };

  const handleDrop = async (event: React.DragEvent) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      const image = await onImageUpload(file);
      editor.chain().focus().setImage({ src: image.url }).run();
    }
  };

  const handlePaste = async (event: React.ClipboardEvent) => {
    const items = event.clipboardData?.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        event.preventDefault(); // Prevent default paste behavior
        const file = items[i].getAsFile();
        if (file) {
          try {
            const image = await onImageUpload(file);
            editor.chain().focus().setImage({ src: image.url }).run();
          } catch (error) {
            console.error('Failed to upload image:', error);
            // Optionally, show an error message to the user
          }
        }
      }
    }
  };

  return (
    <>
      <MenuBar editor={editor} showHtml={showHtml} setShowHtml={setShowHtml} />
      <div 
        className="tiptap-container" 
        onDrop={handleDrop}
        onPaste={handlePaste}
        onDragOver={(e) => e.preventDefault()}
      >
        {showHtml ? (
          <textarea
            value={editor?.getHTML()}
            readOnly
          />
        ) : (
          <EditorContent editor={editor}/>
        )}
      </div>
    </>
  );
};

export default TipTap;
