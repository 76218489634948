import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import invitationService from '../../services/invitations';

interface Invitation {
  id: string;
  name: string;
  // Add other properties as needed
}

interface InvitationSelectProps {
  value: string;
  onChange: (invitationId: string) => void;
}

const InvitationSelect: React.FC<InvitationSelectProps> = ({ value, onChange }) => {
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchInvitations = async () => {
      try {
        const fetchedInvitations = await invitationService.fetchAll();
        setInvitations(fetchedInvitations);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch invitations. Please try again.');
        setLoading(false);
      }
    };

    fetchInvitations();
  }, []);

  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value);
  };

  if (loading) return <p>Loading invitations...</p>;
  if (error) return <p>{error}</p>;

  return (
    <FormControl fullWidth>
      <InputLabel id="invitation-select-label">Invitation</InputLabel>
      <Select
        labelId="invitation-select-label"
        id="invitation-select"
        value={value}
        label="Invitation"
        onChange={handleChange}
      >
        {invitations.map((invitation) => (
          <MenuItem key={invitation.id} value={invitation.id}>
            {invitation.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default InvitationSelect;
