import React, { useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import LoadingOverlay from "components/LoadingOverlay";
import { upload } from "services/images";
import { IImage } from "types/image";

interface FileDropzoneProps {
  onUpload?: (image: IImage) => void;
  onComplete?: (results: any[]) => void; // Callback when all files are uploaded
  accept?: Record<string, string[]>; // File types to accept
  maxFiles?: number; // Maximum number of files allowed
  style?: React.CSSProperties;
  bucket?: string;
}

const FileDropzone: React.FC<FileDropzoneProps> = ({
  onUpload,
  onComplete,
  accept,
  maxFiles = 0,
  style = {},
  bucket = "cityview-cms/events",
}) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) return;

      try {
        setLoading(true);
        const results = [];

        for (const file of acceptedFiles) {
          if (onUpload) {
            const result = await upload(file, bucket);
            results.push(result);
            onUpload(result);
          }
        }

        if (onComplete) {
          onComplete(results);
        }
      } catch (error) {
        console.error("Error uploading files:", error);
      } finally {
        setLoading(false);
      }
    },
    [onUpload, onComplete, bucket]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles,
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        width: "100%",
        height: "200px",
        border: "1px solid #ccc",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        position: "relative",
        ...style,
      }}
    >
      <input {...getInputProps()} />
      <Typography align="center">
        {isDragActive
          ? "Drop the files here..."
          : "Drag 'n' drop files here, or click to select files"}
      </Typography>
      {loading && <LoadingOverlay loading={loading} />}
    </Box>
  );
};

export default FileDropzone; 