import { AppBar, Box, colors, Drawer, Icon, useMediaQuery, Divider, IconButton, Menu, Typography, Tooltip } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HomeIcon from '@mui/icons-material/Home';
import theme from "assets/theme";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { useAuth } from "context/Auth";
import { useGlobalContext } from "context/GlobalContext";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "assets/images/logos/logo_blue.png";
import CityViewLogoCity from "assets/images/logos/CityViewLogoCity.png";
import { ExpandLess } from "@mui/icons-material";
import styled from "@emotion/styled";
import NotificationItem from "components/Items/NotificationItem";
import { useMaterialUIController } from "context";
import {
  navbar,
  navbarContainer,
  navbarIconButton,
  navbarMobileMenu,
} from "components/Navbars/DashboardNavbar/styles";
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import TheaterComedyOutlinedIcon from '@mui/icons-material/TheaterComedyOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

export const ProfileImage = styled.img`
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
`;


interface Props {
  init?: any;
  children?: React.ReactNode;
}

interface Route {
  text: string;
  path: string;
}

const menuList = [
  {
    name: "Engagement",
    key: "engagementRoutes",
    icon: <PeopleOutlinedIcon fontSize="small" />,
  },
  {
    name: "Events",
    key: "eventRoutes",
    icon: <TheaterComedyOutlinedIcon fontSize="small" />,
  },
  {
    name: "Locations",
    key: "locationRoutes",
    icon: <PlaceOutlinedIcon fontSize="small" />,
  },
  {
    name: "Media",
    key: "mediaRoutes",
    icon: <MovieOutlinedIcon fontSize="small" />,
  },
  {
    name: "News",
    key: "newsRoutes",
    icon: <NewspaperOutlinedIcon fontSize="small" />,
  },
  {
    name: "Tags",
    key: "tagRoutes",
    icon: <SellOutlinedIcon fontSize="small" />,
  },
  {
    name: "Settings",
    key: "userRoutes",
    icon: <ManageAccountsOutlinedIcon fontSize="small" />,
  },
];

export const drawerWidthOpen = 190;  // Width when sidebar is open
export const drawerWidthClosed = 60;

const brandingTheme = {
  primaryColor: "#1a73e8",
  primaryColorLight: "#1a73e85e",
  primaryColorDark: "#002f6f",
};

const linkStyles = {
  textDecoration: "none",
  backgroundColor: "#ffffff", // To change color of logo
  color: "inherit",
  display: "flex",
  justifyContent: "center",
  padding: "10px 0px",
};

const menuHeaderStyles = {
  padding: "0 10px",
  width: "100%",
  display: "flex",
  cursor: "pointer",
  justifyContent: "space-between",
  alignItems: "center",
};

const SimpleSideNav: React.FC<Props> = () => {

  const { logout, user } = useAuth();
  const { pathname } = useLocation();

  const { menuIsOpen, setMenuIsOpen, menuSectionExpanded, setMenuSectionExpanded } =
    useGlobalContext();
  const [isClosing, setIsClosing] = useState(false);
  const [currentParent, setCurrentParent] = useState("");
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openUserMenu, setUserOpenMenu] = useState<any>(false);
  const route = useLocation().pathname.split("/").slice(1);
  const routes = user.routes;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMenuIsOpen(!menuIsOpen);
    }
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMenuIsOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleCloseUserMenu = () => setUserOpenMenu(false);
  const handleOpenUserMenu = (event: any) => setUserOpenMenu(event.currentTarget);

  const renderUserMenu = () => ( //Renders drop down menu for PFP / User
    <Menu
      anchorEl={openUserMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openUserMenu)}
      onClose={handleCloseUserMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        onClick={() => navigate("/profile")}
        icon={<Icon>user</Icon>}
        title="Profile"
      />
      <NotificationItem onClick={handleLogout} icon={<Icon>user</Icon>} title="Logout" />
    </Menu>
  );

  const handleLogout = async () => {
    await logout({ logoutParams: { returnTo: window.location.origin } });
  };

  //expands menu of current route
  useEffect(() => {
    const findRouteKeyByPath = () => {
      for (const key in routes) {
        if (routes.hasOwnProperty(key)) {
          const check = routes[key];
          for (const route of check) {
            if (route.path === pathname) {
              setCurrentParent(key);
              return key;
            }
          }
        }
      }
      return null;
    };
    findRouteKeyByPath();
  }, [pathname, routes]);

  const handleClick = (key: string) => {
    setMenuSectionExpanded(key);
    setMenuIsOpen(true);
  }

  const renderDrawer = () => {
    if (!routes) return null;

    return menuList.map((menu: any) => {
      const isCurrentParentRoute = currentParent === menu.key;
      const userExpanded = menuSectionExpanded === menu.key;
      return (
        <div key={menu.key} style={{ padding: "10px 0 10px 0", fontSize: "15px" }}>
          <strong
            style={menuHeaderStyles}
            onClick={() =>
              menuSectionExpanded === menu.key
                ? setMenuSectionExpanded(null)
                : setMenuSectionExpanded(menu.key)
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                paddingLeft: "10px",
                gap: "10px",
                width: "100%",
                color: "#888",
                fontWeight: "normal",
                marginTop: "-10px",
              }}
              onClick={() => setMenuIsOpen(true)}
            >
              {menu.icon}
              <span style={{ flex: 1 }}>{menu.name}</span>
              {/* <ExpandLess
                style={{
                  rotate: userExpanded || isCurrentParentRoute ? "0deg" : "180deg",
                  transition: "all 300ms cubic-bezier(0.3, 0.7, 0.4, 1.5)",
                  color: brandingTheme.primaryColorDark,
                }}
              /> */}
            </div>
          </strong>

          <Box
            display={userExpanded || isCurrentParentRoute ? "visible" : "none"}
            sx={{
              transition: "all 300ms cubic-bezier(0.3, 0.7, 0.4, 1.5)",
              height: userExpanded || isCurrentParentRoute ? "fit-content" : "0",
              paddingTop: "10px",
              "& a": {
                display: "flex",
                flexDirection: "column",
                color: "white",
                textDecoration: "none",
                padding: "5px 20px",
              },
            }}
          >
            {routes[menu.key] &&
              routes[menu.key].map((route: Route) => {
                const isCurrentRoute = pathname === route.path;

                return (
                  <Link
                    style={{
                      textDecoration: "none",
                      marginLeft: "25%",
                      color: isCurrentRoute
                        ? brandingTheme.primaryColor
                        : "#888",
                      borderRadius: "200px",
                      marginTop: "-5px"
                    }}
                    key={route.path}
                    to={route.path}
                  >
                    {route.text}
                  </Link>
                );
              })}
          </Box>
        </div>
      );
    });
  };


  return (
    <Box sx={{ display: "flex" }} >
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `100% ` },
        }}
      >
        {isSmallScreen ? <DashboardNavbar handleDrawerToggle={handleDrawerToggle} isOpen={menuIsOpen} /> : null}
      </AppBar>
      <Box
        component="nav"
        sx={{ width: isSmallScreen ? 0 : menuIsOpen ? drawerWidthOpen : drawerWidthClosed, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {isSmallScreen ? (
          <Drawer
            variant="temporary"
            open={menuIsOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", lg: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidthOpen,
                overflowX: "hidden",
              },
              "& .MuiCollapse-entered": {
                minHeight: "fit-content !important",
              },
            }}
          >
            <Link
              to="/"
              key={"home"}
              onClick={() => {
                setMenuSectionExpanded(null);
              }}
              style={linkStyles}
            >
              <img
                key={"title"}
                src={Logo}
                width={150}
                alt={"CityView CMS Logo"}
                style={{
                  alignSelf: "center",
                  display: "flex",
                  marginTop: " 15px",
                  marginBottom: "10px",
                }}
              />
            </Link>
            {renderDrawer()}
          </Drawer>
        ) : menuIsOpen ? (
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", lg: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidthOpen,
                overflowX: "hidden",
              },
              "& .MuiCollapse-entered": {
                minHeight: "fit-content !important",
              },
            }}
            open
          >
            <Link
              to="/"
              key={"home"}
              onClick={() => {
                setMenuSectionExpanded(null);
              }}
              style={linkStyles}
            >
              <img
                key={"title"}
                src={Logo}
                width={150}
                alt={"CityView CMS Logo"}
                style={{
                  alignSelf: "center",
                  display: "flex",
                  marginTop: " 15px",
                }}
              />
            </Link>
            {renderDrawer()}
            <div style={{ position: "absolute", bottom: 60, width: "100%" }}>
              <Divider sx={{ width: "100%", my: 2 }} />
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "12px",
                  width: "100%",
                  color: "#888",
                  marginTop: "-20px"
                }}
              >
                {user.imageUrl ? (
                  <div style={{ display: "flex", alignItems: "center", gap: "10px", width: "100%", padding: "10px 0px 0px 0px", color: "#888" }}>
                    <ProfileImage
                      alt="avatar"
                      src={user.imageUrl}
                      onError={(e) => {
                        console.error('Image failed to load:', e);
                      }}
                    />
                    <Typography variant="h6">{user?.name}</Typography>
                  </div>
                ) : (
                  <div style={{ display: "flex", alignItems: "center", gap: "10px", padding: "10px 0px 0px 0px", color: "#888" }}>
                    <AccountCircleOutlinedIcon fontSize="large" />
                    <Typography variant="h6">{user?.name}</Typography>
                  </div>
                )}
              </IconButton>
            </div>
            {renderUserMenu()}
            <IconButton
              onClick={() => setMenuIsOpen(false)}
              sx={{
                position: "absolute",
                bottom: 16,
                left: 10,
                right: 0,
                margin: "auto",
                display: "flex",
                alignItems: "left",
                justifyContent: "left"
              }}
            >
              <MenuOpenIcon />
            </IconButton>
          </Drawer>
        ) : (
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", lg: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidthClosed,
                overflowX: "hidden",
              },
              "& .MuiCollapse-entered": {
                minHeight: "fit-content !important",
              },
            }}
            open
          >
            <Link to="/">
              <IconButton>
                <img
                  src={CityViewLogoCity}
                  width={40}
                  alt={"CityView CMS Logo"}
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    marginTop: " 15px",
                    width: "60%",
                    height: "60%",
                  }}
                />
              </IconButton>
            </Link>
            <div style={{ padding: "10px 0 10px 0", fontSize: "15px", marginBottom: "-15px" }}>
              {menuList.map((menu) => (
                <Tooltip title={menu.name} placement="right" key={menu.key}>
                  <div
                    onClick={() => handleClick(menu.key)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      color: "#888",
                      fontWeight: "normal",
                      marginBottom: "15px"
                    }}
                  >
                    {menu.icon}
                  </div>
                </Tooltip>
              ))}
            </div>
            <div style={{ position: "absolute", bottom: 60, width: "100%" }}>
              <Divider sx={{ width: "100%", my: 2 }} />
              <Tooltip
                title="Profile"
                placement="right"
              >
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    paddingLeft: "10%",
                    width: "100%",
                    color: "#888",
                    marginTop: "-20px"
                  }}
                >
                  {user.imageUrl ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px", width: "100%", padding: "10px 0px 0px 0px", color: "#888" }}>
                      <ProfileImage alt="avatar" src={user.imageUrl} onError={(e) => {
                        console.error('Image failed to load:', e);
                        e.currentTarget.src = 'path/to/fallback/image';
                      }} />
                    </div>
                  ) : (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px", width: "100%", padding: "10px 0px 0px 0px", color: "#888" }}>
                      <Icon>account_circle</Icon>
                    </div>
                  )}
                </IconButton>
              </Tooltip>
            </div>
            {renderUserMenu()}
            <IconButton
              onClick={() => setMenuIsOpen(true)}
              sx={{
                position: "absolute",
                bottom: 16,
                left: 0,
                right: 0,
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <MenuIcon />
            </IconButton>
          </Drawer>
        )
        }
      </Box >
    </Box >
  );
};
export default SimpleSideNav;