import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
  Alert,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import adsService from "services/ads";
import { IAd } from "types/ads.d";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";
import Image from "components/Images/Image";
import { IImage } from "types/image.d";

const Ad: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [ad, setAd] = useState<IAd | undefined>(undefined);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchAd = async () => {
      try {
        const _ad = await adsService.fetch(id);
        setAd(_ad);
      } catch (e) {
        console.error("Error fetching ad:", e);
      }
    };
    if (id && id !== "create") {
      fetchAd();
    }
  }, [id]);

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!ad.title || !ad.link) {
        throw new Error("Please fill in all fields");
      }
      if (id === "create") {
        ad.createdBy = user;
        ad.createdAt = new Date().toISOString();
        await adsService.create(ad);
      } else {
        ad.updatedDate = new Date().toISOString();
        ad.updatedBy = user;
        await adsService.update(ad.id, ad);
      }
      // Redirect to listings on success
      navigate("/ads");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await adsService.remove(id);
      navigate("/ads");
    } catch (error) {
      console.error("Error deleting ad:", error);
    }
  };

  const handleImageAccept = (image: IImage) => {
    setAd((prevAd: IAd) => ({ ...prevAd, defaultImage: image }));
  };

  return (
    <BasicLayout>
      <Grid container spacing={2} style={{ marginBottom: 200 }} >
        {error && <Grid item xs={12}><Alert severity="error">{error}</Alert></Grid>}
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Ad</Typography>
        </Grid>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12} md={6} marginTop={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px ' }}>
            <Image
              onAccept={handleImageAccept}
              src={ad?.defaultImage?.url}
              alt={ad?.title}
              bucketPath="cityview-cms/ads"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="name">Name</FormLabel>
                  <TextField
                    id="name"
                    value={ad?.title || ""}
                    onChange={(e) => setAd({ ...ad, title: e.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="alt">Description</FormLabel>
                  <TextField
                    id="alt"
                    value={ad?.description || ""}
                    onChange={(e) => setAd({ ...ad, description: e.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="url">Ad Link</FormLabel>
                  <TextField
                    id="link"
                    value={ad?.link || ""}
                    onChange={(e) => setAd({ ...ad, link: e.target.value })}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            {id && id !== "create" && (
              <Grid item xs={12}>
                <Typography variant="h4" color="alert">
                  Danger Zone
                </Typography>
                <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <LoadingOverlay loading={loading} />
      </Grid>
    </BasicLayout>
  );
};

export default Ad;