import React from 'react';
import { TextField, Select, MenuItem, FormControlLabel, Radio, Checkbox, Button, FormControl, FormLabel, RadioGroup } from '@mui/material';
import { IForm, IFieldOption } from 'types/forms';

interface FormPreviewProps {
  form: IForm;
}

const FormPreview: React.FC<FormPreviewProps> = ({ form }) => {
  const renderField = (field: IForm['fields'][0]) => {
    switch (field.type) {
      case 'text':
      case 'email':
      case 'number':
      case 'date':
        return (
          <TextField
            type={field.type}
            name={field.name}
            label={field.label}
            required={field.required}
            fullWidth
            margin="normal"
          />
        );
      case 'select':
        return (
          <FormControl fullWidth margin="normal">
            <FormLabel>{field.label}</FormLabel>
            <Select
              name={field.name}
              required={field.required}
            >
              {field.options?.map((option: IFieldOption) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case 'radio':
        return (
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">{field.label}</FormLabel>
            <RadioGroup name={field.name}>
              {field.options?.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case 'checkbox':
        return (
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">{field.label}</FormLabel>
            {field.options?.map((option) => (
              <FormControlLabel
                key={option.value + field.name}
                control={<Checkbox name={field.name} value={option.value} />}
                label={option.label}
              />
            ))}
          </FormControl>
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4">{form.name}</h2>
      <p className="text-gray-600 mb-6">{form.description}</p>
      {
        !form.fields.length ? <div className="text-center text-gray-500">No fields</div> :
          <form>
            {form.fields.map((field) => (
              <div key={field.name} className="mb-4">
                {renderField(field)}
              </div>
            ))}
          </form>
      }
    </div>
  );
};

export default FormPreview;
