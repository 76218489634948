import Dashboard from "pages/Dashboard";
import Login from "pages/Login/Login";
import SignUp from "pages/SignUp/SignUp";

// @mui icons
import Icon from "@mui/material/Icon";
import Events from "pages/Events";
import Event from "pages/Event";
import Locations from "pages/Locations";
import Location from "pages/Location";
import Videos from "pages/Videos";
import Video from "pages/Video";
import NewsList from "pages/NewsList";
// import News from "pages/News";
import Ads from "pages/Ads";
import Ad from "pages/Ad";

import Sync from "pages/Sync";
import TagGroups from "pages/TagGroups";
import TagGroup from "pages/TagGroup";
import Tags from "pages/Tags";
import Tag from "pages/Tag";
import EventsFromJSON from "pages/EventsFromJSON";
import EventsFromImage from "pages/EventsFromImage";
import City from "pages/City";
import Cities from "pages/Cities";
import ApiKeys from "pages/ApiKeys";
import ApiKey from "pages/ApiKey";
import TranscribeAudio from "pages/TranscribeAudio";
import News from "pages/News";
import Author from "pages/Author";
import Authors from "pages/Authors";
import Promotion from "pages/Promotion";
import Promotions from "pages/Promotions";
import Itineraries from "pages/Itineraries";
import Intinerary from "pages/Itinerary";
import Profile from "pages/Profile";
import Role from "pages/Role";
import Roles from "pages/Roles";
import Organization from "pages/Organization";
import Group from "pages/Group";
import Groups from "pages/Groups";
import QRCodes from "pages/QRCodes";
import QRCode from "pages/QRCode";
import Member from "pages/Member";
import Members from "pages/Members";
import RSVP from "pages/RSVP";
import RSVPs from "pages/RSVPs";
import Invitation from "pages/Invitation";
import Invitations from "pages/Invitations";
import Form from "pages/Form";
import Forms from "pages/Forms";
import NewsLetter from "pages/Newsletter";
import NewsLetters from "pages/Newsletters";
import DataSources from "pages/DataSources";
import DataSource from "pages/DataSource";
import Plans from "pages/Plans/Plans";

interface RouteObject {
  type: string;
  name?: string;
  key: string;
  route?: string;
  component?: React.ReactNode;
  icon?: React.ReactNode;
  title?: string;
  children?: {
    index?: boolean;
    element?: React.ReactNode;
    path?: string;
  }[];
  collapse?: RouteObject[];
}

const routes: RouteObject[] = [
  {
    type: "title",
    name: "CityView CMS",
    title: "CityView CMS",
    key: "title",
  },
  { type: "divider", key: "divider-0" },
  {
    type: "hidden",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/",
    component: <Dashboard />,
  },
  {
    type: "hidden",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="medium">user</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Engagement",
    key: "engagement",
    icon: <Icon fontSize="medium">people</Icon>,
    collapse: [
      {
        name: "Member",
        key: "member",
        route: "/members/:id",
        component: <Member />,
        type: "hidden",
      },
      {
        type: "item",
        name: "Members",
        key: "members",
        route: "/members",
        component: <Members />,
      },
      {
        name: "RSVP",
        key: "rsvp",
        route: "/rsvps/:id",
        component: <RSVP />,
        type: "hidden",
      },
      {
        type: "item",
        name: "RSVPs",
        key: "rsvps",
        route: "/rsvps",
        component: <RSVPs />,
      },
      {
        name: "Invitation",
        key: "invitation",
        route: "/invitations/:id",
        component: <Invitation />,
        type: "hidden",
      },
      {
        type: "item",
        name: "Invitations",
        key: "invitations",
        route: "/invitations",
        component: <Invitations />,
      },
      {
        name: "QR Code",
        key: "qr-code",
        route: "/qrcodes/:id",
        component: <QRCode />,
        type: "hidden",
      },
      {
        type: "item",
        name: "QR Codes",
        key: "qrcodes",
        route: "/qrcodes",
        component: <QRCodes />,
      },
      {
        name: "Ad",
        key: "ad",
        route: "/ads/:id",
        component: <Ad />,
        type: "hidden",
      },
      {
        type: "item",
        name: "Ads",
        key: "ads",
        route: "/ads",
        component: <Ads />,
      },
      {
        name: "Promotion",
        key: "promotion",
        route: "/promotions/:id",
        component: <Promotion />,
        type: "hidden",
      },
      {
        type: "item",
        name: "Promotions",
        key: "promotions",
        route: "/promotions",
        component: <Promotions />,
      },
      {
        name: "Form",
        key: "form",
        route: "/forms/:id",
        component: <Form />,
        type: "hidden",
      },
      {
        type: "item",
        name: "Forms",
        key: "forms",
        route: "/forms",
        component: <Forms />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Events & Groups",
    key: "events-and-organizations",
    icon: <Icon fontSize="medium">theater_comedy</Icon>,
    collapse: [
      {
        name: "Event",
        key: "event",
        route: "/events/:id",
        component: <Event />,
        type: "hidden",
      },
      {
        type: "item",
        name: "Events",
        key: "events",
        route: "/events",
        component: <Events />,
        icon: <Icon fontSize="medium">theater_comedy</Icon>,
      },
      {
        name: "Itinerary",
        key: "itinerary",
        route: "/itineraries/:id",
        component: <Intinerary />,
        type: "hidden",
      },
      {
        type: "item",
        name: "Itineraries",
        key: "itineraries",
        route: "/itineraries",
        component: <Itineraries />,
      },
      {
        name: "Group",
        key: "group",
        route: "/groups/:id",
        component: <Group />,
        type: "hidden",
      },
      {
        type: "item",
        name: "Groups",
        key: "groups",
        route: "/groups",
        component: <Groups />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Media",
    key: "media",
    icon: <Icon fontSize="medium">movie</Icon>,
    collapse: [
      {
        name: "Video",
        key: "video",
        route: "/videos/:id",
        component: <Video />,
        type: "hidden",
      },
      {
        type: "item",
        name: "Videos",
        key: "videos",
        route: "/videos",
        component: <Videos />,
      },
    ],
  },
  {
    type: "collapse",
    name: "News & Authors",
    key: "news-menu",
    icon: <Icon fontSize="medium">newspaper</Icon>,
    collapse: [
      {
        name: "Author",
        key: "author",
        route: "/authors/:id",
        component: <Author />,
        type: "hidden",
      },
      {
        type: "item",
        name: "Authors",
        key: "authors",
        route: "/authors",
        component: <Authors />,
      },

      {
        name: "News",
        key: "news-article",
        route: "/news/:id",
        component: <News />,
        type: "hidden",
      },
      {
        type: "item",
        name: "News",
        key: "news",
        route: "/news",
        component: <NewsList />,
      },
      {
        name: "Newsletter",
        key: "newsletter",
        route: "/newsletters/:id",
        component: <NewsLetter />,
        type: "hidden",
      },
      {
        type: "item",
        name: "Newsletters",
        key: "newsletters",
        route: "/newsletters",
        component: <NewsLetters />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Locations & Cities",
    key: "location-and-places",
    icon: <Icon fontSize="medium">place</Icon>,
    collapse: [
      {
        name: "DataSource",
        key: "data-source",
        route: "/data-sources/:id",
        component: <DataSource />,
        type: "hidden",
      },
      {
        type: "item",
        name: "Data Sources",
        key: "data-sources",
        route: "/data-sources",
        component: <DataSources />,
      },
      {
        name: "City",
        key: "city",
        route: "/cities/:id",
        component: <City />,
        type: "hidden",
      },
      {
        type: "item",
        name: "Cities",
        key: "cities",
        route: "/cities",
        component: <Cities />,
      },
      {
        name: "Location",
        key: "location",
        route: "/locations/:id",
        component: <Location />,
        type: "hidden",
      },
      {
        type: "item",
        name: "Locations",
        key: "locations",
        route: "/locations",
        component: <Locations />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Tag & Tag Groups",
    key: "tags-menu",
    icon: <Icon fontSize="medium">sell</Icon>,
    collapse: [
      {
        type: "item",
        name: "Tags",
        key: "tags",
        route: "/tags",
        component: <Tags />,
      },
      {
        name: "Tag",
        key: "tag",
        route: "/tags/:id",
        component: <Tag />,
        type: "hidden",
      },
      {
        name: "Tag Group",
        key: "tag-group",
        route: "/tag-groups/:id",
        component: <TagGroup />,
        type: "hidden",
      },
      {
        type: "item",
        name: "Tag Groups",
        key: "tag-groups",
        route: "/tag-groups",
        component: <TagGroups />,
      },
    ],
  },
  {
    name: "Tag Group",
    key: "tagGroup",
    route: "/tag-groups/:id",
    component: <TagGroup />,
    type: "hidden",
  },
  {
    type: "collapse",
    name: "User Tools",
    key: "userTools",
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    collapse: [
      {
        name: "Role",
        key: "role",
        route: "/roles/:id",
        component: <Role />,
        type: "hidden",
      },
      {
        type: "item",
        name: "Roles",
        key: "roles",
        route: "/roles",
        component: <Roles />,
      },
      {
        type: "item",
        name: "Organization",
        key: "organization",
        route: "/organization",
        component: <Organization />,
      },
      
    ],
  },
  {
    type: "collapse",
    name: "Utilities",
    key: "utilities",
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      {
        name: "API Key",
        key: "apiKey",
        route: "/apiKeys/:id",
        component: <ApiKey />,
        type: "hidden",
      },
      {
        type: "item",
        name: "API Keys",
        key: "apiKeys",
        route: "/apiKeys",
        component: <ApiKeys />,
      },
      {
        type: "item",
        name: "Sync",
        key: "run-sync",
        route: "/run-sync",
        component: <Sync />,
      },
      {
        type: "item",
        name: "Events From Image",
        key: "events-from-image",
        route: "/events-from-image",
        component: <EventsFromImage />,
      },
      {
        type: "item",
        name: "Events From JSON",
        key: "events-from-json",
        route: "/events-from-json",
        component: <EventsFromJSON />,
      },
      {
        type: "item",
        name: "Transcribe Audio",
        key: "transcribe-audio",
        route: "/transcribe-audio",
        component: <TranscribeAudio />,
      },
      // {
      //   name: "Analyze Image",
      //   key: "analyze-image",
      //   route: "/utilities/analyze-image",
      //   component: <Vision />,
      // },
      // {
      //   name: "Scrape Webpage",
      //   key: "image-to-text",
      //   route: "/image-to-text",
      //   component: <Vision />,
      // },
    ],
  },
  {
    name: "Login",
    key: "login",
    route: "/login",
    component: <Login />,
    type: "hidden",
  },
  {
    name: "SignUp",
    key: "sign-up",
    route: "/sign-up",
    component: <SignUp />,
    type: "hidden",
  },
  {
    name: "Plans",
    key: "plans",
    route: "/plans",
    component: <Plans />,
    type: "hidden",
  },
];

export default routes;
