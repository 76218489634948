import { API_HOST } from "../config";
import axios from "axios";
import TokenHandler from "./base";
import { IService, IPaginationModal } from "types/App";

async function fetch(id: string) {
  const token = TokenHandler.getToken();
  const response = await axios.get(`${API_HOST}/organizations/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  console.log(response)
  return response.data;
}

async function update(id: string, data: Record<string, any>) {
  const token = TokenHandler.getToken();
  const response = await axios.put(`${API_HOST}/organizations/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  console.log(response);
  return response.data;
}

const organizationsService: IService = {
  fetch,
  update,
};

export default organizationsService;
