import { API_HOST, API_VERSION } from "../config";
import { IAuthor } from "types/authors";
import axios from "axios";
import TokenHandler from "./base";
import { IPaginationModal, IService } from "types/App";

async function fetch() {
  const token = TokenHandler.getToken();

  const url = new URL(`${API_HOST}/stats`);

  const response = await axios.get(url.toString(), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

const statsService = {
  fetch,
};

export default statsService;
