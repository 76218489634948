import React from 'react';
import { Grid, FormControl, FormLabel, Select, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { IEvent } from 'types/events.d';

interface EventRecurrenceFormProps {
  event: IEvent;
  onChange: (updatedEvent: Partial<IEvent>) => void;
}

const EventRecurrenceForm: React.FC<EventRecurrenceFormProps> = ({ event, onChange }) => {
  const handleRecurrenceTypeChange = (type: 'weekly' | 'monthly' | 'none') => {
    onChange({
      recurrenceType: type,
      recurrenceRule: type === 'none' ? undefined : { daysOfWeek: [], dayOfMonth: null, weekOfMonth: null },
    });
  };

  const handleDayOfWeekChange = (day: number) => {
    const updatedDays = event.recurrenceRule?.daysOfWeek?.includes(day)
      ? event.recurrenceRule.daysOfWeek.filter((d) => d !== day)
      : [...(event.recurrenceRule?.daysOfWeek || []), day];
    onChange({ recurrenceRule: { ...event.recurrenceRule, daysOfWeek: updatedDays } });
  };

  const handleMonthlyRecurrenceTypeChange = (type: 'dayOfMonth' | 'weekOfMonth') => {
    onChange({
      recurrenceRule: {
        ...event.recurrenceRule,
        dayOfMonth: type === 'dayOfMonth' ? 1 : null,
        weekOfMonth: type === 'weekOfMonth' ? 1 : null,
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel htmlFor="recurrence-type">Recurrence Type</FormLabel>
          <Select
            id="recurrence-type"
            value={event.recurrenceType}
            onChange={(e) => handleRecurrenceTypeChange(e.target.value as 'weekly' | 'monthly' | 'none')}
          >
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {event.recurrenceType === 'weekly' && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel>Days of Week</FormLabel>
            {[0, 1, 2, 3, 4, 5, 6].map((day) => (
              <FormControlLabel
                key={day}
                control={
                  <Checkbox
                    checked={event.recurrenceRule?.daysOfWeek?.includes(day) || false}
                    onChange={() => handleDayOfWeekChange(day)}
                  />
                }
                label={['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day]}
              />
            ))}
          </FormControl>
        </Grid>
      )}
      {event.recurrenceType === 'monthly' && (
        <>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Monthly Recurrence Type</FormLabel>
              <Select
                value={event.recurrenceRule?.dayOfMonth ? 'dayOfMonth' : 'weekOfMonth'}
                onChange={(e) => handleMonthlyRecurrenceTypeChange(e.target.value as 'dayOfMonth' | 'weekOfMonth')}
              >
                <MenuItem value="dayOfMonth">Day of Month</MenuItem>
                <MenuItem value="weekOfMonth">Week of Month</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {event.recurrenceRule?.dayOfMonth && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="day-of-month">Day of Month</FormLabel>
                <Select
                  id="day-of-month"
                  value={event.recurrenceRule.dayOfMonth}
                  onChange={(e) => onChange({ recurrenceRule: { ...event.recurrenceRule, dayOfMonth: e.target.value as number } })}
                >
                  {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                    <MenuItem key={day} value={day}>{day}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {event.recurrenceRule?.weekOfMonth && (
            <>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="week-of-month">Week of Month</FormLabel>
                  <Select
                    id="week-of-month"
                    value={event.recurrenceRule.weekOfMonth}
                    onChange={(e) => onChange({ recurrenceRule: { ...event.recurrenceRule, weekOfMonth: e.target.value as number } })}
                  >
                    {[1, 2, 3, 4, 5].map((week) => (
                      <MenuItem key={week} value={week}>{week === 5 ? 'Last' : week}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="day-of-week">Day of Week</FormLabel>
                  <Select
                    id="day-of-week"
                    value={event.recurrenceRule.daysOfWeek?.[0] || 0}
                    onChange={(e) => onChange({ recurrenceRule: { ...event.recurrenceRule, daysOfWeek: [e.target.value as number] } })}
                  >
                    {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
                      <MenuItem key={index} value={index}>{day}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
        </>
      )}
      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel htmlFor="start-date">Start Date</FormLabel>
          <DatePicker
            value={dayjs(event.startDate)}
            onChange={(date) => date && onChange({ startDate: date.toDate() })}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel htmlFor="end-date">End Date</FormLabel>
          <DatePicker
            value={dayjs(event.endDate)}
            onChange={(date) => date && onChange({ endDate: date.toDate() })}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default EventRecurrenceForm;
