import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Typography, Alert, IconButton } from "@mui/material";
import { INewsletter } from "types/newsletters";
import newslettersService from "services/newsletters";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";
import { ChevronLeft } from "@mui/icons-material";
import NewsLetterForm from "components/NewsLetterForm";

const Newsletter: React.FC = () => {
  const [newsletter, setNewsletter] = useState<Partial<INewsletter>>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchNewsletter = async () => {
      try {
        setLoading(true);
        const _newsletter: INewsletter = await newslettersService.fetch(id);
        setNewsletter(_newsletter);
      } catch (e) {
        console.error("Error fetching newsletter:", e);
      } finally {
        setLoading(false);
      }
    };
    if (id && id !== "create") {
      fetchNewsletter();
    }
  }, [id]);

  return (
    <BasicLayout>
      <Grid container direction={"column"} spacing={2}>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid item xs={12} display={"flex"} alignItems={"center"}>
            <IconButton onClick={() => navigate(-1)}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="h4">Newsletter</Typography>
          </Grid>
          {!id || id === "create" ? (
            <Typography variant="body1">Create a new newsletter.</Typography>
          ) : (
            <Typography variant="body1">Edit this newsletter.</Typography>
          )}
        </Grid>
        <NewsLetterForm id={id} newsletter={newsletter} setNewsletter={setNewsletter} setError={setError} />
      </Grid>
      <LoadingOverlay loading={loading} />
    </BasicLayout>
  );
};

export default Newsletter;
