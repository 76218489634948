import React, { createContext, useCallback, useState, useEffect, ReactNode, useMemo } from "react";
import authService from "../services/auth";
import TokenHandler from "../services/base";
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext<any>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [hasSubscription, setHasSubscription] = useState<boolean>(false);
  const navigate = useNavigate();

  const isAuthenticated = useMemo(() => !!user, [user]);

  const fetchUser = useCallback(async () => {
    setLoading(true);
    try {
      const token = TokenHandler.getToken();
      if (token) {
        const response = await authService.me();
        setUser(response.user);
        setHasSubscription(
          response.user?.subscription?.status === 'active' ||
          response.user?.subscription?.status === 'trialing' ||
          response.user?.subscription?.status === 'incomplete'
        );
      } else {
        setUser(null);
        setHasSubscription(false);
      }
    } catch (error) {
      console.error(error);
      setUser(null);
      setHasSubscription(false);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const register = useCallback(async (props: any) => {
    setLoading(true);
    try {
      const _user = await authService.register(props);
      const response = await authService.me();
      setUser(response.user);
      navigate('/plans'); // Redirect to plans page after registration
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [navigate]);

  const login = useCallback(async (username: string, password: string) => {
    try {
      setLoading(true);
      setError(null);
      const loginResponse = await authService.login(username, password);
      TokenHandler.setToken(loginResponse.token);
      await fetchUser();
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [fetchUser]);

  const logout = useCallback(async () => {
    setLoading(true);
    try {
      await authService.logout();
      setUser(null);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  const getToken = useCallback(async () => {
    try {
      const token = TokenHandler.getToken();
      return token;
    } catch (error) {
      console.error(error);
    }
    return null;
  }, []);

  const refreshUser = async () => {
    try {
      const userData = await authService.me();
      setUser(userData);
    } catch (error) {
      console.error('Error refreshing user data:', error);
    }
  };

  return (
    <AuthContext.Provider 
      value={{ 
        error, 
        loading, 
        isAuthenticated, 
        user, 
        hasSubscription,
        login, 
        logout, 
        register, 
        getToken, 
        refreshUser 
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
