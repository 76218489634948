import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Typography,
  TextField,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  debounce,
} from "@mui/material";
import { INewsletter } from "types/newsletters";
import LoadingOverlay from "components/LoadingOverlay";
import TipTap from 'components/TipTap';
import AITextField from "components/AITextField";
import { useState } from "react";
import { useAuth } from "context/Auth";
import { useNavigate } from "react-router-dom";
import newslettersService from "services/newsletters";

interface NewsLetterFormProps {
  id: string;
  newsletter?: Partial<INewsletter>;
  setNewsletter: (newsletter: Partial<INewsletter>) => void;
  setError: (error: string) => void;
}

const NewsLetterForm: React.FC<NewsLetterFormProps> = ({ id, newsletter, setNewsletter, setError }) => {

  const [snackbar, setSnackbar] = useState<any>({ open: false, message: "", severity: "success" });
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleSave = async () => {
    try {
      setLoading(true);
      const newNewsletter: Partial<INewsletter> = {
        ...newsletter,
      };

      if (!newNewsletter?.name || !newNewsletter?.subject) {
        throw new Error("Please fill in all required fields");
      }

      if (id === "create") {
        newNewsletter.createdBy = user;
        newNewsletter.createdDate = new Date();
        await newslettersService.create(newNewsletter);
      } else {
        newNewsletter.updatedBy = user;
        newNewsletter.updatedDate = new Date();
        await newslettersService.update(newNewsletter.id!, newNewsletter);
      }
      console.log(newsletter);
      navigate("/newsletters");
    } catch (error: any) {
      console.log(newsletter);
      console.error("Error saving newsletter:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await newslettersService.remove(id!);
      navigate("/newsletters");
    } catch (error) {
      console.error("Error deleting newsletter:", error);
    }
  };

  return (
    <>
      <Grid container item direction={"row"} xs={12} spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="newsletter-name">Name</FormLabel>
            <TextField
              id="newsletter-name"
              value={newsletter?.name || ""}
              required
              onChange={(e) => setNewsletter({ ...newsletter, name: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="newsletter-subject">Subject</FormLabel>
            <TextField
              id="newsletter-subject"
              value={newsletter?.subject || ""}
              required
              onChange={(e) => setNewsletter({ ...newsletter, subject: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="newsletter-from-name">From Name</FormLabel>
            <TextField
              id="newsletter-from-name"
              value={newsletter?.fromName || ""}
              required
              onChange={(e) => setNewsletter({ ...newsletter, fromName: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="newsletter-from-email">From Email</FormLabel>
            <TextField
              id="newsletter-from-email"
              value={newsletter?.fromEmail || ""}
              required
              onChange={(e) => setNewsletter({ ...newsletter, fromEmail: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="newsletter-reply-to">Reply-To Email</FormLabel>
            <TextField
              id="newsletter-reply-to"
              value={newsletter?.replyToEmail || ""}
              required
              onChange={(e) => setNewsletter({ ...newsletter, replyToEmail: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="newsletter-status">Status</FormLabel>
            <Select
              id="newsletter-status"
              value={newsletter?.status || "draft"}
              onChange={(e) => setNewsletter({ ...newsletter, status: e.target.value as any })}
            >
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="scheduled">Scheduled</MenuItem>
              <MenuItem value="sent">Sent</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="newsletter-scheduled-date">Scheduled Date</FormLabel>
            <TextField
              id="newsletter-scheduled-date"
              type="datetime-local"
              value={newsletter?.scheduledDate ? new Date(newsletter.scheduledDate).toISOString().slice(0, 16) : ""}
              required
              onChange={(e) => setNewsletter({ ...newsletter, scheduledDate: new Date(e.target.value) })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <AITextField
              id="newsletter-description"
              label="Description"
              prompt="From the following details, create a description of an event. The title should be descriptive and engaging and be at least 3 sentences long."
              promptMode="modal"
              value={newsletter?.description || ""}
              multiline
              required
              rows={4}
              onChange={(e: any) => setNewsletter({ ...newsletter, description: e })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel required htmlFor="newsletter-content">Content</FormLabel>
            <TipTap
              content={newsletter?.content}
              onChange={debounce((value: JSON) => setNewsletter({ ...newsletter, content: value }), 300)}
              height={800}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Grid>
      {id && id !== "create" && (
        <Grid item xs={12}>
          <Typography variant="h4" color="error">
            Danger Zone
          </Typography>
          <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
            Delete
          </Button>
        </Grid>
      )}
      <LoadingOverlay loading={loading} />
      <Snackbar
        open={snackbar.open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </>
  )
};

export default NewsLetterForm;