import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";

import { IFilterOptions } from "types/paginatedDataGrid";
import BasicLayout from "components/Layouts/BasicLayout";
import promotionsService from "services/promotions";

const Promotions: React.FC = () => {
  const columns: GridColDef[] = [
    { field: "title", headerName: "Title", minWidth: 200, flex: 1 },
    { field: "subtitle", headerName: "Subtitle", minWidth: 200, flex: 1 },
    { field: "description", headerName: "Description", minWidth: 200, flex: 2 },
    { field: "code", headerName: "Code", minWidth: 200, flex: 1 },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={5} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Promotions</Typography>
        </Grid>
        <Grid item xs={7} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/promotions/create">
            <Icon>add</Icon>
            Create Promotion
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              collectionName="promotions"
              defaultSortField="name"
              defaultSortDirection="desc"
              columns={columns}
              service={promotionsService}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Promotions;
