import { API_HOST } from "../config";
import axios from "axios";
import TokenHandler from "./base";
import { IPaginationModal } from "types/App";
import { IQRCode } from "../types/qrcodes";

const qrcodesService = {
  fetch: async (id: string): Promise<IQRCode> => {
    const token = TokenHandler.getToken();
    const response = await axios.get(`${API_HOST}/qrcodes/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  fetchAll: async (paginationModel: IPaginationModal = {}): Promise<IQRCode[]> => {
    const token = TokenHandler.getToken();
    const filteredPagination = { ...paginationModel };
    delete filteredPagination.query;
    delete filteredPagination.sort;

    const url = new URL(`${API_HOST}/qrcodes`);
    url.search = new URLSearchParams(filteredPagination).toString();

    const params = {
      ...(paginationModel?.query && { query: paginationModel.query }),
      ...(paginationModel?.sort && { sort: paginationModel.sort }),
    };

    const response = await axios.get(url.toString(), {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  create: async (qrcode: Omit<IQRCode, "id">): Promise<IQRCode> => {
    const token = TokenHandler.getToken();
    const response = await axios.post(`${API_HOST}/qrcodes`, qrcode, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.id;
  },

  update: async (id: string, qrcode: Partial<IQRCode>): Promise<void> => {
    const token = TokenHandler.getToken();
    await axios.put(`${API_HOST}/qrcodes/${id}`, qrcode, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  remove: async (id: string): Promise<void> => {
    const token = TokenHandler.getToken();
    await axios.delete(`${API_HOST}/qrcodes/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

};

export default qrcodesService;