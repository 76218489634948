import { Grid } from "@mui/material";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import newslettersService from "services/newsletters";


const NewsLetterList = () => {

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "subject", headerName: "Subject", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
  ];

  return (
    <Grid item xs={12}>
      <PaginatedDataGrid
        collectionName="newsletters"
        defaultSortField="name"
        defaultSortDirection="asc"
        columns={columns}
        service={newslettersService}
        filterOptions={[
          {
            label: "Title",
            name: "title",
            type: "text",
            operator: "search",
          },
        ]}
      />
    </Grid>
  )
};

export default NewsLetterList;