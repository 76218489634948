import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";
import { IPromotion } from "types/promotions.d";
import promotionsService from "services/promotions";
import LoadingOverlay from "components/LoadingOverlay";

interface PromotionSelectProps {
  value?: IPromotion;
  onChange: (promotion: IPromotion) => void;
}

const PromotionSelect: React.FC<PromotionSelectProps> = ({ onChange, value }) => {
  const [promotions, setPromotions] = useState<IPromotion[]>([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [newPromotion, setNewPromotion] = useState<IPromotion>(undefined);

  useEffect(() => {
    const fetchPromotions = async () => {
      const promotions = await promotionsService.fetchAll({ pageSize: 1000 });
      setPromotions(promotions.data);
    };
    fetchPromotions();
  }, []);

  const handleSelectChange = (value: any) => {
    if (!promotions?.length) return;

    const selectedPromotion = promotions.find((promotion) => promotion.id === value);
    if (selectedPromotion) {
      onChange(selectedPromotion);
    } else {
      onChange(null);
    }
  };
  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 10,
  });

  const handleSave = async () => {
    try {
      setLoading(true);

      const promotion: IPromotion = {
        ...newPromotion,
        type: "event",
      };
      //TODO - Add the rest of the required fields
      if (!promotion.title) {
        throw new Error("Please fill in required fields");
      }

      await promotionsService.create(promotion);

      setShowCreateModal(false);
      setNewPromotion(undefined);
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container direction={"row"} gap={5}>
        <Grid item xs={6}>
          <Autocomplete
            value={value?.id || value?._id || ""}
            onChange={(e, value) => {
              console.log("this", e, value);
              handleSelectChange(value);
            }}
            options={promotions?.map((promotion: any) => promotion.id)}
            filterOptions={filterOptions}
            getOptionLabel={(option: any) => {
              return promotions?.find((promotion: any) => promotion.id === option)?.title || option;
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => setShowCreateModal(true)}>
            <span style={{ lineHeight: "16px" }}>ADD NEW PROMOTION </span>
          </Button>
        </Grid>
      </Grid>
      <Dialog fullWidth open={showCreateModal} onClose={() => setShowCreateModal(false)}>
        <DialogTitle>{"Add New Promotion"}</DialogTitle>
        <DialogContent>
          <Grid container direction={"column"} style={{ padding: "20px" }} spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="title">Title</FormLabel>
                <TextField
                  id="title"
                  required={true}
                  value={newPromotion?.title || ""}
                  onChange={(e) => setNewPromotion((prev) => ({ ...prev, title: e.target.value }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="subtitle">Subtitle</FormLabel>
                <TextField
                  id="subtitle"
                  value={newPromotion?.subtitle || ""}
                  onChange={(e) =>
                    setNewPromotion((prev) => ({ ...prev, subtitle: e.target.value }))
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="description">Description</FormLabel>
                <TextField
                  id="description"
                  value={newPromotion?.description || ""}
                  onChange={(e) =>
                    setNewPromotion((prev) => ({ ...prev, description: e.target.value }))
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="code">Promotion Code</FormLabel>
                <TextField
                  id="code"
                  value={newPromotion?.code || ""}
                  onChange={(e) => setNewPromotion((prev) => ({ ...prev, code: e.target.value }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel htmlFor="qr-code">QR Code</FormLabel>
                <TextField
                  id="qr-code"
                  value={newPromotion?.qrcode || ""}
                  onChange={(e) => setNewPromotion((prev) => ({ ...prev, qrcode: e.target.value }))}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container item justifyContent={"flex-end"} spacing={4}>
            <Grid item>
              <Button variant="contained" onClick={handleSave}>
                Save Promotion
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setShowCreateModal(false);
                  setNewPromotion(undefined);
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <LoadingOverlay loading={loading} />
    </>
  );
};

export default PromotionSelect;
