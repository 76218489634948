import { IUser as User } from "./users.d";
import { ITag } from "./tags";
import { ILocationData } from "./locations.d";
import { IVideoData } from "./videos.d";
import { IGroup } from "./groups.d";
import { IPromotion } from "./promotions.d";
import { IImage } from "./image";

export interface IEventResponse {
  page: number;
  limit: number;
  search: any;
  total: number;
  sortField: string;
  sortDirection: string;
  data: IEvent[];
}

export interface IGenerateEventImageResponse {
  image: string;
}
export enum StatusEnum {
  Published = "published",
  Pending = "pending",
}

export type StatusTypes = StatusEnum.Published | StatusEnum.Pending;

export interface IEvent {
  id?: string;
  _id?: string;
  createdBy?: User | string;
  createdDate: string;
  updatedDate?: string;
  updatedBy?: User;
  organization: string | IGroup; // Changed to required
  content?: JSON;
  title: string;
  slug: string;
  defaultImage?: IImage;
  images?: IImage[];
  defaultVideo?: IVideoData;
  videos?: IVideoData[];
  description?: string;
  thumbnail: string;
  price?: string;
  tagIds: string[];
  link: string;
  status: StatusTypes;
  featured?: boolean;
  boosted?: boolean;
  location: ILocationData;
  locationName?: string;
  group?: IGroup;
  promotion?: IPromotion;
  tags: ITag[];
  rsvpDeadline?: Date;
  maxCapacity?: number;
  membershipTypes?: Array<'basic' | 'premium' | 'vip'>;
  allowGuests: boolean;
  guestsLimit: number;
  rsvpOpenDate?: Date;
  waitlistEnabled: boolean;
  waitlistLimit?: number;
  eventType: 'single' | 'multi' | 'recurring';
  startDate: Date;
  endDate?: Date;
  startTime: string;
  endTime?: string;
  recurrenceType?: 'weekly' | 'monthly' | 'none';
  recurrenceRule?: {
    daysOfWeek?: number[];
    dayOfMonth?: number;
    weekOfMonth?: number;
  };
}

export interface Photo {
  width: number;
  height: number;
  html_attributions: string[];
  url: string;
  photo_reference: string;
  _id: string;
}

export enum DayOfWeek {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum WeekOfMonth {
  First = 1,
  Second,
  Third,
  Fourth,
  Last,
}
