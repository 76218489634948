import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
  Divider,
  Select,
  FormGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Tabs,
  Tab,
  Box,
  Autocomplete,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import LoadingOverlay from "components/LoadingOverlay";
import { useGlobalContext } from "context/GlobalContext";
import BasicLayout from "components/Layouts/BasicLayout";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TagMultiSelect from 'components/TagMultiSelect/TagMultiSelect';

import membersService from "services/members";
import rsvpsService from "services/rsvps";
import { IMember, MembershipType } from "types/members.d";
import { IRSVP } from "types/rsvp.d";

const Member: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [member, setMember] = useState<IMember | undefined>(undefined);
  const [rsvps, setRsvps] = useState<IRSVP[]>([]);
  const [activeTab, setActiveTab] = useState(0);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { loading: contextLoading, refetchGroups, tags, tagGroups } = useGlobalContext();
  const memberTags = tags.filter((tag: any) => tag.type === 'member');

  useEffect(() => {
    const fetchMember = async () => {
      try {
        const member = await membersService.fetch(id);
        setMember(member);
      } catch (e) {
        console.error("Error fetching member:", e);
      }
    };
    if (id && id !== "create") {
      fetchMember();
    }
  }, [id]);

  useEffect(() => {
    const fetchRsvps = async () => {
      try {
        const { data } = await rsvpsService.fetchAll({
          query: [{ field: "member._id", operator: "==", value: id }],
        });
        setRsvps(data);
      } catch (e) {
        console.error("Error fetching RSVPs:", e);
      }
    };
    if (id && id !== "create") {
      fetchRsvps();
    }
  }, [id]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const newMember: IMember = {
        ...member,
        updatedBy: user?.uid,
        updatedDate: new Date().toISOString(),
      };

      if (!newMember.name || !newMember.email) {
        throw new Error("Please fill in all fields");
      }
      if (id === "create") {
        newMember.createdBy = user;
        newMember.createdDate = new Date().toISOString();
        newMember.organization = user.organization;
        await membersService.create(newMember);
      } else {
        newMember.updatedBy = user;
        newMember.updatedDate = new Date().toISOString();
        await membersService.update(newMember.id, newMember);
      }
      refetchGroups();
      navigate("/members");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error?.response?.data ? error.response.data.error : "Error saving Member");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await membersService.remove(id);
      refetchGroups();
      navigate("/members");
    } catch (error) {
      console.error("Error deleting listing:", error);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const rsvpColumns: GridColDef[] = [
    { field: 'event', headerName: 'Event Name', flex: 2, renderCell: (params) => params.row.event.title },
    { field: 'status', headerName: 'Status', flex: 1 },
    { field: 'guests', headerName: 'Guests', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="text"
          size="small"
          component={Link}
          to={`/rsvps/${params.row.id}`}
        >
          View
        </Button>
      )
    }
  ];

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => navigate(-1)}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="h4">Member</Typography>
          </Box>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="RSVPs" {...a11yProps(1)} />
            <Tab label="Stats" {...a11yProps(2)} />
            <Tab label="Communications" {...a11yProps(3)} />
            <Tab label="Admin" {...a11yProps(4)} />
          </Tabs>
          <Box sx={{ mt: 2 }}>
            {activeTab === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={2}>
                    <Divider />
                    <Divider />

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel required htmlFor="name">
                          Name
                        </FormLabel>
                        <TextField
                          id="name"
                          value={member?.name || ""}
                          onChange={(e) => setMember({ ...member, name: e.target.value })}
                          required
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel required htmlFor="email">
                          Email
                        </FormLabel>
                        <TextField
                          id="email"
                          value={member?.email || ""}
                          onChange={(e) => setMember({ ...member, email: e.target.value })}
                          required
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel required htmlFor="phone">
                          Phone
                        </FormLabel>
                        <TextField
                          id="phone"
                          value={member?.phone || ""}
                          onChange={(e) => setMember({ ...member, phone: e.target.value })}
                          required
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel required htmlFor="membershipType">
                          Membership Type
                        </FormLabel>
                        <Select
                          id="membershipType"
                          value={member?.membershipType || ''}
                          onChange={(e) =>
                            setMember({ ...member, membershipType: e.target.value as MembershipType })
                          }
                          required
                        >
                          <MenuItem value="basic">Basic</MenuItem>
                          <MenuItem value="premium">Premium</MenuItem>
                          <MenuItem value="vip">VIP</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="tags">Tags</FormLabel>
                        <TagMultiSelect
                          selectedTags={member?.tags || []}
                          setSelectedTags={(tags) => {
                            setMember({
                              ...member,
                              tags
                            });
                          }}
                          type={["member", "tag"]}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormLabel id="status-group-label">Is Active?</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="status-group-label"
                          name="row-radio-buttons-group"
                          onChange={(e) => {
                            const isActive = e.target.value === "true";
                            setMember({ ...member, isActive }); // update local state immediately
                          }}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Active"
                            checked={member?.isActive === true} // check if member.isActive is true
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Inactive"
                            checked={member?.isActive === false} // check if member.isActive is false
                          />
                        </RadioGroup>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6}></Grid>
              </Grid>
            )}
            {activeTab === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {rsvps.length > 0 ? (
                    <DataGrid
                      rows={rsvps}
                      columns={rsvpColumns}
                      paginationModel={{ page: 0, pageSize: 10 }}
                      autoHeight
                      disableRowSelectionOnClick
                    />
                  ) : (
                    <Typography>No RSVPs</Typography>
                  )}
                </Grid>
              </Grid>
            )}
            {activeTab === 2 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">Stats</Typography>
                  {/* Add stats content here */}
                </Grid>
              </Grid>
            )}
            {activeTab === 3 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">Communications</Typography>
                  {/* Add communications content here */}
                </Grid>
              </Grid>
            )}
            {activeTab === 4 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">Admin</Typography>
                  {id && id !== "create" && (
                    <>
                      <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                        Danger Zone
                      </Typography>
                      <Button
                        disabled={loading}
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                        sx={{ mt: 1 }}
                      >
                        Delete Member
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        <LoadingOverlay loading={contextLoading || loading} />
      </Grid>
    </BasicLayout>
  );
};

export default Member;
