import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import BasicLayout from "components/Layouts/BasicLayout";
import rsvpsService from "services/rsvps";
import eventsService from "services/events";
import membersService from "services/members";

const RSVPs: React.FC = () => {
  const columns: GridColDef[] = [
    { field: "member.name", headerName: "Member", flex: 1, valueGetter: (params) => params.row.member?.name },
    { field: "member.email", headerName: "Email", flex: 1, valueGetter: (params) => params.row.member?.email },
    { field: "event.title", headerName: "Event", flex: 1, valueGetter: (params) => params.row.event?.title },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      valueGetter: (params) => {
        const status = params.row.status;
        if (!status) return "Pending";

        // Format the status text
        switch (status) {
          case "attending":
            return "Attending";
          case "not_attending":
            return "Not Attending";
          case "maybe":
            return "Maybe";
          default:
            return status.charAt(0).toUpperCase() + status.slice(1); // Capitalize any other statuses
        }
      },
    },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={5} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">RSVPs</Typography>
        </Grid>
        <Grid item xs={7} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/rsvps/create">
            <Icon>add</Icon>
            Create RSVP
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              collectionName="rsvps"
              defaultSortField="createdDate"
              defaultSortDirection="desc"
              columns={columns}
              service={rsvpsService}
              getRowId={(row) => row._id || row.id}
              filterOptions={[
                {
                  label: "Member",
                  name: "member._id",
                  type: "autocomplete",
                  fieldName: "name",
                  service: membersService,
                },
                {
                  name: "event._id",
                  label: "Event",
                  type: "autocomplete",
                  service: eventsService,
                },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default RSVPs;