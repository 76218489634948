import React, { useState, useCallback } from "react"; 
import { Grid, Button, IconButton, Box, FormLabel } from '@mui/material';
import { useDropzone } from "react-dropzone";
import { upload } from "services/images";
import { Edit } from "@mui/icons-material";
import LoadingOverlay from "components/LoadingOverlay";
import ImageModal from "./ImageModal";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import UploadIcon from '@mui/icons-material/CloudUpload';
import { IImage } from "types/image";
interface ImageProps {
  style?: React.CSSProperties;
  onAccept: (image: IImage) => void;
  context?: string;
  bucketPath?: string;
  src?: any;
  alt?: any;
}

const Image: React.FC<ImageProps> = ({
  src,
  alt = "image",
  style = {},
  bucketPath = "cityview-cms/news",
  onAccept,
  context,
}) => {

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Handle drag and drop
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) return;

      const file = acceptedFiles[0];  // Assuming a single file drop
      try {
        setLoading(true);
        const image = await upload(file, bucketPath);
        onAccept(image);  // Set the new image URL
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setLoading(false);
      }
    },
    [bucketPath]
  );

  // Handle drag and drop
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  //Opens the modal to generate an image
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(true);  // Open the modal
  };

  // Closes the modal
  const handleClose = () => {
    setOpen(false);
  };


  // Opens an image editor?
  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    alert("Edit feature coming soon!");
  };

  return (
    <Box 
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      {/* Image box */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          border: src ? 'none' : '1px solid #ccc',  // Apply border if no image
          borderRadius: "10px",
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          src ? (
            <img
              src={src}
              alt={alt}
              style={{ width: '100%', height: '100%', objectFit: 'contain' }} // Image takes up full box
            />
          ) : (
            <p style={{ textAlign: "center" }}>Drag 'n' drop image here, or click icon to select image</p>
          )
        )}
  
        {loading && <LoadingOverlay loading={loading} />}
      </Box>
  
      {/* Button Section */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",  // Aligns the buttons to the bottom-right
        }}
      >
        {/* AI Generate Image Button */}
        <IconButton onClick={handleClick}>
          <SmartToyIcon fontSize="large" color="info" />
        </IconButton>
  
        {/* Upload Icon */}
        <IconButton {...getRootProps()}>
          <UploadIcon fontSize="large" color="info" />
        </IconButton>
  
        {/* Edit Icon */}
        <IconButton onClick={(e) => handleEditClick(e)}>
          <Edit fontSize="large" color="info" />
        </IconButton>
      </Box>
  
      {/* ImageModal for AI image generation */}
      {open && (
        <ImageModal
          open={open}
          onClose={handleClose}
          onAccept={onAccept}
          setLoading={setLoading}
          bucketPath={bucketPath}
          context={context}
        />
      )}
    </Box>
  );
};

export default Image;