import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  FormLabel,
} from '@mui/material';
import { IDataSource } from 'types/dataSource.d';
import dataSourcesService from 'services/dataSources';
import { useAuth } from 'context/Auth';
import AITextField from 'components/AITextField';

type PartialDataSource = Partial<IDataSource> & {
  type: 'website' | 'instagram' | 'facebook' | 'twitter' | 'rss' | 'json';
  dataType: 'events' | 'news' | 'both';
  url: string;
  prompt: string;
  scrapingInterval: 'hourly' | 'every-240-minutes' | 'twice-daily' | 'daily' | 'weekly' | 'monthly';
  active: boolean;
};

interface DataSourceFormProps {
  dataSource?: IDataSource | null;
  parentId?: string;
  parentType?: 'organization' | 'location' | 'group';
  onComplete: () => void;
  onCancel: () => void;
}

const DataSourceForm: React.FC<DataSourceFormProps> = ({
  dataSource,
  parentId,
  parentType,
  onComplete,
  onCancel,
}) => {
  const [formData, setFormData] = useState<PartialDataSource>({
    type: 'website',
    dataType: 'events',
    url: '',
    prompt: '',
    scrapingInterval: 'hourly',
    active: true,
  });

  const { user } = useAuth();

  useEffect(() => {
    if (dataSource) {
      setFormData(dataSource as PartialDataSource);
    }
  }, [dataSource]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name as string]: value }));
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name as string]: value }));
  };

  const handleSubmit = async () => {
    try {
      if (dataSource) {
        await dataSourcesService.update(dataSource.id!, formData);
      } else {
        const newDataSource = {
          ...formData,
          createdBy: user?.uid,
          createdDate: new Date(),
          ...(parentType && { parentType }),
          ...(parentId && { parentId }),
        };
        await dataSourcesService.create(newDataSource);
      }
      onComplete();
    } catch (error) {
      console.error("Error saving data source:", error);
    }
  };

  const urlLabel = useMemo(() => {
    switch (formData.type) {
      case 'website': return 'URL';
      case 'instagram': return 'Instagram Handle';
      case 'facebook': return 'Facebook Handle';
      case 'twitter': return 'Twitter Username';
      case 'rss': return 'RSS URL';
      case 'json': return 'JSON URL';
    }
  }, [formData.type]);

  return (
    <>
      <DialogContent>
        <FormControl fullWidth margin="dense">
          <FormLabel>Type</FormLabel>
          <Select name="type" value={formData.type} onChange={handleSelectChange}>
            <MenuItem value="website">Website</MenuItem>
            <MenuItem value="instagram">Instagram</MenuItem>
            <MenuItem value="facebook">Facebook</MenuItem>
            <MenuItem value="twitter">Twitter</MenuItem>
            <MenuItem value="rss">RSS</MenuItem>
            <MenuItem value="json">JSON</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <FormLabel>Data Type</FormLabel>
          <Select name="dataType" value={formData.dataType} onChange={handleSelectChange}>
            <MenuItem value="events">Events</MenuItem>
            <MenuItem value="news">News</MenuItem>
            <MenuItem value="both">Both</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <FormLabel>{urlLabel}</FormLabel>
          <TextField
            margin="dense"
            name="url"
            type="url"
            fullWidth
            value={formData.url}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <AITextField
            label="Prompt"
            name="prompt"
            value={formData.prompt}
            onChange={(value: string) => setFormData(prev => ({ ...prev, prompt: value }))}
            prompt="Generate a prompt for extracting content from this source"
            promptMode="modal"
            multiline
            rows={4}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <FormLabel>Scraping Interval</FormLabel>
          <Select name="scrapingInterval" value={formData.scrapingInterval} onChange={handleSelectChange}>
            <MenuItem value="hourly">Hourly</MenuItem>
            <MenuItem value="every-240-minutes">Every 4 Hours</MenuItem>
            <MenuItem value="twice-daily">Twice Daily</MenuItem>
            <MenuItem value="daily">Daily</MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
      </DialogActions>
    </>
  );
};

export default DataSourceForm;
