import axios from "axios";
import { API_HOST, API_VERSION } from "../config";
import TokenHandler from "./base";
import { IImage } from "types/image";

export const upload = async (file: File, bucket: string): Promise<IImage> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('bucket', bucket);
  const token = TokenHandler.getToken();

  try {
    const url = `${API_HOST}/images/upload`;
    const response = await axios.post(
      url,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data && response.data.url) {
      return response.data;
    } else {
      throw new Error('Image upload failed');
    }
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const createImageFromUrl = async (imageUrl: string) => {
  const token = TokenHandler.getToken();
  const response = await axios.post(`${API_HOST}/images`, { url: imageUrl }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const generate = async ({
  prompt,
  path = "/images/upload",
}: {
  prompt: string;
  path?: string;
}) => {
  const token = TokenHandler.getToken();
  try {
    const response = await axios.post(
      `${API_HOST}/images/generate`,
      { prompt, path },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    console.log("Error", err);
  }
};

const imageService = {
  upload,
  generate,
  createImageFromUrl,
};

export default imageService;
