import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import videosService from "services/videos";
import { IFilterOptions } from "types/paginatedDataGrid";
import BasicLayout from "components/Layouts/BasicLayout";

const Videos: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "posterUrl",
      headerName: "",
      width: 70,
      renderCell: (params: any) =>
        params.value ? (
          <img src={params?.value} alt="Video" style={{ width: "50px" }} />
        ) : (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ fontSize: "50px !important", fontWeight: 100 }}
          >
            image
          </Icon>
        ),
      sortable: false,
    },
    { field: "title", headerName: "Name", minWidth: 200, flex: 1 },
    // { field: "url", headerName: "Video URL", minWidth: 200, flex: 2 },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Videos</Typography>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/videos/create">
            <Icon>add</Icon>
            Add Video
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              columns={columns}
              collectionName="videos"
              defaultSortField="title"
              defaultSortDirection="desc"
              service={videosService}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Videos;
