import React from 'react';
import {
  Autocomplete,
  createFilterOptions,
  TextField,
} from "@mui/material";
import { useGlobalContext } from 'context/GlobalContext';
import { ITagGroup } from '../../types/tagGroups';
import { ITag } from '../../types/tags';

interface TagMultiSelectProps {
  selectedTags?: any;
  setSelectedTags?: (tags: ITag[]) => void;
  type?: string[];
}

const TagMultiSelect: React.FC<TagMultiSelectProps> = (props) => {
  const { selectedTags, setSelectedTags, type } = props;

  const { tags, tagGroups } = useGlobalContext();

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 10,
  });


  const renderTagsOptionLabel = (tag: ITag) => {
    if (tag) {
      const tagGroup = tagGroups.find((group: ITagGroup) =>
        group.tags.some((t: any) => t._id === tag._id)
      );
      if (tagGroup) {
        return `${tag.title} - ${tagGroup.title}`;
      }
      return tag.title;
    }
    return "";
  };

  return (
    <Autocomplete
      multiple
      value={selectedTags || []}
      onChange={(_e, value) => {
        setSelectedTags?.(value);
      }}
      filterOptions={filterOptions}
      options={type ? tags.filter((tag: any) => type.includes(tag.type)) : tags}
      getOptionLabel={renderTagsOptionLabel}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
    />
  );
};

export default TagMultiSelect;