import React, { useCallback, useMemo, useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  Autocomplete,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  InputAdornment,
  createFilterOptions,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { } from "lodash";
import TextField from "@mui/material/TextField";
import { IGlobalFilter, useGlobalFilterContext } from "./GlobalFilterContext";
import { IFilterOptions } from "types/paginatedDataGrid";
import AutocompleteFilter from './AutocompleteFilter';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs';
import { DateTime } from 'luxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControlLabel, Checkbox } from "@mui/material";

interface PaginationFilterProps {
  filterOptions?: FilterOption[];
  collectionName: string;
}

export interface IFilterOptionOption {
  value: string;
  label: string;
}

export type Operators = "eq" | "gt" | "gte" | "in" | "lt" | "lte" | "ne" | "nin" | "regex" | "search";

export interface FilterOption {
  columnWidthMap?: { [field: string]: string | number };
  label: string;
  name: string;
  options?: IFilterOptionOption[];
  required?: boolean;
  placeholder?: string;
  operator?: Operators;
  service?: any;
  fieldName?: string;
  type:
  | "text"
  | "date"
  | "datetime"
  | "checkbox"
  | "select"
  | "multiSelect"
  | "select"
  | "number"
  | "search"
  | "int"
  | "statusCheckboxes"
  | "autocomplete";
}

const defaultFilterOptions: FilterOption[] = [
  {
    columnWidthMap: { xs: 12 },
    label: "Price",
    name: "Price",
    required: true,
    type: "text",
  }
];

const tagFilterOptions = createFilterOptions({
  ignoreCase: true,
  limit: 10,
});

const PaginationFilter = (props: PaginationFilterProps) => {
  const { filterOptions = defaultFilterOptions, collectionName } = props;

  const { filterValues: contextFilterValues, setFilterValues } = useGlobalFilterContext();
  const [statusFilter, setStatusFilter] = useState({
    pending: false,
    published: false,
  });

  //TODO
  //@ts-ignore
  const filterValues: IFilterOptions[] = useMemo(() => {
    return contextFilterValues[collectionName as keyof typeof contextFilterValues] || [];
  }, [collectionName, contextFilterValues]);

  const [filterExpanded, setFilterExpanded] = useState(false);

  const handleStatusChange = (status: "pending" | "published", checked: boolean) => {
    setStatusFilter((prev) => ({
      ...prev,
      [status]: checked,
    }));

    const pendingSelected = status === "pending" ? checked : statusFilter.pending;
    const publishedSelected = status === "published" ? checked : statusFilter.published;

    if (!pendingSelected && !publishedSelected) {
      setFilterValues((prev: IGlobalFilter[]) => ({
        ...prev,
        [collectionName]: filterValues.filter((f) => f.field !== "status"), // Remove status filter
      }));
    } else {
      const newStatusFilter = [];

      if (pendingSelected) newStatusFilter.push("pending");
      if (publishedSelected) newStatusFilter.push("published");

      setFilterValues((prev: IGlobalFilter[]) => ({
        ...prev,
        [collectionName]: [
          ...filterValues.filter((f) => f.field !== "status"),
          {
            field: "status",
            value: newStatusFilter,
            operator: "in",
          },
        ],
      }));
    }
  };


  // NEEDS FIXED
  const handleDateChange = (date: Dayjs | null, field: string) => {
    setFilterValues((prev: IGlobalFilter[]) => ({
      ...prev,
      [collectionName]: date
        ? [
          ...filterValues.filter((f) => f.field !== field),
          {
            field,
            value: date.toISOString(), // Store ISO string for the selected date
            operator: "in", // You can adjust this operator based on your needs
          },
        ]
        : filterValues.filter((f) => f.field !== field), // Clear the date filter if no date selected
    }));
  };

  const renderFilterField = useCallback(
    (filterOption: FilterOption) => {
      if (filterOption.type === "statusCheckboxes") {
        return (
          <Grid container spacing={1} justifyContent="flex-start" alignItems="center" paddingLeft={.5}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={statusFilter.pending}
                    onChange={(e) => handleStatusChange("pending", e.target.checked)}
                  />
                }
                label="Pending"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={statusFilter.published}
                    onChange={(e) => handleStatusChange("published", e.target.checked)}
                  />
                }
                label="Published"
              />
            </Grid>
          </Grid>
        );
      }

      //TODO: fix this
      if (filterOption.type === "date" || filterOption.type === "datetime") {
        return (
          <DatePicker
            slotProps={{ actionBar: { actions: ["clear", "accept"] } }}
            name={"from-search"}
            value={
              filterValues[filterOption.name as keyof typeof filterValues]
                ? //TODO: fix this
                //@ts-ignore
                dayjs(filterValues[filterOption.name as keyof typeof filterValues])
                : null
            }
            onAccept={(value: any) => {
              setFilterValues((prev: any) => ({
                ...prev,
                [filterOption.name]: {
                  field: filterOption.name,
                  value: value ? DateTime.fromJSDate(value.toDate()).startOf('day').toJSDate() : null,
                  operator: "gte",
                },
                [filterOption.name]: {
                  field: filterOption.name,
                  value: value ? DateTime.fromJSDate(value.toDate()).endOf('day').toJSDate() : null,
                  operator: "lte",
                },
              }));
            }}
          />
        );
      }

      if (filterOption.type === "autocomplete") {
        const value = filterValues.find((filterValue) => filterValue.field === filterOption.name)?.value || null;
        return (
          <AutocompleteFilter

            filterOption={filterOption}
            value={value}
            onChange={(newValue) => {
              setFilterValues((prev: IGlobalFilter[]) => ({
                ...prev,
                [collectionName]: [
                  ...filterValues.filter((filterValue) => filterValue.field !== filterOption.name),
                  ...(newValue ? [{
                    field: filterOption.name,
                    value: newValue.value,
                    operator: 'eq',
                  }] : []),
                ],
              }));
            }}
          />
        );
      }

      if (filterOption.type === "checkbox") {
        const getChecked = () => {
          return (
            !!filterValues?.find((filterValue) => filterValue.field === filterOption.name)?.value ||
            false
          );
        };
        return (
          <>
            <input
              type="checkbox"
              checked={getChecked()}
              onChange={(e) => {
                return !e.target.checked
                  ? setFilterValues((prev: IGlobalFilter[]) => ({
                    ...prev,
                    [collectionName]: [
                      ...filterValues.filter(
                        (filterValue) => filterValue.field !== filterOption.name
                      ),
                    ],
                  }))
                  : setFilterValues((prev: IGlobalFilter[]) => ({
                    ...prev,
                    [collectionName]: [
                      ...filterValues.filter(
                        (filterValue) => filterValue.field !== filterOption.name
                      ),
                      {
                        field: filterOption.name,
                        value: e.target.checked,
                        operator: "eq",
                      },
                    ],
                  }));
              }}
              name={filterOption.name}
              id={filterOption.name}
            />
            <span> </span>
            <label htmlFor={filterOption.name}>{filterOption.label}</label>
          </>
        );
      }

      if (filterOption.type === "select") {
        const getValue = () => {
          const valueString =
            filterValues.find((filterValue) => filterValue.field === filterOption.name)?.value ||
            "";
          return filterOption.options.find((option) => option.value === valueString) || null;
        };

        return (
          <Autocomplete
            id={filterOption.name}
            fullWidth
            value={getValue()}
            onChange={(_e, value) => {
              return !value
                ? setFilterValues((prev: IGlobalFilter[]) => ({
                  ...prev,
                  [collectionName]: [
                    ...filterValues.filter(
                      (filterValue) => filterValue.field !== filterOption.name
                    ),
                  ],
                }))
                : setFilterValues((prev: IGlobalFilter[]) => ({
                  ...prev,
                  [collectionName]: [
                    ...filterValues.filter(
                      (filterValue) => filterValue.field !== filterOption.name
                    ),
                    {
                      field: filterOption.name,
                      value: (value as IFilterOptionOption).value,
                      operator: "eq",
                    },
                  ],
                }));
            }}
            filterOptions={tagFilterOptions}
            options={filterOption.options}
            getOptionLabel={(o) => {
              return (o as { label: string }).label;
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        );
      }

      if (filterOption.type === "multiSelect") {
        const getValue = () => {
          const valueStringArray =
            (filterValues.find((filterValue) => filterValue.field === filterOption.name)
              ?.value as unknown as string[]) || [];
          return valueStringArray.map((v) => {
            return filterOption.options.find((option) => option.value === v);
          });
        };

        return (
          <Autocomplete
            multiple
            id={filterOption.name}
            fullWidth
            value={getValue()}
            onChange={(_e, value) => {
              return !value?.length
                ? setFilterValues((prev: IGlobalFilter[]) => ({
                  ...prev,
                  [collectionName]: [
                    ...filterValues.filter(
                      (filterValue) => filterValue.field !== filterOption.name
                    ),
                  ],
                }))
                : setFilterValues((prev: IGlobalFilter[]) => ({
                  ...prev,
                  [collectionName]: [
                    ...filterValues.filter(
                      (filterValue) => filterValue.field !== filterOption.name
                    ),
                    {
                      field: filterOption.name,
                      value: value.map((v) => (v as IFilterOptionOption).value),
                      operator: "in",
                    },
                  ],
                }));
            }}
            filterOptions={tagFilterOptions}
            options={filterOption.options}
            getOptionLabel={(o) => {
              return (o as { label: string }).label;
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        );
      }

      return (
        <TextField
          type={filterOption.type === "number" || filterOption.type === "int" ? "number" : "text"}
          name={"search" + filterOption.name + filterOption.operator}
          id={"grid-search-" + filterOption.name + filterOption.operator}
          fullWidth
          value={
            filterValues.find((filterValue) => filterValue.field === filterOption.name)?.value || ""
          }
          placeholder={
            filterOption?.placeholder ? filterOption.placeholder : `Enter a ${filterOption.label}`
          }
          autoComplete="off"
          inputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>search</Icon>
              </InputAdornment>
            ),
          }}
          onChange={(e) =>
            //need to delete the filter if the value is empty
            e.target.value === ""
              ? setFilterValues((prev: IGlobalFilter[]) => ({
                ...prev,
                [collectionName]: [
                  ...filterValues.filter(
                    (filterValue) =>
                      filterValue.field !== filterOption.name &&
                      filterValue.operator !== filterOption.operator
                  ),
                ],
              }))
              : setFilterValues((prev: IGlobalFilter[]) => {
                return {
                  ...prev,
                  [collectionName]: [
                    ...filterValues.filter((filterValue) => {
                      return filterValue.field !== filterOption.name;
                    }),
                    {
                      field: filterOption.name,
                      value: e.target.value,
                      operator: filterOption.operator ? filterOption.operator : "search",
                    },
                  ],
                };
              })
          }
        />
      );
    },
    [collectionName, filterValues, setFilterValues]
  );

  return (
    <Accordion
      expanded={filterExpanded}
      onChange={(_e, expanded) => setFilterExpanded(expanded)}
      style={{ paddingRight: "32px", width: "100%" }}
    >
      <AccordionSummary
        id="panel-header"
        aria-controls="panel-content"
        expandIcon={<GridExpandMoreIcon />}
      >
        Filters
      </AccordionSummary>
      <Grid container spacing={2} padding={"10px"} margin={0}>
        {filterOptions.map((filterOption: FilterOption, index) => (
          <Grid
            key={index}
            item
            {...(filterOption.columnWidthMap ? filterOption.columnWidthMap : { xs: 12 })}
          >
            <FormControl fullWidth>
              {filterOption.type !== "checkbox" && (
                <FormLabel required={filterOption.required}>
                  {filterOption.label || filterOption.name}
                </FormLabel>
              )}
            </FormControl>
            {renderFilterField(filterOption)}
          </Grid>
        ))}
      </Grid>
    </Accordion>
  );
};

export default PaginationFilter;