import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Typography,
  TextField,
  Snackbar,
  Alert,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import { useAuth } from "context/Auth";
import { ITag } from "types/tags";
import tagsService from "services/tags";
import LoadingOverlay from "components/LoadingOverlay";
import { upload } from "services/images";
import { useDropzone } from "react-dropzone";
import BasicLayout from "components/Layouts/BasicLayout";
import { useGlobalContext } from "context/GlobalContext";
import { ChevronLeft } from "@mui/icons-material";
import Image from "components/Images/Image";
import { IImage } from "types/image";

const Tag: React.FC = () => {
  const [tag, setTag] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<any>({ open: false, message: "", severity: "success" });
  const [error, setError] = useState("");
  const { refetchTags, loading: contextLoading } = useGlobalContext();

  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchTag = async () => {
      try {
        setLoading(true);
        const _tag: ITag = await tagsService.fetch(id);
        setTag(_tag);
      } catch (e) {
        console.error("Error fetching tag group:", e);
      } finally {
        setLoading(false);
      }
    };
    if (id && id !== "create") {
      fetchTag();
    }
  }, [id]);

  // Function to delete a tag
  const handleDelete = async () => {
    try {
      await tagsService.remove(id);
      navigate("/tags");
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
    refetchTags();
  };

  // Function to save a tag
  const handleSave = async () => {
    try {
      setLoading(true);
      const newTag: ITag = {
        ...tag,
      };

      if (!newTag?.title || !newTag?.description || !tag?.defaultImage?.url) {
        throw new Error("Please fill in all fields");
      }

      if (id === "create") {
        // TODO Check to make sure it doesn't already exist
        newTag.createdBy = user;
        newTag.createdDate = new Date().toISOString();
        await tagsService.create(newTag);
      } else {
        newTag.updatedBy = user;
        newTag.updatedAt = new Date().toISOString();
        await tagsService.update(newTag.id, newTag);
      }
      refetchTags();
      //on successful save, navigate back to tags
      navigate("/tags");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasicLayout>
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={12} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} display={"flex"} alignItems={"center"}>
              <IconButton onClick={() => navigate(-1)}>
                <ChevronLeft />
              </IconButton>
              <Typography variant="h4">Tag</Typography>
            </Grid>
            <Grid item xs={12}>
              {!id ||
                (id === "create" ? (
                  <Typography variant="body1">
                    Create a tag that can be tied to a listing.
                  </Typography>
                ) : (
                  <Typography variant="body1">Edit this tag.</Typography>
                ))}
            </Grid>
            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={12} md={5} marginTop={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px ' }}>
                <Image
                  onAccept={(image: IImage) => setTag({ ...tag, defaultImage: image })}
                  src={tag?.defaultImage?.url}
                  alt={tag?.title}
                  bucketPath="cityview-cms/tags"
                />
              </Grid>
              <Grid item xs={12} md={7} spacing={2}>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel required htmlFor="tag-name">
                      Name
                    </FormLabel>
                    <TextField
                      id="tag-name"
                      value={tag?.title || ""}
                      required
                      onChange={(e) => setTag({ ...tag, title: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel required htmlFor="description">
                      Description
                    </FormLabel>
                    <TextField
                      id="description"
                      value={tag?.description || ""}
                      required
                      onChange={(e) => setTag({ ...tag, description: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel required htmlFor="tag-type">Tag Type</FormLabel>
                    <Select
                      id="tag-type"
                      value={tag?.type || 'tag'}
                      required
                      onChange={(e) => setTag({ ...tag, type: e.target.value })}
                    >
                      <MenuItem value="tag">Tag</MenuItem>
                      <MenuItem value="event">Event</MenuItem>
                      <MenuItem value="location">Location</MenuItem>
                      <MenuItem value="news">News</MenuItem>
                      <MenuItem value="member">Member</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                  <Button variant="contained" color="primary" onClick={handleSave}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}></Grid>
        </Grid>
        <Grid item xs={12} />
        {id && id !== "create" && (
          <Grid item xs={12}>
            <Typography variant="h4" color="alert">
              Danger Zone
            </Typography>
            <Button
              disabled={loading || contextLoading}
              variant="contained"
              color="error"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Grid>
        )}
        <LoadingOverlay loading={loading || contextLoading} />
        <Snackbar
          open={snackbar.open}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
        </Snackbar>
      </Grid>
    </BasicLayout>
  );
};

export default Tag;
