import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Typography,
  TextField,
  Snackbar,
  Alert,
  IconButton, 
  Select,
  MenuItem,
} from "@mui/material";
import { useAuth } from "context/Auth";
import { IAuthor } from "types/authors";
import authorsService from "services/authors";
import LoadingOverlay from "components/LoadingOverlay";
import { useDropzone } from "react-dropzone";
import BasicLayout from "components/Layouts/BasicLayout";
import { ChevronLeft } from "@mui/icons-material";
import { upload } from "services/images";
import Image from "components/Images/Image";
import { IImage } from "types/image";

const Author: React.FC = () => {
  const [author, setAuthor] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<any>({ open: false, message: "", severity: "success" });
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchAuthor = async () => {
      try {
        setLoading(true);
        const _author: IAuthor = await authorsService.fetch(id);
        setAuthor(_author);
      } catch (e) {
        console.error("Error fetching author group:", e);
      } finally {
        setLoading(false);
      }
    };
    if (id && id !== "create") {
      fetchAuthor();
    }
  }, [id]);
  
  console.log("author", author);

  // Function to delete a author
  const handleDelete = async () => {
    try {
      await authorsService.remove(id);
      navigate("/authors");
    } catch (error) {
      console.error("Error deleting author:", error);
    }
  };

  // Function to save a author
  const handleSave = async () => {
    try {
      setLoading(true);
      const newAuthor: IAuthor = {
        ...author,
      };

      if (!newAuthor?.name || !newAuthor?.defaultImage) {
        console.log("newAuthor", newAuthor);
        throw new Error("Please fill in all fields");
      }

      if (id === "create") {
        // TODO Check to make sure it doesn't already exist
        newAuthor.createdBy = user;
        newAuthor.createdDate = new Date();
        await authorsService.create(newAuthor);
      } else {
        newAuthor.updatedBy = user;
        newAuthor.updatedDate = new Date();
        await authorsService.update(newAuthor.id, newAuthor);
      }
      //on successful save, navigate back to authors
      navigate("/authors");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  console.log("author", author);

  return (
    <BasicLayout>
      <Grid container direction={"column"} spacing={2}>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        <Grid item xs={12} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12} display={"flex"} alignItems={"center"}>
                <IconButton onClick={() => navigate(-1)}>
                  <ChevronLeft />
                </IconButton>
                <Typography variant="h4">Author</Typography>
              </Grid>

              {!id ||
                (id === "create" ? (
                  <Typography variant="body1">
                    Create a author that can be tied to a listing.
                  </Typography>
                ) : (
                  <Typography variant="body1">Edit this author.</Typography>
                ))}
            </Grid>
            <Grid container marginTop={2} spacing={2}>
              <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                <Image
                  onAccept={(image: IImage) => setAuthor({ ...author, defaultImage: image })}
                  src={author.defaultImage?.url}
                  alt={author.name}
                  bucketPath="cityview-cms/authors"
                />
              </Grid>
              <Grid item direction={"column"} xs={10} md={6} spacing={2}>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel required htmlFor="author-name">
                      Name
                    </FormLabel>
                    <TextField
                      id="author-name"
                      value={author?.name || ""}
                      required
                      onChange={(e) => setAuthor({ ...author, name: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="author-category">Category</FormLabel>
                    <TextField
                      id="author-category"
                      value={author?.category || ""}
                      onChange={(e) => setAuthor({ ...author, category: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="author-fun-title">Fun Title</FormLabel>
                    <TextField
                      id="author-fun-title"
                      value={author?.funTitle || ""}
                      onChange={(e) => setAuthor({ ...author, funTitle: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="author-serious-title">Serious Title</FormLabel>
                    <TextField
                      id="author-serious-title"
                      value={author?.seriousTitle || ""}
                      onChange={(e) => setAuthor({ ...author, seriousTitle: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="author-short-bio">Short Bio</FormLabel>
                    <TextField
                      id="author-short-bio"
                      value={author?.shortBio || ""}
                      onChange={(e) => setAuthor({ ...author, shortBio: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="author-long-bio">Long Bio</FormLabel>
                    <TextField
                      id="author-long-bio"
                      value={author?.longBio || ""}
                      onChange={(e) => setAuthor({ ...author, longBio: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                {/* <Grid item>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="author-interests">Interests</FormLabel>
                    <TextField
                      id="author-interests"
                      value={author?.interests?.join(", ") || ""}
                      onChange={(e) =>
                        setAuthor({ ...author, interests: e.target.value.split(",") })
                      }
                    />
                  </FormControl>
                </Grid> */}
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel style={{ color: "#666" }} htmlFor="author-interests">
                      Voice Source
                    </FormLabel>
                    <Select
                      id="author-voice-source"
                      value={author?.voiceSource || ""}
                      onChange={(e: any) => setAuthor({ ...author, voiceSource: e.target.value })}
                    >
                      <MenuItem value="openai">OpenAI</MenuItem>
                      <MenuItem value="elevenlabs">ElevenLabs</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="author-interests">VoiceID</FormLabel>
                    <TextField
                      id="author-voiceid"
                      value={author?.voiceID || ""}
                      onChange={(e) => setAuthor({ ...author, voiceID: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="author-interests">Video Overlay</FormLabel>
                    <TextField
                      id="author-voiceid"
                      value={author?.videoOverlay || ""}
                      onChange={(e) => setAuthor({ ...author, voiceID: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                  <Button variant="contained" color="primary" onClick={handleSave}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}></Grid>
        </Grid>
        <Grid item xs={12} />
        {id && id !== "create" && (
          <Grid item xs={12}>
            <Typography variant="h4" color="alert">
              Danger Zone
            </Typography>
            <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
              Delete
            </Button>
          </Grid>
        )}
        <LoadingOverlay loading={loading} />
        <Snackbar
          open={snackbar.open}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
        </Snackbar>
      </Grid>
    </BasicLayout>
  );
};

export default Author;