import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
  MenuItem,
} from '@mui/material';
import { useAuth } from 'context/Auth';
import BasicLayout from 'components/Layouts/BasicLayout';
import { GridColDef } from '@mui/x-data-grid';
import phoneNumbersService from 'services/phoneNumbers';
import { IPhoneNumber } from 'types/phoneNumbers';
import { DataGrid } from "@mui/x-data-grid";

const TwilioPhoneNumbers: React.FC = () => {
  const [areaCode, setAreaCode] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [availableNumbers, setAvailableNumbers] = useState<IPhoneNumber[]>([]);
  const [organizationNumbers, setOrganizationNumbers] = useState<IPhoneNumber[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState<IPhoneNumber | null>(null);
  const { user } = useAuth();

  const isAdmin = user?.role === 'admin';

  const fetchAvailableNumbers = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await phoneNumbersService.fetchAvailable({ areaCode, zipCode });
      setAvailableNumbers(response.data);
    } catch (err) {
      setError('Failed to fetch available numbers');
      console.error(err);
    }
    setLoading(false);
  };

  const fetchOrganizationNumbers = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await phoneNumbersService.fetchAll();
      setOrganizationNumbers(response.data);
    } catch (err) {
      setError('Failed to fetch organization numbers');
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrganizationNumbers();
  }, []);

  const handleAddNumber = async (number: IPhoneNumber) => {
    if (!isAdmin) return;
    setLoading(true);
    setError('');
    try {
      await phoneNumbersService.create(number);
      fetchOrganizationNumbers();
    } catch (err) {
      setError('Failed to add number');
      console.error(err);
    }
    setLoading(false);
  };

  const handleUpdateNumber = async (id: string, data: Partial<IPhoneNumber>) => {
    if (!isAdmin) return;
    setLoading(true);
    setError('');
    try {
      await phoneNumbersService.update(id, data);
      fetchOrganizationNumbers();
      setUpdateModalOpen(false);
    } catch (err) {
      setError('Failed to update number');
      console.error(err);
    }
    setLoading(false);
  };

  const handleDeleteNumber = async (id: string) => {
    if (!isAdmin) return;
    setLoading(true);
    setError('');
    try {
      await phoneNumbersService.remove(id);
      fetchOrganizationNumbers();
    } catch (err) {
      setError('Failed to delete number');
      console.error(err);
    }
    setLoading(false);
  };

  const availableNumbersColumns: GridColDef[] = [
    { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
    { field: 'friendlyName', headerName: 'Friendly Name', flex: 1 },
    { field: 'locality', headerName: 'Locality', flex: 1 },
    { field: 'region', headerName: 'Region', flex: 1 },
    { field: 'price', headerName: 'Price', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleAddNumber(params.row)}
          disabled={!isAdmin}
        >
          Add
        </Button>
      ),
    },
  ];

  const organizationNumbersColumns: GridColDef[] = [
    { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
    { field: 'friendlyName', headerName: 'Friendly Name', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedNumber(params.row);
              setUpdateModalOpen(true);
            }}
            disabled={!isAdmin}
          >
            Update
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleteNumber(params.row.id)}
            disabled={!isAdmin}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <BasicLayout>
      <Typography variant="h4" gutterBottom>
        Twilio Phone Numbers
      </Typography>
      <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Search Available Numbers
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Area Code"
              value={areaCode}
              onChange={(e) => setAreaCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Zip Code"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={fetchAvailableNumbers}
              disabled={loading}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Typography variant="h6" gutterBottom>
        Available Numbers
      </Typography>
      <DataGrid
        columns={availableNumbersColumns}
        rows={availableNumbers || []}
        loading={loading}
      />

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Organization Numbers
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={fetchOrganizationNumbers}
          disabled={loading}
          sx={{ mb: 2 }}
        >
          Refresh
        </Button>
        <DataGrid
          columns={organizationNumbersColumns}
          rows={organizationNumbers || []}
          loading={loading}
        />
      </Box>

      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      <Dialog open={updateModalOpen} onClose={() => setUpdateModalOpen(false)}>
        <DialogTitle>Update Phone Number</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Friendly Name"
            value={selectedNumber?.friendlyName || ''}
            onChange={(e) =>
              setSelectedNumber((prev) => ({ ...prev, friendlyName: e.target.value }))
            }
            sx={{ mt: 2 }}
          />
          <TextField
            select
            fullWidth
            label="Status"
            value={selectedNumber?.status || ''}
            onChange={(e) => setSelectedNumber((prev) => ({ ...prev, status: e.target.value as "active" | "inactive" }))}
            sx={{ mt: 2 }}
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpdateModalOpen(false)}>Cancel</Button>
          <Button
            onClick={() => selectedNumber && handleUpdateNumber(selectedNumber.id, selectedNumber)}
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </BasicLayout>
  );
};

export default TwilioPhoneNumbers;
