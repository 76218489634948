import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Autocomplete,
  createFilterOptions,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Icon,
  Tabs,
  Tab,
  Box,
  Alert
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import AITextField from "components/AITextField";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import eventsService from "services/events";
import { useGlobalContext } from "context/GlobalContext";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";
import { IEvent, IGenerateEventImageResponse, StatusEnum } from "types/events.d";
import { IImage } from "types/image";
import { ILocationData } from "types/locations.d";
import debounce from "lodash/debounce";
import ImageGallery from "components/ImageGallery";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import membersService from "services/members";
import rsvpsService from "services/rsvps";
import TagMultiSelect from "components/TagMultiSelect/TagMultiSelect";

import PromotionSelect from "components/PromotionSelect";
import TipTap from "components/TipTap";
import EventOccurrenceForm from "components/EventOccurrenceForm";
import EventRecurrenceForm from "components/EventRecurrenceForm";
import Image from "components/Images/Image";
import FileDropzone from "components/FileDropzone";
import { ITag } from "types/tags";

interface IEventAPIRequest extends Omit<IEvent, "location"> {
  location: string;
}
const Event: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const DEFAULT_CREATE_EVENT: IEvent = {
    title: "",
    createdDate: new Date().toISOString(),
    link: "",
    location: undefined,
    slug: "",
    tagIds: [],
    thumbnail: "",
    tags: [],
    images: [],
    id: "",
    updatedBy: user?.uid,
    updatedDate: new Date().toISOString(),
    status: StatusEnum.Published,
    eventType: 'single',
    organization: null,
    allowGuests: false,
    guestsLimit: 0,
    waitlistEnabled: false,
    description: "",
    startDate: new Date(),
    startTime: "18:00",
  };
  const [event, setEvent] = useState<IEvent | null>(id ? DEFAULT_CREATE_EVENT : null);

  const { loading: contextLoading, locations, tags, videos, tagGroups } = useGlobalContext();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const event: IEvent = await eventsService.fetch(id);
        //not all events have status, so we set it to published by default
        if (!event.status) {
          event.status = StatusEnum.Published;
        }
        setEvent(event);
      } catch (e) {
        console.error("Error fetching event:", e);
      }
    };

    if (id && id !== "create") {
      fetchEvent();
    }
  }, [id]);

  const navigate = useNavigate();

  const handleSave = async (newStatus: StatusEnum) => {
    try {
      setLoading(true);

      const newEvent: IEventAPIRequest = {
        ...event,
        location: event?.location?.id,
        updatedBy: user?.uid,
        updatedDate: new Date().toISOString(),
        tagIds: event.tags?.map((tag: any) => tag.id),
        status: newStatus, // Set the new status
      };
      if (
        !newEvent.title ||
        !newEvent.link ||
        !newEvent.eventType ||
        !newEvent.startDate
      ) {
        throw new Error("Please fill in all fields");
      }
      if (id === "create") {
        newEvent.createdBy = user;
        newEvent.createdDate = new Date().toISOString();
        delete newEvent.id;
        await eventsService.create(newEvent);
      } else {
        newEvent.updatedBy = user;
        newEvent.updatedDate = new Date().toISOString();
        await eventsService.update(newEvent.id || newEvent._id, newEvent);
      }

      // Redirect to previous page after saving
      navigate(-1);
    } catch (error: any) {
      console.error("Error saving event:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await eventsService.remove(id);
      navigate("/events");
    } catch (error) {
      console.error("Error deleting listing:", error);
    }
  };

  const filterOptions = createFilterOptions({
    ignoreCase: true,
  });

  const renderTagsOptionLabel = (option: any) => {
    const tag = tags.find((tag: any) => tag.id === option);
    const tagGroup = tagGroups.find((tagGroup: any) =>
      tagGroup.tags.some((t: any) => t?.id === tag?.id)
    );
    if (tag) {
      if (tagGroup) {
        return `${tag.title} - ${tagGroup.title}`;
      }
      return tag?.title;
    }
    return option || "";
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleImageChange = (image: IImage) => {
    setEvent((prevEvent: IEvent) => ({ ...prevEvent, defaultImage: image }));
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const columns: GridColDef[] = [
    { field: "member.name", headerName: "Member", flex: 1, valueGetter: (params) => params.row.member?.name },
    { field: "member.email", headerName: "Email", flex: 1, valueGetter: (params) => params.row.member?.email },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      valueGetter: (params) => {
        const status = params.row.status;
        if (!status) return "Pending";

        // Format the status text
        switch (status) {
          case "attending":
            return "Attending";
          case "not_attending":
            return "Not Attending";
          case "maybe":
            return "Maybe";
          default:
            return status.charAt(0).toUpperCase() + status.slice(1); // Capitalize any other statuses
        }
      },
    },
    { field: "guests", headerName: "Attendees", flex: 1, valueGetter: (params) => params.row.guests },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"}>
            <Grid item display={"flex"} alignItems={"center"}>
              <IconButton onClick={() => navigate(-1)}>
                <ChevronLeft />
              </IconButton>
              <Typography variant="h4">Event</Typography>
            </Grid>
            <Grid item>
              <Button
                disabled={loading}
                variant="contained"
                color="secondary"
                onClick={() => handleSave(StatusEnum.Pending)}
                sx={{ mr: 1 }}
              >
                Save Draft
              </Button>
              {(
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  onClick={() => handleSave(StatusEnum.Published)}
                >
                  Publish
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        {error && <Grid item xs={12}><Alert severity="error">{error}</Alert></Grid>}
        <Grid item xs={12}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Occurrence" {...a11yProps(1)} />
            <Tab label="Media" {...a11yProps(2)} />
            <Tab label="Promotions" {...a11yProps(3)} />
            <Tab label="RSVPs" {...a11yProps(4)} />
            <Tab label="Admin" {...a11yProps(5)} />
          </Tabs>
          <Box sx={{ mt: 2 }}>
            {activeTab === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Box sx={{ paddingTop: 8 }}>
                    <Image
                      onAccept={handleImageChange}
                      src={event.defaultImage?.url}
                      alt={event.title}
                      bucketPath="cityview-cms/events"
                      style={{ height: '400px' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <AITextField
                        id="title"
                        label="Title"
                        prompt="From the following details, create a title of an event. The title should be descriptive and engaging while being lesss than 6 words."
                        value={event?.title || ""}
                        onChange={(e: any) => setEvent({ ...event, title: e })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AITextField
                        id="description"
                        label="Description"
                        prompt="From the following details, create a description of an event. The title should be descriptive and engaging and be at least 3 sentences long."
                        promptMode="modal"
                        value={event?.description || ""}
                        multiline
                        rows={4}
                        onChange={(e: any) => setEvent({ ...event, description: e })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormLabel>Content</FormLabel>
                      <TipTap
                        content={event.content}
                        onChange={debounce(
                          (value: JSON) => setEvent({ ...event, content: value }),
                          300
                        )}
                        bucketPath="cityview-cms/events"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="title">Price</FormLabel>
                        <TextField
                          id="price"
                          value={event?.price || ""}
                          onChange={(e) => setEvent({ ...event, price: e.target.value })}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="link">
                          Link
                          <a href={event?.link} target="_blank" rel="noreferrer">
                            <Icon>open_in_new</Icon>
                          </a>
                        </FormLabel>
                        <TextField
                          id="link"
                          value={event?.link || ""}
                          onChange={(e) => setEvent({ ...event, link: e.target.value })}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="tags">Tags</FormLabel>
                        <TagMultiSelect
                          selectedTags={event?.tags || []}
                          setSelectedTags={(tags) => {
                            setEvent({
                              ...event,
                              tags
                            });
                          }}
                          type={["event", "tag"]}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="location">Location</FormLabel>
                        <Autocomplete
                          value={event?.location || null}
                          onChange={(_e: any, value: ILocationData | null) => {
                            setEvent({
                              ...event,
                              location: value,
                            });
                          }}
                          getOptionLabel={(option: ILocationData | string) => {
                            if (typeof option === 'string') return option;
                            return option?.name || '';
                          }}
                          options={locations || []}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </FormControl>
                    </Grid>
                    {event?.location && (
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', mt: 2 }}>
                          <Box sx={{ flexBasis: '30%', mr: 2 }}>
                            {event.location.thumbnail ? (
                              <img
                                src={event.location.thumbnail}
                                alt={event.location.name}
                                style={{ width: '100%', height: 'auto' }}
                              />
                            ) : (
                              <Box
                                sx={{
                                  width: '100%',
                                  height: '200px',
                                  bgcolor: 'grey.300',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <Typography>No image available</Typography>
                              </Box>
                            )}
                          </Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="h6">{event.location.name}</Typography>
                            <Typography>{event.location.address1}</Typography>
                            <Typography>{event.location.city}, {event.location.state} {event.location.zip}</Typography>
                            {event.location.link && (
                              <Typography>
                                Website: <a href={event.location.link} target="_blank" rel="noopener noreferrer">{event.location.link}</a>
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {activeTab === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel focused={false} htmlFor="event-type-group">
                      Event Type
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="event-type-group"
                      name="event-type-group"
                      value={event.eventType}
                      onChange={(e) => setEvent({ ...event, eventType: e.target.value as 'single' | 'multi' | 'recurring' })}
                    >
                      <FormControlLabel
                        value="single"
                        control={<Radio />}
                        label="Single Occurrence"
                      />
                      <FormControlLabel
                        value="multi"
                        control={<Radio />}
                        label="Multi-Occurrence"
                      />
                      <FormControlLabel
                        value="recurring"
                        control={<Radio />}
                        label="Recurring Event"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {event.eventType === 'single' || event.eventType === 'multi' ? (
                    <EventOccurrenceForm
                      event={event}
                      onChange={(updatedEventData) => setEvent({ ...event, ...updatedEventData })}
                    />
                  ) : (
                    <EventRecurrenceForm
                      event={event}
                      onChange={(updatedEventData) => setEvent({ ...event, ...updatedEventData })}
                    />
                  )}
                </Grid>
              </Grid>
            )}
            {activeTab === 2 && (
              <Box sx={{ pt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel htmlFor="images">Images</FormLabel>
                    <ImageGallery
                      images={event?.images || []}
                      onDelete={(imageId: string) => {
                        setEvent({
                          ...event,
                          images: event?.images?.filter((image: IImage) => image.id !== imageId),
                        });
                      }}
                      onBulkDelete={(imageIds: string[]) => {
                        setEvent({
                          ...event,
                          images: event?.images?.filter((image: IImage) => !imageIds.includes(image.id)),
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FileDropzone
                      accept={{
                        "image/jpeg": [],
                        "image/png": [],
                      }}
                      onComplete={(result: any[]) => {
                        setEvent({
                          ...event,
                          images: [...(event.images || []), ...result],
                        });
                      }}
                      onUpload={(image: IImage) => {
                        setEvent({
                          ...event,
                          images: [...(event.images || []), image],
                        })
                        console.log(image);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="video">Video</FormLabel>
                      <Autocomplete
                        value={event?.defaultVideo?.id || ""}
                        freeSolo
                        onChange={(e, value) =>
                          setEvent({
                            ...event,
                            defaultVideo: videos?.find((video: any) => video.id === value),
                          })
                        }
                        options={videos?.map((video: any) => video.id)}
                        getOptionLabel={(option) =>
                          videos?.find((video: any) => video.id === option)?.title || option || ""
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            )}
            {activeTab === 3 && (
              <Grid container spacing={2}>
                {/* Promotions tab content */}
                <Grid item xs={12}>
                  <FormGroup>
                    <FormLabel id="status-group-label">Monitization Options</FormLabel>
                    <FormControlLabel
                      htmlFor="featured"
                      label={"Featured"}
                      control={
                        <Checkbox
                          checked={event?.featured || false}
                          onChange={(e) => setEvent({ ...event, featured: e.target.checked })}
                        />
                      }
                    />
                    <FormControlLabel
                      htmlFor="boosted"
                      label={"Boosted"}
                      control={
                        <Checkbox
                          checked={event?.boosted || false}
                          onChange={(e) => setEvent({ ...event, boosted: e.target.checked })}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel id="promotion">Current Promotion</FormLabel>
                  <PromotionSelect
                    value={event?.promotion}
                    onChange={(promotion: any) => setEvent({ ...event, promotion })}
                  />
                </Grid>
              </Grid>
            )}
            {activeTab === 4 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">RSVPs</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <PaginatedDataGrid
                      collectionName="rsvps"
                      defaultSortField="createdDate"
                      defaultSortDirection="desc"
                      columns={columns}
                      service={rsvpsService}
                      getRowId={(row) => row._id || row.id}
                      defaultQuery={[
                        {
                          field: "event._id",
                          operator: "eq",
                          value: event._id
                        }
                      ]}
                      filterOptions={[
                        {
                          label: "Member",
                          name: "member._id",
                          type: "autocomplete",
                          fieldName: "name",
                          service: membersService,
                        }
                      ]}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
            {activeTab === 5 && (
              <Grid container spacing={2}>
                {/* Admin tab content */}
                <Grid item xs={12}>
                  <FormGroup>
                    <FormLabel id="status-group-label">Publishing Status</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="status-group-label"
                      name="row-radio-buttons-group"
                      value={event.status}
                      onChange={(e) => setEvent({ ...event, status: e.target.value as StatusEnum })}
                    >
                      <FormControlLabel
                        value={StatusEnum.Pending}
                        control={<Radio />}
                        label="Pending"
                      />
                      <FormControlLabel
                        value={StatusEnum.Published}
                        control={<Radio />}
                        label="Published"
                      />
                    </RadioGroup>
                  </FormGroup>
                </Grid>
                {id && id !== "create" && (
                  <Grid item xs={12}>
                    <Typography variant="h4" color="error">
                      Danger Zone
                    </Typography>
                    <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                      Delete
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Box>
        </Grid>
        <LoadingOverlay loading={loading || contextLoading} />
      </Grid>
    </BasicLayout>
  );
};

export default Event;
