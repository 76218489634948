import { API_HOST } from "../config";
import axios from "axios";
import TokenHandler from "./base";
import { IService, IPaginationModal } from "types/App";
import { IInvitation } from "types/invitations";

const invitationsService: IService = {
  fetchAll: async (paginationModel?: IPaginationModal) => {
    const token = TokenHandler.getToken();
    const filteredPagination = { ...paginationModel };
    delete filteredPagination.query;
    delete filteredPagination.sort;

    const url = new URL(`${API_HOST}/invitations`);
    url.search = new URLSearchParams(filteredPagination).toString();

    const params = {
      ...(paginationModel?.query && { query: paginationModel.query }),
      ...(paginationModel?.sort && { sort: paginationModel.sort }),
    };
    const response = await axios.get(url.toString(), {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  fetch: async (id: string) => {
    const token = TokenHandler.getToken();
    const response = await axios.get(`${API_HOST}/invitations/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  create: async (data: IInvitation) => {
    const token = TokenHandler.getToken();
    const response = await axios.post(`${API_HOST}/invitations`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  update: async (id: string, data: Partial<IInvitation>) => {
    const token = TokenHandler.getToken();
    const response = await axios.put(`${API_HOST}/invitations/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  remove: async (id: string) => {
    const token = TokenHandler.getToken();
    const response = await axios.delete(`${API_HOST}/invitations/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },
};

export default invitationsService;