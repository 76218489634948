export enum QRCodeType {
  SYSTEM = 'system',
  USER = 'user'
}

export interface IQRCode {
  id: string;
  name: string;
  label: string;
  code: string;
  url: string;
  eventId?: string;
  type: QRCodeType;  // New field
  createdAt: string;
  createdBy: string;
  updatedAt?: string;
  updatedBy?: string;
}