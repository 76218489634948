import React, { useCallback, useState, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { IFilterOptionOption, FilterOption } from './PaginationFilter';

interface AutocompleteFilterProps {
  filterOption: FilterOption;
  value: any;
  onChange: (newValue: any) => void;
}

const AutocompleteFilter: React.FC<AutocompleteFilterProps> = ({ filterOption, value, onChange }) => {
  const [options, setOptions] = useState<IFilterOptionOption[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchOptions = async (search: string = '') => {
    setLoading(true);
    try {
      const fieldName = filterOption.fieldName || 'title';
      const query = search ? [{ field: fieldName, operator: 'search', value: search }] : [];
      const { data } = await filterOption.service.fetchAll({ query });
      setOptions(data.map((item: any) => ({ value: item.id, label: item[fieldName] })));
    } catch (error) {
      console.error('Error fetching options:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <Autocomplete
      id={filterOption.name}
      fullWidth
      options={options}
      loading={loading}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue.length > 2) {
          fetchOptions(newInputValue);
        }
      }}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      getOptionLabel={(option) => option.label || ''}
      isOptionEqualToValue={(option, value) => option.value === value || (value?.value && value.value === option.value)}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteFilter;