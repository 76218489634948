import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Grid, FormGroup, FormLabel, FormControlLabel, RadioGroup, Radio, Typography } from '@mui/material';
import imageService from "services/images";
import { IImage } from "types/image";

interface ImageModalProps {
  open: boolean;  // Controls whether the modal is open or closed
  onClose: () => void;  // Function to handle closing the modal
  onAccept: (image: IImage) => void;  // Function to save the image URL
  setLoading?: (loading: boolean) => void;  // Function to set the loading state
  bucketPath?: string;
  context?: string;
}

const DEFAULT_TEXT = "";

const ImageModal: React.FC<ImageModalProps> = ({ open, onClose, onAccept, setLoading, bucketPath, context = "" }) => {

  const [generatedImageUrl, setGeneratedImageUrl] = useState<string | null>(null);  // State for the generated image
  const [newPrompt, setNewPrompt] = React.useState<string | undefined>(DEFAULT_TEXT);
  const [promptStyle, setPromptStyle] = useState<any>("");
  const [size, setSize] = useState<any>("medium");

  const generatePrompt = () => {
    // Combine description, style, and size into the prompt
    let generationPrompt = `Using the following article context: "${context}", generate an image that matches the description: "${newPrompt}" in the style of "${promptStyle}"`;
    return generationPrompt;
  };

  // Map selected size to the corresponding image dimensions
  const getImageSize = (size: string) => {
    switch (size) {
      case "small":
        return "512x512";
      case "large":
        return "1792x1024";
      default:
        return "1024x1024";  // Default to medium
    }
  };

  // Handle the click event of the "Generate" button
  const handleGenerateClick = async () => {
    try {
      setLoading(true);
      if (!newPrompt || !promptStyle) {
        alert("Please fill in both the prompt and the art style.");
        return;
      }

      // Use image service to generate the image based on the prompt and selected size
      const response = await imageService.generate({
        prompt: generatePrompt(),
        path: bucketPath,
      });

      setGeneratedImageUrl(response.data.image);  // Set the generated image URL
      console.log(response)
    } catch (error: any) {
      console.error("Error generating image:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle the click event of the "Save" button
  const handleSaveClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    // Create an image object with the generated image URL

    if (generatedImageUrl) {
      console.log(generatedImageUrl)
      const { data: generatedImage } = await imageService.createImageFromUrl(generatedImageUrl);
      onAccept(generatedImage);
      console.log(generatedImage)
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Generate an AI Image</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Left column: Display the generated image */}
          <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
            {generatedImageUrl ? (
              <img
                src={generatedImageUrl}
                alt="Generated"
                style={{ maxWidth: '100%', objectFit: 'contain' }}
              />
            ) : (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  border: '1px dashed #ccc',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p>No image generated yet</p>
              </div>
            )}
          </Grid>

          {/* Right column: Input for description and buttons */}
          <Grid item xs={12} md={6} display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={2}>
            {/* Prompt */}
            <TextField
              autoFocus
              margin="dense"
              id="first-prompt"
              label="Prompt"
              type="text"
              fullWidth
              value={newPrompt}
              onChange={(e) => setNewPrompt(e.target.value)}
              multiline
              rows={5}
            />

            {/* Prompt Style */}
            <TextField
              margin="dense"
              id="second-prompt"
              label="Art Style"
              type="text"
              fullWidth
              value={promptStyle}
              onChange={(e) => setPromptStyle(e.target.value)}
              rows={5}
            />

            {/* Size */}
            <Grid container spacing={1} justifyContent="left" alignItems="left" padding={2}>
              <FormGroup>
                <Typography variant="body2">Select Image Size</Typography>
                <RadioGroup
                  row
                  value={size}
                  onChange={(e) => setSize(e.target.value)}
                >
                  <FormControlLabel value="small" control={<Radio />} label="Small (512x512)" />
                  <FormControlLabel value="medium" control={<Radio />} label="Medium (1024x1024)" />
                  <FormControlLabel value="large" control={<Radio />} label="Large (1792x1024)" />
                </RadioGroup>
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {/* Cancel button */}
        <Button onClick={onClose} color="secondary" variant="contained">
          Cancel
        </Button>

        {/* Generate/Regenerate button */}
        <Button onClick={handleGenerateClick} color="primary" variant="contained">
          {generatedImageUrl ? 'Regenerate Image' : 'Generate Image'}
        </Button>

        {/* Save button (only active when an image has been generated) */}
        {generatedImageUrl && (
          <Button onClick={handleSaveClick} color="success" variant="contained">
            Use Image
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ImageModal;