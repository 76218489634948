import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import newslettersService from 'services/newsletters';
import { INewsletter } from 'types/newsletters';

interface NewsletterSelectProps {
  value: INewsletter | null;
  onChange: (newsletter: INewsletter | null) => void;
}

const NewsletterSelect: React.FC<NewsletterSelectProps> = ({ value, onChange }) => {
  const [newsletters, setNewsletters] = useState<INewsletter[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNewsletters = async () => {
      try {
        const response = await newslettersService.fetchAll();
        setNewsletters(response.data);
      } catch (error) {
        console.error('Error fetching newsletters:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNewsletters();
  }, []);

  return (
    <Autocomplete
      options={newsletters}
      getOptionLabel={(option) => option.name}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Newsletter"
          variant="outlined"
          fullWidth
        />
      )}
      loading={loading}
    />
  );
};

export default NewsletterSelect;
