import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "context/Auth";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { loading, isAuthenticated, hasSubscription } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (!hasSubscription && window.location.pathname !== '/plans') {
    return <Navigate to="/plans" />;
  }

  return children;
};

export default ProtectedRoute;
