import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import citiesService from "services/cities";
import { AxiosResponse } from "axios";
import { ICityData } from "types/cities.d";
import LoadingOverlay from "components/LoadingOverlay";
import BasicLayout from "components/Layouts/BasicLayout";

const City: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [city, setCity] = useState<ICityData>(undefined);
  const [thumbnailError, setThumbnailError] = useState<any>("");

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  //fetch specific city if URL has city's id
  useEffect(() => {
    const fetchCity = async () => {
      try {
        setLoading(true);
        const fetchedCity = await citiesService.fetch(id);
        setCity(fetchedCity);
      } catch (e) {
        console.error("Error fetching event:", e);
      } finally {
        setLoading(false);
      }
    };
    if (id && id !== "create") {
      fetchCity();
    }
  }, [id]);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      if (!acceptedFiles?.length) {
        setThumbnailError("Thumbnail must be a file");
        return;
      }
      setThumbnailError(null);
      setLoading(true);
      const newFiles = [];
      for (let file of acceptedFiles) {
        const response: AxiosResponse = await citiesService.upload(file);
        newFiles.push(response);
      }
      if (newFiles.length === 0) return setLoading(false);
      setCity({
        ...city,
        thumbnail: newFiles[0].data.url,
      });
      setLoading(false);
    },
    [city]
  );

  const { getRootProps: getRootPropsPoster, getInputProps: getInputPropsPoster } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: onDrop,
  });

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!city.title || !city.thumbnail) {
        throw new Error("Please fill in all fields");
      }
      if (id === "create") {
        city.createdBy = user;
        city.createdAt = new Date().toISOString();
        await citiesService.create(city); 
      } else {
        city.updatedAt = new Date().toISOString();
        city.updatedBy = user;
        await citiesService.update(city.id, city);
      }
      //Redirect to listings if no errors
      navigate("/cities");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await citiesService.remove(id);
      navigate("/cities");
    } catch (error) {
      console.error("Error deleting city:", error);
    }
  };
  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">City</Typography>
        </Grid>

        <Grid item xs={12}>
          {error && <Typography color="error">{error}</Typography>}
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormLabel htmlFor="description">Thumbnail</FormLabel>
          {thumbnailError && <Typography color="error">{thumbnailError}</Typography>}
          <div
            {...getRootPropsPoster()}
            style={{
              width: "100%",
              height: "200px",
              border: "1px solid #ccc",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ...(city?.thumbnail && {
                backgroundImage: `url(${city?.thumbnail})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }),
            }}
          >
            <input {...getInputPropsPoster()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="name">Title</FormLabel>
            <TextField
              id="title"
              value={city?.title || ""}
              onChange={(e) => setCity({ ...city, title: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
        {id !== "create" && (
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" color="alert">
                  Danger Zone
                </Typography>
                <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        <LoadingOverlay loading={loading} />
      </Grid>
    </BasicLayout>
  );
};

export default City;
