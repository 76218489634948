import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import LoadingOverlay from "components/LoadingOverlay";
import { useGlobalContext } from "context/GlobalContext";
import BasicLayout from "components/Layouts/BasicLayout";
import dataSourcesService from "services/dataSources";
import { IDataSource } from "types/dataSource.d";
import DataSourceForm from "components/DataSourceForm/DataSourceForm";
import PaginatedDataGrid from "components/PaginatedDataGrid/PaginatedDataGrid";
import logsService from "services/logs";
import { ILog } from "types/logs.d";
import { GridCellParams } from "@mui/x-data-grid";

const DataSource: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dataSource, setDataSource] = useState<IDataSource | undefined>(undefined);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { loading: contextLoading } = useGlobalContext();

  useEffect(() => {
    const fetchDataSource = async () => {
      try {
        const dataSource = await dataSourcesService.fetch(id);
        setDataSource(dataSource);
      } catch (e) {
        console.error("Error fetching data source:", e);
      }
    };
    if (id && id !== "create") {
      fetchDataSource();
    }
  }, [id]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const newDataSource: IDataSource = {
        ...dataSource,
        updatedBy: user?.uid,
        updatedDate: new Date().toISOString(),
      };

      if (!newDataSource.type || !newDataSource.dataType) {
        throw new Error("Please fill in all required fields");
      }

      if (id === "create") {
        newDataSource.createdBy = user?.uid;
        newDataSource.createdDate = new Date().toISOString();
        await dataSourcesService.create(newDataSource);
      } else {
        await dataSourcesService.update(newDataSource.id, newDataSource);
      }
      setDataSource(newDataSource);
      navigate("/data-sources");
    } catch (error: any) {
      console.error("Error saving data source:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await dataSourcesService.remove(id);
      navigate("/data-sources");
    } catch (error) {
      console.error("Error deleting data source:", error);
    }
  };

  // Add this constant for the label style
  const labelStyle = {
    color: 'rgba(0, 0, 0, 0.87)', // Or any color you prefer
    '&.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.87)', // Same color as non-focused state
    },
  };

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Data Source</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <DataSourceForm dataSource={dataSource} onComplete={handleSave} onCancel={() => navigate(-1)} />
        </Grid>
        <LoadingOverlay loading={contextLoading || loading} />
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <PaginatedDataGrid
            collectionName="logs"
            service={logsService}
            columns={[
              {
                field: 'dataSource', headerName: 'Data Source', flex: 1, renderCell: (params: GridCellParams) => {
                  return <Link to={`/data-sources/${params.row.dataSource._id}`}>{params.row.dataSource.parentId?.name || params.row.dataSource.parentId?.title || params.row.dataSource._id}</Link>
                }
              },
              { field: 'level', headerName: 'Level', flex: 1 },
              { field: 'message', headerName: 'Message', flex: 1 },
              { field: 'timestamp', headerName: 'Timestamp', flex: 1 },
              {
                field: 'metadata', headerName: 'Metadata', flex: 1, renderCell: (params: GridCellParams) => {
                  return <pre>{JSON.stringify(params.row.metadata, null, 2)}</pre>
                }
              },
            ]}
            defaultQuery={[
              {
                field: 'organization._id',
                operator: 'eq',
                value: user?.organization?.id,
              },
              {
                field: 'dataSource._id',
                operator: 'eq',
                value: id,
              }
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          {id && id !== "create" && (
            <Grid item xs={12}>
              <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                Delete
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default DataSource;
