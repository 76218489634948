import React from 'react';
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  createFilterOptions,
  FormControl,
  FormLabel,
  IconButton,
  TextField,
  Typography,
  Grid,
  Box,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardContent
} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { ChevronLeft } from "@mui/icons-material";
import BasicLayout from "components/Layouts/BasicLayout";
import LoadingOverlay from "components/LoadingOverlay";
import { useAuth } from "context/Auth";
import { IRole } from "types/roles";
import { IOrganization } from "types/organizations";
import organizationsService from "services/organizations";
import TwilioPhoneNumbers from '../TwilioPhoneNumbers/TwilioPhoneNumbers';
import Image from "components/Images/Image";
import stripeService from 'services/stripe';
import { IImage } from 'types/image';
import { IUser } from 'types/users';
import UserFormModal from 'components/UserForm/UserFormModal';
import usersService from 'services/users';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface SubscriptionData {
  id: string;
  status: string;
  current_period_end: string;
  plan: {
    name: string;
    amount: number;
  };
}

interface PlanData {
  product: {
    id: string;
    name: string;
    description?: string;
  };
  prices: {
    id: string;
    unit_amount: number;
  }[];
}

const Organization: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const { user } = useAuth();
  const [roles, setRoles] = useState<IRole[]>([]);
  const [organization, setOrganization] = useState<IOrganization | undefined>(undefined);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const [subscription, setSubscription] = useState<SubscriptionData | null>(null);
  const [plans, setPlans] = useState<PlanData[]>([]);
  const [showPlansModal, setShowPlansModal] = useState(false);
  const { refreshUser } = useAuth();
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);

  useEffect(() => {
    if (user?.roles) {
      setRoles(user.roles);
    }
  }, [user?.roles]);

  const filterOptions = createFilterOptions({
    ignoreCase: true,
  });

  const renderRolesOptionLabel = (option: any) => {
    const role = roles?.find((role: any) => role.id === option);
    if (role) {
      return role?.title;
    }
    return option || "";
  };

  const fetchOrganization = useCallback(async () => {
    setLoading(true);
    try {
      const response = await organizationsService.fetch(user?.organization);
      setOrganization(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [user]);

  const handleSave = async () => {

    console.log(organization)
    try {
      setLoading(true);

      if (!organization?.name) {
        throw new Error("Please fill in all required fields");
      }
      await organizationsService.update(organization.id, organization);
      navigate('/');
    } catch (error: any) {
      console.error("Error saving organization:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (userId: string) => {
    // Prevent self-deletion
    if (userId === user?.id) {
      alert("You cannot remove yourself from the organization");
      return;
    }

    try {
      setLoading(true);
      // Update the user directly through userService
      await usersService.update(userId, { isActive: false });
      // Refresh organization data to get updated users list
      await fetchOrganization();
    } catch (error) {
      console.error("Error updating user status:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.organization) {
      fetchOrganization();
    }
  }, [fetchOrganization, user]);

  const fetchSubscription = useCallback(async () => {
    try {
      if (!user?.subscription?.id) {
        return;
      }
      const response = await stripeService.getSubscription(user?.subscription?.id);
      setSubscription(response);
    } catch (error) {
      console.error('Error fetching subscription:', error);
    }
  }, [user]);

  const fetchPlans = useCallback(async () => {
    try {
      const response = await stripeService.getPlans();
      setPlans(response);
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  }, []);

  useEffect(() => {
    if (user?.organization) {
      fetchOrganization();
      fetchSubscription();
      fetchPlans();
    }
  }, [fetchOrganization, fetchSubscription, fetchPlans, user]);

  const handleCancelSubscription = async () => {
    if (!subscription) return;

    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      try {
        setLoading(true);
        await stripeService.cancelSubscription(subscription.id);
        await fetchSubscription(); // Refresh subscription data
        await refreshUser(); // Refresh user data to update subscription status
      } catch (error) {
        console.error('Error canceling subscription:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePlanChange = async (priceId: string) => {
    if (!subscription) return;

    try {
      setLoading(true);
      await stripeService.updateSubscription(subscription.id, { priceId });
      await fetchSubscription(); // Refresh subscription data
      await refreshUser(); // Refresh user data
      setShowPlansModal(false);
    } catch (error) {
      console.error('Error updating subscription:', error);
      setError('Failed to update subscription');
    } finally {
      setLoading(false);
    }
  };

  const handleAddUser = () => {
    setIsUserModalOpen(true);
  };

  const handleCloseUserModal = () => {
    setSelectedUser(null);
    setIsUserModalOpen(false);
    fetchOrganization();
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Button
            variant="contained"
            onClick={() => {
              setSelectedUser(params.row);
              setIsUserModalOpen(true);
            }}
            color="primary"
            size="small"
            style={{ marginRight: '8px' }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            onClick={() => handleDeleteUser(params.row.id)}
            color="error"
            size="small"
          >
            Remove
          </Button>
        </>
      ),
    },
  ];
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const PlansModal = () => (
    <Dialog
      open={showPlansModal}
      onClose={() => setShowPlansModal(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Change Subscription Plan</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          {[...plans].sort((a, b) => a.prices[0].unit_amount - b.prices[0].unit_amount).map((plan) => (
            <Grid item xs={12} sm={6} key={plan.product.id}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flex: 1,
                  bgcolor: subscription?.plan?.name === plan.product.name ? 'primary.light' : 'background.paper'
                }}
              >
                <CardContent>
                  <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                    <Box>
                      <Typography variant="h6" component="div" gutterBottom>
                        {plan.product.name}
                      </Typography>
                      <Typography variant="h4" color="primary" gutterBottom>
                        ${(plan.prices[0].unit_amount / 100).toFixed(2)}/month
                      </Typography>
                      {plan.product.description && (
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                          {plan.product.description}
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Button
                        className="items-end"
                        variant="contained"
                        fullWidth
                        disabled={subscription?.plan?.name === plan.product.name}
                        onClick={() => handlePlanChange(plan.prices[0].id)}
                      >
                        {subscription?.plan?.name === plan.product.name ? 'Current Plan' : 'Select Plan'}
                      </Button>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );

  const activeUsers = organization?.users?.filter(user => user.isActive) ?? [];

  return (
    <BasicLayout>
      <Typography variant="h4" gutterBottom>
        Organization
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="organization tabs">
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Phone Numbers" {...a11yProps(1)} />
          <Tab label="Subscription" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} display="flex" alignItems="center">
            <IconButton onClick={() => navigate(-1)}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="h4">Organization</Typography>
          </Grid>

          {/* Organization Details Form */}
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>Organization Details</Typography>
            <FormControl fullWidth margin="normal">
              <FormLabel required htmlFor="org-name">Name</FormLabel>
              <TextField
                id="org-name"
                value={organization?.name || ""}
                onChange={(e) => setOrganization({ ...organization, name: e.target.value })}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="org-description">Description</FormLabel>
              <TextField
                id="org-description"
                multiline
                rows={4}
                value={organization?.description || ""}
                onChange={(e) => setOrganization({ ...organization, description: e.target.value })}
              />
            </FormControl>
            {/* New API Key field */}
            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="org-api-key">API Key</FormLabel>
              <TextField
                id="apiKey"
                type="text"
                value={organization?.apiKey || ""}
                onChange={(e) => setOrganization({ ...organization, apiKey: e.target.value })}
              // InputProps={{
              //   readOnly: true,
              // }}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="org-image">Image</FormLabel>
              <Image
                onAccept={(image: IImage) => setOrganization({ ...organization, image })}
                src={organization?.imageUrl}
                alt={organization?.name}
                bucketPath="cityview-cms/organizations"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h5">Users</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddUser}
              >
                Add User
              </Button>
            </Box>
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={activeUsers || []}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
              />
            </div>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={loading}
          style={{ marginTop: '16px' }}
        >
          Save Organization
        </Button>
        <LoadingOverlay loading={loading} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TwilioPhoneNumbers />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Subscription Details
            </Typography>
            {subscription ? (
              <Box>
                <Typography gutterBottom>
                  ID: {subscription.id}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Current Plan: {subscription.plan.name}
                </Typography>
                <Typography gutterBottom>
                  Status: {subscription.status}
                </Typography>
                <Typography gutterBottom>
                  Amount: ${(subscription.plan.amount / 100).toFixed(2)}/month
                </Typography>
                <Typography gutterBottom>
                  Current Period Ends: {new Date(subscription.current_period_end).toLocaleDateString()}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowPlansModal(true)}
                    sx={{ mr: 2 }}
                  >
                    Change Plan
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCancelSubscription}
                  >
                    Cancel Subscription
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box>
                <Typography gutterBottom>
                  No active subscription found.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/plans')}
                >
                  View Plans
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <PlansModal />
      <Dialog open={isUserModalOpen} onClose={handleCloseUserModal}>
        <DialogTitle>Add New User</DialogTitle>
        <UserFormModal
          open={isUserModalOpen}
          onClose={handleCloseUserModal}
          organizationId={organization?.id || ''}
          selectedUser={selectedUser}
        />
      </Dialog>
    </BasicLayout>
  );
};

export default Organization;
