import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import eventsService from "services/events";
import BasicLayout from "components/Layouts/BasicLayout";
import { capitalizeFirstLetter } from "utils/commonFunctions";

const Events: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "defaultImage",
      headerName: "",
      width: 70,
      renderCell: (params: any) =>
        params.value?.url ? (
          <img src={params?.value?.url} alt="Event" style={{ width: "50px" }} />
        ) : (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{ fontSize: "50px !important", fontWeight: 100 }}
          >
            image
          </Icon>
        ),
      sortable: false,
    },
    { field: "title", headerName: "Title", flex: 2 },
    {
      field: "location",
      headerName: "Location",
      flex: 2,
      renderCell: (params: any) => <span>{params?.value?.name}</span>,
    },
    {
      field: "startDate",
      headerName: "Start",
      renderCell: (params: any) => {
        const d = new Date(params?.value);
        return <span>{d.toLocaleDateString()}</span>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params: any) => <>{capitalizeFirstLetter(params?.value)}</>,
    },
    {
      field: "totalRsvps",
      headerName: "RSVPs",
      flex: 1,
      width: 10,
    },
    {
      field: "totalAttendees",
      headerName: "Attendees",
      flex: 1,
      width: 10,
    },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Events</Typography>
        </Grid>
        <Grid item xs={2} sm={4}></Grid>
        <Grid item xs={6} sm={4} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/events/create">
            <Icon>add</Icon>
            Create Event
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              collectionName="events"
              defaultSortField="start"
              defaultSortDirection="desc"
              columns={columns}
              service={eventsService}
              filterOptions={[
                {
                  label: "Title",
                  name: "title",
                  type: "text",
                  operator: "search",
                  columnWidthMap: { xs: 12, sm: 12, md: 6, lg: 4 },
                },
                {
                  label: "Location",
                  name: "location.name",
                  type: "text",
                  operator: "search",
                  columnWidthMap: { xs: 12, sm: 12, md: 6, lg: 4 },
                },
                {
                  label: "Start Date",
                  name: "start",
                  type: "date",
                  operator: "gte",
                  columnWidthMap: { xs: 12, sm: 12, md: 6, lg: 4 },
                },
                {
                  label: "End Date",
                  name: "end",
                  type: "date",
                  operator: "lte",
                  columnWidthMap: { xs: 12, sm: 12, md: 6, lg: 4 },
                },
                {
                  label: "Status",
                  name: "status",
                  type: "select",
                  options: ['draft', 'published', 'cancelled'].map((value) => ({
                    label: capitalizeFirstLetter(value),
                    value,
                  })),
                  operator: "eq",
                  columnWidthMap: { xs: 12, sm: 12, md: 6, lg: 4 },
                },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Events;
