import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Grid,
  TextField,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  Box,
  Icon,
  FormHelperText,
  IconButton,
} from "@mui/material";

import { SwipeLeft } from "@mui/icons-material";
import TagMultiSelect from "components/TagMultiSelect";
import VideoSelect from "components/VideoSelect";
import LocationSelect from "components/LocationSelect";

import Thumbnail from "components/Thumbnail";
import { IEvent } from "types/events.d";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import eventsService from "services/events";
import AITextField from "components/AITextField";
import { useAuth } from "context/Auth";

interface ApproveSwipperProps {
  events: any[];
  onClose?: () => void;
  onOpen?: () => void;
}

export default function ApproveSwipper(props: ApproveSwipperProps) {
  const { onClose = () => null } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [events, setEvents] = useState<any[]>([]);
  const [open, setOpen] = useState<any>(null);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const [event, setEvent] = useState<IEvent | null>(null);

  const { user } = useAuth();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const events = await eventsService.fetchAll({
          query: [
            { field: "status", operator: 'eq', value: "pending" },
          ],
          pageSize: 1000,
        });
        if (events.data.length) {
          setEvent(events.data[0]);
        }
        setEvents(events.data);
      } catch (error: any) {
        console.error("Error fetching events", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
    if (open) {
      fetchEvents();
    }
  }, [open]);

  useEffect(() => {
    if (events.length) {
      setEvent(events[currentEventIndex]);
    }
  }, [currentEventIndex, events]);

  const handleApprove = async () => {
    try {
      setLoading(true);
      await eventsService.update(event.id, {
        updatedBy: user,
        updatedDate: new Date(),
        status: "published"
      })
      setEvents((prevEvents) => prevEvents.filter((e) => e.id !== event.id));
      next();
    } catch (error: any) {
      console.error('Error approving event:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  const handleReject = async () => {
    try {
      setLoading(true);
      await eventsService.remove(event.id);
      setEvents((prevEvents) => prevEvents.filter((e) => e.id !== event.id));
      next();
    } catch (error: any) {
      console.error('Error rejecting event:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  const next = () => {
    if (currentEventIndex < events.length - 1) {
      setCurrentEventIndex(currentEventIndex + 1);
    } else {
      setCurrentEventIndex(0);
    }
  }

  const previous = () => {
    if (currentEventIndex > 0) {
      setCurrentEventIndex(currentEventIndex - 1);
    }
  }

  const handleClose = () => {
    setOpen(null);
    setCurrentEventIndex(0);
    setEvent(null);
    onClose();
  }

  const eventIsValid = useMemo(() => {
    return event?.title && event?.description && event?.location && event?.thumbnail && event?.link;
  }, [event]);
  
  return (
    <>
      <Button variant="outlined" color="secondary" onClick={() => setOpen(true)}>
        <SwipeLeft color="secondary" style={{ width: 40 }} />
      </Button>
      <Dialog open={!!open} onClose={() => setOpen(null)} maxWidth="lg" fullWidth>
        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            {event?.title}
            <Link to={`/events/${event?.id}`}>
              <Icon>open_in_new</Icon>
            </Link>
          </Box>
          <IconButton onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {
            !event
              ? <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress color="inherit" />
              </Box>
              : <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <Thumbnail
                    imageUrl={event?.thumbnail || ""}
                    imageStyles={{ width: "100%", height: 300 }}
                    onChange={(url) => {
                      setEvent((prevEvent) => ({ ...prevEvent, thumbnail: url as string }));
                    }}
                  />

                  <FormControl fullWidth>
                    <FormLabel>Tags</FormLabel>
                    <TagMultiSelect
                      selectedTags={event?.tags}
                      setSelectedTags={(tags) => setEvent((pe: any) => ({ ...pe, tags }))}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <FormLabel required>Location</FormLabel>
                    <LocationSelect
                      value={event?.location}
                      onChange={(location) => setEvent((pe: any) => ({ ...pe, location }))}
                    />
                    <FormHelperText>Hint from bot: {event?.locationName}</FormHelperText>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormLabel>Video</FormLabel>
                    <VideoSelect
                      value={event?.defaultVideo}
                      onChange={(video) => setEvent((pe: any) => ({ ...pe, defaultVideo: video }))}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth style={{ marginBottom: 6 }}>
                    <FormLabel required>Title</FormLabel>
                    <TextField
                      value={event?.title || ""}
                      id="title"
                      variant="outlined"
                      onChange={(e) => setEvent((pe: any) => ({ ...pe, title: e.target.value }))}
                    />
                  </FormControl>
                  <FormControl fullWidth style={{ marginBottom: 6 }}>
                    <AITextField
                      id="description"
                      label="Description"
                      prompt="From the following details, create a description of an event. The title should be descriptive and engaging and be at least 3 sentences long."
                      promptMode="modal"
                      value={event?.description || ""}
                      multiline
                      rows={4}
                      onChange={(val: any) => setEvent({ ...event, description: val })}
                    />
                  </FormControl>
                  <FormControl fullWidth style={{ marginBottom: 6 }}>
                    <FormLabel>Price</FormLabel>
                    <TextField
                      value={event?.price || ""}
                      id="price"
                      variant="outlined"
                      onChange={(e) => setEvent((pe: any) => ({ ...pe, price: e.target.value }))}
                    />
                  </FormControl>
                  <FormControl required fullWidth style={{ marginBottom: 6 }}>
                    <FormLabel>
                      Link
                      {event?.link && <a href={event?.link} target="_blank" rel="noreferrer">
                        <Icon>open_in_new</Icon>
                      </a>}
                    </FormLabel>
                    <TextField
                      value={event?.link || ""}
                      id="link"
                      variant="outlined"
                      onChange={(e) => setEvent((pe: any) => ({ ...pe, link: e.target.value }))}
                    />
                  </FormControl>
                  <FormGroup>
                    <FormLabel id="status-group-label">Monitization Options</FormLabel>
                    <FormControlLabel
                      htmlFor="featured"
                      label={"Featured"}
                      control={
                        <Checkbox
                          checked={event?.featured || false}
                          onChange={(e) => setEvent({ ...event, featured: e.target.checked })}
                        />
                      }
                    />
                    <FormControlLabel
                      htmlFor="boosted"
                      label={"Boosted"}
                      control={
                        <Checkbox
                          checked={event?.boosted || false}
                          onChange={(e) => setEvent({ ...event, boosted: e.target.checked })}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
              </Grid>
          }
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          {
            loading
              ? <Box style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                <CircularProgress color="inherit" />
              </Box>
              : <>
                <Button variant="contained" color="secondary" size="large" onClick={handleReject}>
                  Reject
                </Button>
                <Box>
                  <Button disabled={!currentEventIndex} variant="contained" color="secondary" size="large" onClick={previous}>
                    Prev
                  </Button>
                  <Button variant="contained" color="secondary" size="large" onClick={next}>
                    Next
                  </Button>
                </Box>
                <Button disabled={!eventIsValid} variant="contained" color="primary" size="large" onClick={handleApprove}>
                  {!eventIsValid && <Icon>error</Icon>}
                  Approve
                </Button>
              </>
          }
        </DialogActions>
      </Dialog>
    </>
  )
}