import React from 'react';
import { FormControl, FormLabel, TextField, Select, MenuItem, Checkbox, FormControlLabel, Button, SelectChangeEvent, IconButton, Grid, Radio, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { IFormField, IFormFieldType, IFieldOption } from 'types/forms.d';

interface FormFieldProps {
  field: IFormField;
  onChange: (updatedField: IFormField) => void;
  onDelete: () => void;
}

const FormField: React.FC<FormFieldProps> = ({ field, onChange, onDelete }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    onChange({ ...field, [name]: value });
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    onChange({ ...field, type: e.target.value as IFormFieldType });
  };

  const handleRequiredChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...field, required: e.target.checked });
  };

  const handleOptionChange = (index: number, key: 'value' | 'label', newValue: string) => {
    const updatedOptions = [...field.options];
    updatedOptions[index] = { ...updatedOptions[index], [key]: newValue };
    onChange({ ...field, options: updatedOptions });
  };

  const handleAddOption = () => {
    const updatedOptions = [...(field.options || []), { value: '', label: '' }];
    onChange({ ...field, options: updatedOptions });
  };

  const handleDeleteOption = (index: number) => {
    const updatedOptions = field.options.filter((_, i) => i !== index);
    onChange({ ...field, options: updatedOptions });
  };

  return (
    <Grid container style={{ border: "solid 1px #9d9d9d", borderRadius: "10px", padding: "10px", backgroundColor: "#f0f0f0"  }}>
      <Grid item xs={12} display="flex" justifyContent="flex-end" style={{ height: 2 }}>
        <IconButton style={{ backgroundColor: "gray", width: 20, height: 20 }} size="small" onClick={onDelete}>
          <CloseIcon fontSize="small" style={{ color: "white" }} />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth margin="normal">
              <FormLabel>Field Name</FormLabel>
              <TextField
                name="name"
                value={field.name}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth margin="normal">
              <FormLabel>Field Label</FormLabel>
              <TextField
                name="label"
                value={field.label}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth margin="normal">
              <FormLabel>Field Type</FormLabel>
              <Select
                name="type"
                value={field.type}
                onChange={handleSelectChange}
              >
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="number">Number</MenuItem>
                <MenuItem value="date">Date</MenuItem>
                <MenuItem value="select">Select</MenuItem>
                <MenuItem value="radio">Radio</MenuItem>
                <MenuItem value="checkbox">Checkbox</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth margin="normal">
              <FormLabel>&nbsp;</FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.required}
                    onChange={handleRequiredChange}
                  />
                }
                label="Required"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      {['select', 'radio', 'checkbox'].includes(field.type) && (
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <FormLabel style={{ marginBottom: 8 }}>
              Options
              <IconButton
                onClick={handleAddOption}
                style={{ border: "solid 1px #9d9d9d", borderRadius: '50%', padding: "3px", marginLeft: 2 }}
                size="small"
              >
                <AddIcon />
              </IconButton>
            </FormLabel>
            <Grid container spacing={2}>
              {field.options?.map((option, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        value={option.value}
                        onChange={(e) => handleOptionChange(index, 'value', e.target.value)}
                        placeholder="Value"
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        value={option.label}
                        onChange={(e) => handleOptionChange(index, 'label', e.target.value)}
                        placeholder="Label"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => handleDeleteOption(index)} size="small">
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>

          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default FormField;
