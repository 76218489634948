import React from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import { IFilterOptions } from "types/paginatedDataGrid";
import BasicLayout from "components/Layouts/BasicLayout";
import rolesService from "services/roles";

const Roles: React.FC = () => {
  const columns: GridColDef[] = [
    { field: "title", headerName: "Title", flex: 1, minWidth: 200 },
    { field: "description", headerName: "Description", flex: 1, minWidth: 200 },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={4} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Roles</Typography>
        </Grid>
        <Grid item xs={8} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/roles/create">
            <Icon>add</Icon>
            Create New Role
          </Button>
        </Grid>
        <Grid item xs={12}>
          <PaginatedDataGrid
            collectionName="roles"
            defaultSortField="title"
            defaultSortDirection="desc"
            columns={columns}
            service={rolesService}
          />
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Roles;
