import React, { useState } from 'react';
import { IImage } from '../../types/image';
import {
  Grid,
  Card,
  CardMedia,
  CardActions,
  Checkbox,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface ImageGalleryProps {
  images: IImage[];
  onDelete?: (imageId: string) => void;
  onBulkDelete?: (imageIds: string[]) => void;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images, onDelete, onBulkDelete }) => {
  const [selectedImages, setSelectedImages] = useState<Set<string>>(new Set());
  const [viewingImage, setViewingImage] = useState<IImage | null>(null);

  const handleImageClick = (image: IImage) => {
    setViewingImage(image);
  };

  const handleCheckboxChange = (image: IImage) => {
    const newSelectedImages = new Set(selectedImages);
    if (newSelectedImages.has(image.id)) {
      newSelectedImages.delete(image.id);
    } else {
      newSelectedImages.add(image.id);
    }
    setSelectedImages(newSelectedImages);
  };

  const handleDelete = (image: IImage) => {
    onDelete && onDelete(image.id);
    setSelectedImages(prev => {
      const newSet = new Set(prev);
      newSet.delete(image.id);
      return newSet;
    });
  };

  const handleBulkDelete = () => {
    onBulkDelete && onBulkDelete(Array.from(selectedImages));
    setSelectedImages(new Set());
  };


  console.log(images)
  return (
    <Box sx={{ width: '100%' }}>
      {onBulkDelete && images && images.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleBulkDelete}
            disabled={selectedImages.size === 0}
            startIcon={<DeleteIcon />}
          >
            Delete Selected ({selectedImages.size})
          </Button>
        </Box>
      )}
      <Grid container spacing={2}>
        {images.map(image => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={image.id}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={image.url}
                alt={`Image ${image.id}`}
                onClick={() => handleImageClick(image)}
                sx={{ cursor: 'pointer' }}
              />
              <CardActions disableSpacing sx={{ justifyContent: 'space-between' }}>
                <Checkbox
                  checked={selectedImages.has(image.id)}
                  onChange={() => handleCheckboxChange(image)}
                />
                {onDelete && (
                  <IconButton onClick={() => handleDelete(image)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={viewingImage !== null}
        onClose={() => setViewingImage(null)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          {viewingImage && (
            <img
              src={viewingImage.url}
              alt={`Viewing ${viewingImage.id}`}
              style={{ width: '100%', height: 'auto' }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ImageGallery;
