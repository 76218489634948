import { API_HOST } from "../config";
import axios from "axios";
import TokenHandler from "./base";

const service = "auth";

async function me() {
  const token = TokenHandler.getToken();
  const response = await axios.get(`${API_HOST}/${service}/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function register(props: any) {
  const response = await axios.post(`${API_HOST}/${service}/register`, props);
  console.log(response.data);
  TokenHandler.setToken(response.data.token);
  return response.data;
}

async function login(email: string, password: string) {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("password", password);

  const response = await axios.post(
    `${API_HOST}/${service}/login`,
    {
      email,
      password,
    },
    {
      headers: {
        ContentType: "application/json",
      },
    }
  );
  TokenHandler.setToken(response.data.token);
  return response.data;
}

async function logout() {
  return TokenHandler.removeToken();
}

const authService = { me, fetch, register, login, logout };

export default authService;
