import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarContainer } from '@mui/x-data-grid';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { IDataSource } from 'types/dataSource.d';
import dataSourcesService from 'services/dataSources';
import DataSourceForm from '../DataSourceForm/DataSourceForm';

interface DataSourceListProps {
  dataSources: IDataSource[];
  parentId: string;
  parentType: 'organization' | 'location' | 'group';
  onDataSourceChange: () => void;
}

const DataSourceList: React.FC<DataSourceListProps> = ({ dataSources, parentId, parentType, onDataSourceChange }) => {
  const [open, setOpen] = useState(false);
  const [editingDataSource, setEditingDataSource] = useState<IDataSource | null>(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditingDataSource(null);
  };

  const handleEdit = (dataSource: IDataSource) => {
    setEditingDataSource(dataSource);
    handleOpen();
  };

  const handleDelete = async (id: string) => {
    try {
      await dataSourcesService.remove(id);
      onDataSourceChange();
    } catch (error) {
      console.error("Error deleting data source:", error);
    }
  };

  const handleFormComplete = () => {
    onDataSourceChange();
    handleClose();
  };

  const columns: GridColDef[] = [
    { field: 'type', headerName: 'Type', flex: 1 },
    { field: 'url', headerName: 'URL', flex: 2 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconButton aria-label="edit" onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => handleDelete(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        rows={dataSources}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{
          toolbar: () => (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" startIcon={<AddIcon />} onClick={handleOpen} sx={{ m: 1 }}>
                Add Data Source
              </Button>
            </GridToolbarContainer>
          ),
        }}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingDataSource ? 'Edit Data Source' : 'Add Data Source'}</DialogTitle>
        <DataSourceForm
          dataSource={editingDataSource}
          parentId={parentId}
          parentType={parentType}
          onComplete={handleFormComplete}
          onCancel={handleClose}
        />
      </Dialog>
    </>
  );
};

export default DataSourceList;
