import axios from 'axios';
import { API_HOST } from '../config';
import TokenHandler from './base';
import { IPaginationModal } from 'types/App';
import { IPhoneNumber } from 'types/phoneNumbers';

const phoneNumbersService = {
  fetchAvailable: async (params: { areaCode?: string; zipCode?: string }) => {
    const token = TokenHandler.getToken();
    const response = await axios.get(`${API_HOST}/phoneNumbers/available`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  fetchAll: async (paginationModel: IPaginationModal = {}) => {
    const token = TokenHandler.getToken();
    const filteredPagination = { ...paginationModel };
    delete filteredPagination.query;
    delete filteredPagination.sort;

    const url = new URL(`${API_HOST}/phoneNumbers`);
    url.search = new URLSearchParams(filteredPagination).toString();

    const params = {
      ...(paginationModel?.query && { query: paginationModel.query }),
      ...(paginationModel?.sort && { sort: paginationModel.sort }),
    };

    const response = await axios.get(url.toString(), {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  create: async (data: IPhoneNumber) => {
    const token = TokenHandler.getToken();
    const response = await axios.post(`${API_HOST}/phoneNumbers`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  update: async (id: string, data: Partial<IPhoneNumber>) => {
    const token = TokenHandler.getToken();
    const response = await axios.put(`${API_HOST}/phoneNumbers/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  remove: async (id: string) => {
    const token = TokenHandler.getToken();
    const response = await axios.delete(`${API_HOST}/phoneNumbers/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },
};

export default phoneNumbersService;
