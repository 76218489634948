import {
  Autocomplete,
  createFilterOptions,
  FormLabel,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
} from "@mui/material";
import { INews } from "types/news";
import { useGlobalContext } from "context/GlobalContext";
import { StatusEnum } from "types/events.d";
import PreviewArticleModal from "./PreviewArticleModal";
import TipTap from "components/TipTap";
import debounce from "lodash/debounce";
import React, { useState } from "react";
import Image from "components/Images/Image";
import { IImage } from "types/image";
import TagMultiSelect from "components/TagMultiSelect/TagMultiSelect";
const NewsArticle = ({
  news,
  setNews,
}: {
  news: Partial<INews>;
  setNews: (news: Partial<INews> | ((prevNews: Partial<INews>) => Partial<INews>)) => void;
}) => {
  const { tags, tagGroups } = useGlobalContext();

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 10,
  });

  const handleOnAccept = (image: IImage) => {
    setNews((prevNews) => ({
      ...prevNews,
      defaultImage: image
    }));
  };

  return (
    <Grid container className="NewsArticle" spacing={2} >
      <Grid item xs={12} className="NewsArticle__header"> {/* Header for container */}
        <Typography variant="h4">Article</Typography>
      </Grid>
      <Grid item xs={12} sm={5} padding={2} className="NewsArticle__image">
        <Image
          onAccept={handleOnAccept}
          src={news?.defaultImage?.url}
          alt={news?.title}
          bucketPath="cityview-cms/news"
          context={news?.article || news?.prompt}
        />
      </Grid>
      <Grid item xs={12} sm={7} className="NewsArticle__image">
        <Grid container spacing={2}>
          <Grid item xs={12} className="NewsArticle__header">
            <FormControl fullWidth>
              <Typography variant="h6">Title</Typography>
              <TextField
                id="title"
                value={news?.title || ""}
                onChange={(e: any) => setNews({ ...news, title: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className="NewsArticle__header">
            <FormControl fullWidth>
              <Typography variant="h6">Subtitle</Typography>
              <TextField
                id="subtitle"
                value={news?.subtitle || ""}
                onChange={(e: any) => setNews({ ...news, subtitle: e.target.value })}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} className="NewsArticle__summary">
            <FormControl fullWidth>
              <Typography variant="h6">Summary</Typography>
              <TextField
                id="summary"
                multiline
                rows={4}
                value={news?.summary || ""}
                onChange={(e: any) => setNews({ ...news, summary: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}></Grid>

          <Grid item xs={12} className="NewsArticle__author">
            <Typography variant="h6">Author</Typography>
            <TextField id="author" disabled value={news?.author?.name || ""} fullWidth />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel htmlFor="tags">Tags</FormLabel>
              <TagMultiSelect
                selectedTags={news?.tags || []}
                setSelectedTags={(tags) => {
                  setNews({
                    ...news,
                    tags
                  });
                }}
                type={["news", "tag"]}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={news?.featured || false}
                  onChange={(e: any) => setNews({ ...news, featured: e.target.checked })}
                />
              }
              label="Featured"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={news?.trending || false}
                  onChange={(e: any) => setNews({ ...news, trending: e.target.checked })}
                />
              }
              label="Trending"
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormLabel id="status-group-label">Publishing Status</FormLabel>
              <RadioGroup
                row
                aria-labelledby="status-group-label"
                name="row-radio-buttons-group"
                value={news?.status}
                onChange={(e) => setNews({ ...news, status: e.target.value as StatusEnum })}
              >
                <FormControlLabel value={StatusEnum.Pending} control={<Radio />} label="Pending" />
                <FormControlLabel
                  value={StatusEnum.Published}
                  control={<Radio />}
                  label="Published"
                />
              </RadioGroup>
            </FormGroup>
          </Grid>
          {news?.video?.url && (
            <Grid item xs={12}>
              <Typography variant="h6">Video</Typography>
              <video controls width="600">
                <source src={news?.video?.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12} className="NewsArticle__content">
          <FormControl fullWidth>
            <Grid
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              marginBottom={2}
              alignItems={"flex-end"}
            >
              <Typography variant="h6">Content (HTML)</Typography>

            </Grid>
            <Grid item xs={12}>
              <TipTap
                content={news.content}
                onChange={debounce((value: JSON) => setNews({ ...news, content: value }), 300)}
                bucketPath="cityview-cms/news"
              />
            </Grid>
            <Grid item xs={12}
              sx={{ display: "flex", justifyContent: "flex-end", paddingTop: 2 }}>
              {news.content && <PreviewArticleModal news={news} />} {/* Preview Article Modal Button*/}
            </Grid>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewsArticle; 
