import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";
import formsService from "services/forms";
import BasicLayout from "components/Layouts/BasicLayout";

const Forms: React.FC = () => {
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 2, minWidth: 200 },
    { field: "description", headerName: "Description", flex: 2, minWidth: 200 },
    {
      field: "submitAction",
      headerName: "Submit Action",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "createdDate",
      headerName: "Created",
      minWidth: 200,
      renderCell: (params: any) => {
        const d = new Date(params?.value);
        return <span>{d.toLocaleString()}</span>;
      },
    },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Forms</Typography>
        </Grid>
        <Grid item xs={2} sm={4}></Grid>
        <Grid item xs={6} sm={4} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/forms/create">
            <Icon>add</Icon>
            Create Form
          </Button>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              collectionName="forms"
              defaultSortField="createdDate"
              defaultSortDirection="desc"
              columns={columns}
              service={formsService}
              filterOptions={[
                {
                  label: "Name",
                  name: "name",
                  type: "text",
                  operator: "search",
                },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default Forms;
