import React from 'react';
import { Grid, FormControl, FormLabel, TextField } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { IEvent } from 'types/events.d';

interface EventOccurrenceFormProps {
  event: IEvent;
  onChange: (updatedEvent: Partial<IEvent>) => void;
}

const EventOccurrenceForm: React.FC<EventOccurrenceFormProps> = ({ event, onChange }) => {
  const handleDateChange = (field: 'startDate' | 'endDate') => (date: dayjs.Dayjs | null) => {
    if (date) {
      onChange({ [field]: date.toDate() });
    }
  };

  const handleTimeChange = (field: 'startTime' | 'endTime') => (time: dayjs.Dayjs | null) => {
    if (time) {
      onChange({ [field]: time.format('HH:mm') });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel htmlFor="start-date">Start Date</FormLabel>
          <DatePicker
            value={dayjs(event.startDate)}
            onChange={handleDateChange('startDate')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
      {event.eventType === 'multi' && <FormControl fullWidth>
          <FormLabel htmlFor="end-date">End Date</FormLabel>
          <DatePicker
            value={dayjs(event.endDate)}
            onChange={handleDateChange('endDate')}
          />
        </FormControl>}
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel htmlFor="start-time">Start Time</FormLabel>
          <TimePicker
            value={dayjs(`2000-01-01T${event.startTime}`)}
            onChange={handleTimeChange('startTime')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel htmlFor="end-time">End Time</FormLabel>
          <TimePicker
            value={dayjs(`2000-01-01T${event.endTime}`)}
            onChange={handleTimeChange('endTime')}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default EventOccurrenceForm;
