import { IService } from "../types/Utils";
import { API_HOST, API_VERSION } from "../config";
import axios from "axios";
import TokenHandler from "./base";

async function eventObjectsFromJSON(url: string, key?: string) {
  const token = TokenHandler.getToken();

  const path = new URL(`${API_HOST}/utils/event-objects-from-json`);

  const response = await axios.post(
    path.toString(),
    { url, key },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

async function eventObjectsFromImage({
  file,
  prompt,
  metadata,
}: {
  file: File;
  prompt: string;
  metadata: { [key: string]: any };
}) {
  const token = TokenHandler.getToken();

  const path = new URL(`${API_HOST}/utils/event-objects-from-image`);
  const formData = new FormData();
  formData.append("file", file);
  formData.append("prompt", prompt);
  formData.append("metadata", JSON.stringify(metadata));

  const response = await axios.post(path.toString(), formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

const openAIService: IService = {
  eventObjectsFromJSON,
  eventObjectsFromImage,
};

export default openAIService;
