import { API_HOST } from "../config";
import axios from "axios";
import TokenHandler from "./base";
import { IPaginationModal } from "types/App";
import { INewsletter } from '../types/newsletters';

async function fetchAll(paginationModel: IPaginationModal = {}) {
  const token = TokenHandler.getToken();
  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/newsletters`);
  url.search = new URLSearchParams(filteredPagination).toString();

  const params = {
    ...(paginationModel?.query && { query: paginationModel.query }),
    ...(paginationModel?.sort && { sort: paginationModel.sort }),
    includeDrafts: true,
  };
  const response = await axios.get(url.toString(), {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function fetch(id: string) {
  const token = TokenHandler.getToken();
  const response = await axios.get(`${API_HOST}/newsletters/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function create(data: Partial<INewsletter>) {
  const token = TokenHandler.getToken();
  const response = await axios.post(`${API_HOST}/newsletters`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function update(id: string, data: Partial<INewsletter>) {
  const token = TokenHandler.getToken();
  const response = await axios.put(`${API_HOST}/newsletters/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function remove(id: string) {
  const token = TokenHandler.getToken();
  const response = await axios.delete(`${API_HOST}/newsletters/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function generate(data: { prompt: string }) {
  const token = TokenHandler.getToken();
  const response = await axios.post(`${API_HOST}/newsletters/generate`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function exportNewsletters(paginationModel: IPaginationModal = {}) {
  const token = TokenHandler.getToken();
  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/newsletters/export`);
  url.search = new URLSearchParams(filteredPagination).toString();

  const params = {
    ...(paginationModel?.query && { query: paginationModel.query }),
    ...(paginationModel?.sort && { sort: paginationModel.sort }),
  };

  const response = await axios.get(url.toString(), {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

const newslettersService = {
  fetch,
  fetchAll,
  create,
  update,
  remove,
  generate,
  export: exportNewsletters,
};

export default newslettersService;
