import React, { useState, useEffect } from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  TextField,
  Autocomplete
} from '@mui/material';
import { IUser } from 'types/users';
import usersService from 'services/users';
import { useAuth } from 'context/Auth';
import { IRole } from 'types/roles';
import rolesService from "services/roles";

interface UserFormModalProps {
  open: boolean;
  onClose: () => void;
  organizationId?: string;
  selectedUser: IUser | null;
}

type PartialUser = Partial<IUser> & {
  email: string;
  name: string;
  phoneNumber: string;
  roles: IRole[];
  isActive: boolean;
};

const UserFormModal: React.FC<UserFormModalProps> = ({ open, onClose, organizationId, selectedUser }) => {

  const [roles, setRoles] = useState<IRole[]>(undefined);
  const [userData, setUserData] = useState<PartialUser>({
    email: '',
    name: '',
    phoneNumber: '',
    roles: [],
    isActive: false,
  });

  const { user: currentUser } = useAuth();

  useEffect(() => {
    if (selectedUser) {
      setUserData({
        email: selectedUser.email,
        name: selectedUser.name,
        phoneNumber: selectedUser.phoneNumber,
        roles: selectedUser.roles,
        isActive: selectedUser.isActive,
      });
    }
  }, [selectedUser]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const _roles: { data: IRole[] } = await rolesService.fetchAll();
        setRoles(_roles.data);
      } catch (e) {
        console.error("Error fetching roles:", e);
      }
    };

    if (!roles) {
      fetchRoles();
    }
  }, [roles]);

  const renderRolesOptionLabel = (option: any) => {
    const role = roles?.find((role: any) => role.id === option);
    if (role) {
      return role?.title;
    }
    return option || "";
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    setUserData(prev => ({ ...prev, [name as string]: value }));
  };

  const handleSubmit = async () => {
    try {
      if (selectedUser) {
        const updatedUserData = {
          ...userData,
          organization: selectedUser.organization
        };
        await usersService.update(selectedUser.id!, updatedUserData);
      } else {
        const newUser = {
          ...userData,
          createdBy: currentUser?.uid,
          createdDate: new Date(),
          organization: organizationId,
          isActive: true,
        };
        await usersService.create(newUser);
      }
      onClose();
    } catch (error) {
      console.error("Error saving user:", error);
    }
  };

  return (
    <>
      <DialogContent>
        <FormControl fullWidth margin="dense">
          <FormLabel>Email *</FormLabel>
          <TextField
            margin="dense"
            name="email"
            type="email"
            fullWidth
            value={userData?.email}
            onChange={handleChange}
            required
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <FormLabel>Name *</FormLabel>
          <TextField
            margin="dense"
            name="name"
            type="text"
            fullWidth
            value={userData?.name}
            onChange={handleChange}
            required
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <FormLabel>Phone Number *</FormLabel>
          <TextField
            margin="dense"
            name="phoneNumber"
            type="tel"
            fullWidth
            value={userData?.phoneNumber}
            onChange={handleChange}
            required
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel htmlFor="tags">Roles</FormLabel>
          <Autocomplete
            multiple
            value={userData?.roles || []}
            onChange={(_e, value) => {
              setUserData({
                ...userData,
                roles: value,
              });
            }}
            options={roles ? roles?.map((role: any) => role.id) : []}
            getOptionLabel={renderRolesOptionLabel}
            renderInput={(params) => <TextField {...params} />}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
      </DialogActions>
    </>
  );
};

export default UserFormModal;