import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import PaginatedDataGrid from "components/PaginatedDataGrid";

import { IFilterOptions } from "types/paginatedDataGrid";
import BasicLayout from "components/Layouts/BasicLayout";
import qrcodesService from "services/qrcodes";
import { QRCodeType } from 'types/qrcodes.d';

const QRCodes: React.FC = () => {
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", minWidth: 200, flex: 1 },
    { field: "code", headerName: "Code", minWidth: 200, flex: 1 },
    { field: "url", headerName: "URL", minWidth: 200, flex: 2 },
    { 
      field: "type", 
      headerName: "Type", 
      minWidth: 120, 
      flex: 1,
      renderCell: (params) => (
        <span style={{ color: params.value === QRCodeType.SYSTEM ? 'blue' : 'green' }}>
          {params.value === QRCodeType.SYSTEM ? 'System' : 'User'}
        </span>
      )
    },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={5} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">QR Codes</Typography>
        </Grid>
        <Grid item xs={7} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/qrcodes/create">
            <Icon>add</Icon>
            Create QR Code
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <PaginatedDataGrid
              collectionName="qrcodes"
              defaultSortField="createdAt"
              defaultSortDirection="desc"
              columns={columns}
              service={qrcodesService}
            />
          </Box>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default QRCodes;