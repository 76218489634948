import React from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Icon, Typography } from "@mui/material";
import BasicLayout from "components/Layouts/BasicLayout";
import NewsLetterList from "components/NewsLetterList";

const Newsletters: React.FC = () => {
  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4} display={"flex"} alignItems={"center"}>
          <Typography variant="h4">Newsletters</Typography>
        </Grid>
        <Grid item xs={12} sm={8} display={"flex"} justifyContent="flex-end" alignItems={"flex-end"}>
          <Button variant="contained" color="primary" component={Link} to="/newsletters/create">
            <Icon>add</Icon>
            Create New Newsletter
          </Button>
        </Grid>
        <NewsLetterList />
      </Grid>
    </BasicLayout>
  );
};

export default Newsletters;
