import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography, 
  TextField,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import LoadingOverlay from "components/LoadingOverlay";
import { useGlobalContext } from "context/GlobalContext";
import BasicLayout from "components/Layouts/BasicLayout";
import Image from "components/Images/Image";

import groupsService from "services/groups";
import { IGroup } from "types/groups.d";
import dataSourcesService from "services/dataSources";
import { IDataSource } from "types/dataSource.d";
import DataSourceList from 'components/DataSourceList/DataSourceList';
import { IImage } from "types/image.d";

const Group: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [group, setGroup] = useState<IGroup | undefined>(undefined);
  const [dataSources, setDataSources] = useState<IDataSource[]>([]);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { loading: contextLoading, refetchGroups } = useGlobalContext();

  useEffect(() => {
    const fetchGroup = async () => {
      try {
        const group = await groupsService.fetch(id);
        setGroup(group);
      } catch (e) {
        console.error("Error fetching event:", e);
      }
    };
    if (id && id !== "create") {
      fetchGroup();
    }
  }, [id]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const newGroup: IGroup = {
        ...group,
        updatedBy: user?.uid,
        updatedAt: new Date().toISOString(),
      };

      if (!newGroup.name || !newGroup.website) {
        throw new Error("Please fill in all fields");
      }
      if (id === "create") {
        // TODO Check to make sure it doesn't already exist
        // const q = query(collection(getFirestore(), firestoreCollection), where("placeId", "==", newLocation.placeId));
        // const locationsSnapshot = await getDocs(q);
        // if(locationsSnapshot.docs.length > 0) {
        //   throw new Error('Location already exists');
        // }
        newGroup.createdBy = user;
        newGroup.createdAt = new Date().toISOString();
        await groupsService.create(newGroup);
      } else {
        newGroup.updatedBy = user;
        newGroup.updatedAt = new Date().toISOString();
        await groupsService.update(newGroup.id, newGroup);
      }
      refetchGroups();
      navigate("/groups");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await groupsService.remove(id);
      refetchGroups();
      navigate("/groups");
    } catch (error) {
      console.error("Error deleting listing:", error);
    }
  };

  const handleDataSourceChange = async () => {
    try {
      const group = await groupsService.fetch(id);
      setGroup(group);
      const response = await dataSourcesService.fetchAll();
      setDataSources(response.data);
    } catch (e) {
      console.error("Error fetching updated data:", e);
    }
  };

  return (
    <BasicLayout>
      <Grid container direction={"column"} spacing={4}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Group</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} marginTop={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '600px '}}>
              <Image
                onAccept={(image: IImage) => setGroup({ ...group, image })}
                src={group?.logo}
                alt={group?.name}
                bucketPath="cityview-cms/groups"
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <TextField
                      id="name"
                      value={group?.name || ""}
                      onChange={(e) => setGroup({ ...group, name: e.target.value })}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="description">Description</FormLabel>
                    <TextField
                      id="description"
                      multiline
                      rows={4}
                      value={group?.description || ""}
                      onChange={(e) =>
                        setGroup({ ...group, description: e.target.value })
                      }
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="link">Website</FormLabel>
                    <TextField
                      id="link"
                      value={group?.website || ""}
                      onChange={(e) =>
                        setGroup({ ...group, website: e.target.value })
                      }
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="google-calendar-link">Google Calendar Link</FormLabel>
                    <TextField
                      id="google-calendar-link"
                      value={group?.googleCalendarLink || ""}
                      onChange={(e) =>
                        setGroup({ ...group, googleCalendarLink: e.target.value })
                      }
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="instagram">Instagram</FormLabel>
                    <TextField
                      id="instagram"
                      value={group?.instagram || ""}
                      onChange={(e) =>
                        setGroup({ ...group, instagram: e.target.value })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="facebook">Facebook</FormLabel>
                    <TextField
                      id="facebook"
                      value={group?.facebook || ""}
                      onChange={(e) =>
                        setGroup({ ...group, facebook: e.target.value })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="twitter">Twitter</FormLabel>
                    <TextField
                      id="twitter"
                      value={group?.twitter || ""}
                      onChange={(e) =>
                        setGroup({ ...group, twitter: e.target.value })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                  <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
                    Save
                  </Button>
                </Grid>                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            {id && id !== "create" && (
              <Grid item xs={12}>
                <Typography variant="h4" color="alert">
                  Danger Zone
                </Typography>
                <Button disabled={loading} variant="contained" color="error" onClick={handleDelete}>
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Data Sources</Typography>
          <DataSourceList
            dataSources={dataSources}
            parentId={group?.id || ''}
            parentType="group"
            onDataSourceChange={handleDataSourceChange}
          />
        </Grid>
        <LoadingOverlay loading={contextLoading || loading} />
      </Grid>
    </BasicLayout>
  );
};

export default Group;
