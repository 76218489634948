import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Box, Typography } from '@mui/material';
import stripeService from '../../services/stripe';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context/Auth';

interface Props {
  priceId: string;
  userId: string;
  amount: number;
  currency: string;
  onSuccess: () => void;
}

const StripeCheckoutForm = ({ priceId, userId, amount, currency, onSuccess }: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { refreshUser } = useAuth();
  const [error, setError] = React.useState<string | null>(null);
  const [processing, setProcessing] = React.useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);
    
    try {
      // 1. Create payment method
      const { error: pmError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)!,
      });

      if (pmError) {
        throw new Error(pmError.message);
      }

      // 2. Create subscription with payment method
      const { subscriptionId, clientSecret } = await stripeService.createSubscription({
        priceId,
        userId,
        paymentMethodId: paymentMethod.id,
      });
      
      if (clientSecret) {
        const { error: confirmError } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethod.id,
        });
        if (confirmError) {
          throw new Error(confirmError.message);
        }
      }

      // 4. Update user data and redirect
      await refreshUser();
      onSuccess();
      navigate('/', { 
        state: { 
          message: 'Subscription activated successfully!' 
        }
      });
    } catch (e: any) {
      setError(e.message || 'An unexpected error occurred');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 2 }}>
        <CardElement />
      </Box>
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <Button
        type="submit"
        variant="contained"
        fullWidth
        disabled={!stripe || processing}
      >
        {processing ? 'Processing...' : 'Subscribe'}
      </Button>
    </form>
  );
};

export default StripeCheckoutForm;