import React, { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Grid , Box, Typography} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useAuth } from "../../context/Auth";
import { FormControl, InputLabel, TextField } from "@mui/material";
import Logo from "assets/images/logos/logo_blue.png";
import darrenFaulknerImg from "assets/images/darren-faulkner-3FzYpZjE5Ec-unsplash.jpg";
import LoadingOverlay from "components/LoadingOverlay";
import { Link as MUILink } from "@mui/material";
// Authentication layout components
import LoginSignUp from "components/Layouts/LoginSignUp";

function Cover(): JSX.Element {
  const navigate = useNavigate();
  
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<Error | null>(null);

  const { register, loading, isAuthenticated } = useAuth();

  const submitDisabled = useMemo(() => loading || !email || !password || !confirmPassword, [email, password, confirmPassword, loading]);

  const handleSignup = async () => {
    if (password !== confirmPassword) {
      setError(new Error("Passwords do not match"));
      return;
    }
    try {
      await register({ name, email, password });
      console.log("User registered and logged in successfully");
    } catch (error: any) {
      setError(error); 
      console.error(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <Grid container height={"100vh"}>
      <Grid item xs={12} md={4} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <LoginSignUp>
          <Grid item>
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
                {loading ?
                  <LoadingOverlay />
                  : <img
                    key={"title"}
                    src={Logo}
                    width={300}
                    alt={"CityView CMS Logo"}
                  />}
            </Box>
            <Grid item xs={12} marginTop={2}>
              <Typography variant="h4" sx={{ textAlign: "center" }}>
                Create An Account
              </Typography>
              <Typography variant="body2" sx={{ textAlign: "center"}}>
                Already have an account?{" "}
                <MUILink component={Link} to="/login" color="blue">
                  Login Here
                </MUILink>
              </Typography>
            </Grid>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!submitDisabled) {
                  handleSignup();
                }
              }}
            >
              <MDBox pt={4} pb={3} px={12}>
                <Grid container justifyContent="center">
                  {error && (
                    <Grid item xs={12}>
                      <MDTypography style={{ textAlign: "center" }} variant="body2" color="error">
                        {error.message}
                      </MDTypography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="body2">Name</Typography>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} marginTop={2}>
                    <Typography variant="body2">Email</Typography>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} marginTop={2}>
                    <Typography variant="body2">Password</Typography>
                    <FormControl fullWidth>
                      <TextField
                        type="password"
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} marginTop={2}>
                    <Typography variant="body2"> Confirm Password</Typography>
                    <FormControl fullWidth>
                      <TextField
                        type="password"
                        variant="outlined"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} marginTop={2}>
                    <MDButton
                      disabled={submitDisabled}
                      type="submit"
                      variant="contained"
                      color="info"
                      fullWidth
                    >
                      Sign Up
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </form>
          </Grid>
        </LoginSignUp>
      </Grid>
      <Grid item md={8} sx={{
        backgroundImage: `url(${darrenFaulknerImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }} />
    </Grid>
  );
}

export default Cover;
