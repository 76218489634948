import React, { useState, useCallback } from 'react';
import {
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import { debounce } from 'lodash';
import groupsService from '../../services/groups';

interface Group {
  _id: string;
  name: string;
}

interface GroupMultiSelectProps {
  selectedGroups: Group[];
  setSelectedGroups: (groups: Group[]) => void;
}

const GroupMultiSelect: React.FC<GroupMultiSelectProps> = ({ selectedGroups, setSelectedGroups }) => {
  const [options, setOptions] = useState<Group[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchGroups = async (query: string) => {
    setLoading(true);
    try {
      const response = await groupsService.fetchAll({ query, limit: 10 });
      setOptions(response.data);
    } catch (error) {
      console.error('Error fetching groups:', error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchGroups = useCallback(
    debounce((query: string) => fetchGroups(query), 300),
    []
  );

  const handleInputChange = (_event: React.SyntheticEvent, value: string) => {
    debouncedFetchGroups(value);
  };

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option.name}
      value={selectedGroups}
      onChange={(_event, newValue) => setSelectedGroups(newValue)}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Groups"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option._id === value._id}
    />
  );
};

export default GroupMultiSelect;
