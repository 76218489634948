import React, { useCallback } from "react";  
import { useDropzone } from "react-dropzone";
import { upload } from "services/images";

interface ThumbnailProps {
  imageUrl: string;
  onChange: (file: File | string) => void;
  bucketPath?: string;
  imageStyles?: React.CSSProperties;
}

const Thumbnail: React.FC<ThumbnailProps> = ({ imageUrl = "", onChange, imageStyles = {} }) => {
  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      if (acceptedFiles.length === 0) return;
      for (let file of acceptedFiles) {
        const image = await upload(file, "Tumbnails");
        onChange(image.url);
      }
    },
    [onChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  
  return (
    <div {...getRootProps()} className={`thumbnail ${isDragActive ? "drag-active" : ""}`}>
      <input {...getInputProps()} />
      {imageUrl ? (
        <div
          style={{
            width: "100px",
            height: "100px",
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: "cover",
            ...imageStyles
          }}
        />
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: 150,
            border: "1px solid grey",
            borderRadius: 10,
            padding: "20% 10px",
          }}
        >
          <p style={{ textAlign: "center" }}>
            Drag and drop an image here, or click to select an image.
          </p>
        </div>
      )}
    </div>
  );
};

export default Thumbnail;
