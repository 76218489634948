/**
 * A global context to store filters.
 */
import React, { createContext, useState, useMemo, ReactNode } from "react";

import { IFilterOptions } from "types/paginatedDataGrid";

export interface IGlobalFilter {
  [collectionName: string]: IFilterOptions[];
}

export const GlobalFilterContext = createContext<{
  filterValues: IGlobalFilter[];
  setFilterValues: any;
}>(null);

export const GlobalFilterContextProvider = ({ children }: { children: ReactNode }) => {
  const [filterValues, setFilterValues] = useState<IGlobalFilter[]>([]);

  const value = useMemo(() => {
    return {
      filterValues,
      setFilterValues,
    };
  }, [filterValues]);

  return <GlobalFilterContext.Provider value={value}>{children}</GlobalFilterContext.Provider>;
};

export const useGlobalFilterContext = () => React.useContext(GlobalFilterContext);
