import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Autocomplete,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Typography,
  TextField,
  Divider,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useAuth } from "context/Auth";
import locationsService from "services/locations";
import { ILocationData } from "types/locations.d";
import getGoogleMapsApiClient from "utils/googlemaps";
import LoadingOverlay from "components/LoadingOverlay";
import { useGlobalContext } from "context/GlobalContext";
import BasicLayout from "components/Layouts/BasicLayout";
import dataSourcesService from "services/dataSources";
import { IDataSource } from "types/dataSource.d";
import DataSourceList from 'components/DataSourceList/DataSourceList';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link as LinkIcon, Visibility, Delete } from '@mui/icons-material';
import { formatDate } from 'utils/commonFunctions';
import eventsService from "services/events";
import Image from "components/Images/Image";
import FileDropzone from "components/FileDropzone";
import ImageGallery from "components/ImageGallery";
import { IImage } from "types/image";
import TagMultiSelect from "components/TagMultiSelect/TagMultiSelect";

const Location: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchLoading, setSearchLoading] = useState<any>(false);
  const [searchText, setSearchText] = useState<any>("");
  const [location, setLocation] = useState<ILocationData | undefined>(undefined);
  const [dataSources, setDataSources] = useState<IDataSource[]>([]);
  const [activeTab, setActiveTab] = useState(0);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { loading: contextLoading, videos, refetchLocations } = useGlobalContext();

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const event = await locationsService.fetch(id);
        setLocation(event);
      } catch (e) {
        console.error("Error fetching event:", e);
      }
    };
    if (id && id !== "create") {
      fetchLocation();
    }
  }, [id]);

  useEffect(() => {
    const fetchDataSources = async () => {
      try {
        const response = await dataSourcesService.fetchAll();
        setDataSources(response.data);
      } catch (e) {
        console.error("Error fetching data sources:", e);
      }
    };
    fetchDataSources();
  }, [id]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const { events, ...restLocation } = location;
      const newLocation: ILocationData = {
        ...restLocation,
        updatedBy: user?.uid,
        updatedAt: new Date().toISOString(),
        status: "published",
        source: "admin",
        formattedAddress: `${location.address1}, ${location.city}, ${location.state} ${location.zip}`,
      };

      if (!newLocation.name || !newLocation.thumbnail || !newLocation.placeId) {
        throw new Error("Please fill in all fields");
      }
      if (id === "create") {
        // TODO Check to make sure it doesn't already exist
        // const q = query(collection(getFirestore(), firestoreCollection), where("placeId", "==", newLocation.placeId));
        // const locationsSnapshot = await getDocs(q);
        // if(locationsSnapshot.docs.length > 0) {
        //   throw new Error('Location already exists');
        // }
        newLocation.createdBy = user;
        newLocation.createdAt = new Date().toISOString();
        await locationsService.create(newLocation);
      } else {
        newLocation.updatedBy = user;
        newLocation.updatedAt = new Date().toISOString();
        await locationsService.update(newLocation.id, newLocation);
      }
      refetchLocations();
      navigate("/locations");
    } catch (error: any) {
      console.error("Error saving listing:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await locationsService.remove(id);
      refetchLocations();
      navigate("/locations");
    } catch (error) {
      console.error("Error deleting listing:", error);
    }
  };

  const handleSearch = async () => {
    const placesApi = await getGoogleMapsApiClient();
    if (searchText.trim() !== "") {
      try {
        setSearchLoading(true);
        const responseJson = await placesApi.maps.places.Place.searchByText({
          fields: ["displayName", "formattedAddress", "photos", "websiteURI", "types"],
          query: searchText,
        });
        const { places: candidates } = responseJson;
        if (candidates.length > 0) {
          const candidate = candidates[0];
          console.log("candidate", JSON.stringify(candidate, null, 2));
          let thumbnail;
          if (candidate.photos?.length) {
            thumbnail = candidate.photos[0].getURI({ maxWidth: 400 });
          }
          console.log("candidate", JSON.stringify(candidate, null, 2));
          const googleLocation = {
            placeId: candidate.id,
            name: candidate.displayName,
            formattedAddress: candidate.formattedAddress,
            address1: candidate.formattedAddress.split(",")[0]?.trim(),
            city: candidate.formattedAddress.split(",")[1]?.trim(),
            state: candidate.formattedAddress.split(",")[2].split(" ")[1]?.trim() || null,
            zip: candidate.formattedAddress.split(",")[2].split(" ")[2]?.trim() || null,
            thumbnail,
            link: candidate.websiteURI,
            types: candidate.types,
          };
          setLocation({
            ...location,
            ...googleLocation,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSearchLoading(false);
      }
    }
  };

  const handleDataSourceChange = async () => {
    try {
      const updatedLocation = await locationsService.fetch(id);
      setLocation(updatedLocation);
      const response = await dataSourcesService.fetchAll();
      setDataSources(response.data);
    } catch (e) {
      console.error("Error fetching updated data:", e);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleViewEvent = (eventId: string) => {
    navigate(`/events/${eventId}`);
  };

  const handleDeleteEvent = async (eventId: string) => {
    try {
      await eventsService.remove(eventId);
      // Refresh the location data to update the events list
      const updatedLocation = await locationsService.fetch(id);
      setLocation(updatedLocation);
    } catch (error) {
      console.error("Error deleting event:", error);
      setError("Failed to delete event");
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'thumbnail',
      headerName: 'Image',
      width: 100,
      renderCell: (params) => (
        <img src={params.value} alt={params.row.title} style={{ width: '100%', height: 'auto' }} />
      ),
    },
    { field: 'title', headerName: 'Title', flex: 2 },
    {
      field: 'start',
      headerName: 'Start',
      width: 200,
      valueFormatter: (params) => formatDate(new Date(params.value)),
    },
    { field: 'price', headerName: 'Price', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => window.open(params.row.link, '_blank')}
            size="small"
          >
            <LinkIcon />
          </IconButton>
          <IconButton
            onClick={() => handleViewEvent(params.row.id)}
            size="small"
          >
            <Visibility />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <BasicLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} display={"flex"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">Location</Typography>
        </Grid>
        <Grid item xs={12}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Events" {...a11yProps(1)} />
            <Tab label="Data Sources" {...a11yProps(2)} />
            <Tab label="Images" {...a11yProps(3)} />
            <Tab label="Video" {...a11yProps(4)} />
            <Tab label="Admin" {...a11yProps(5)} />
          </Tabs>
          <Box sx={{ mt: 2 }}>
            {activeTab === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  {location?.thumbnail && (
                    <Grid item xs={12}>
                      <Image
                        src={location?.thumbnail}
                        alt={`Location thumbnail for ${location?.name}`}
                        onAccept={(image: IImage) => {
                          setLocation({ ...location, defaultImage: image });
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={2}>
                    {!location?._id && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <FormLabel htmlFor="name">Search Google for Location</FormLabel>
                          <TextField
                            id="searchText"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                          <br />
                          <Button
                            disabled={searchLoading}
                            variant="contained"
                            color="primary"
                            onClick={handleSearch}
                          >
                            Search
                          </Button>
                        </FormControl>

                        <Divider />
                        <Divider />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="name">Name</FormLabel>
                        <TextField
                          id="name"
                          value={location?.name || ""}
                          onChange={(e) => setLocation({ ...location, name: e.target.value })}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="link">Link</FormLabel>
                        <TextField
                          id="link"
                          value={location?.link || ""}
                          onChange={(e) => setLocation({ ...location, link: e.target.value })}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="facebook">Facebook</FormLabel>
                        <TextField
                          id="facebook"
                          value={location?.facebook || ""}
                          onChange={(e) => setLocation({ ...location, facebook: e.target.value })}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="instagram">Instagram</FormLabel>
                        <TextField
                          id="instagram"
                          value={location?.instagram || ""}
                          onChange={(e) => setLocation({ ...location, instagram: e.target.value })}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="address1">Address1</FormLabel>
                        <TextField
                          id="address1"
                          value={location?.address1 || ""}
                          onChange={(e) => setLocation({ ...location, address1: e.target.value })}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="city">City</FormLabel>
                        <TextField
                          id="city"
                          value={location?.city || ""}
                          onChange={(e) => setLocation({ ...location, city: e.target.value })}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="state">State</FormLabel>
                        <TextField
                          id="state"
                          value={location?.state || ""}
                          onChange={(e) => setLocation({ ...location, state: e.target.value })}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="zip">Zip</FormLabel>
                        <TextField
                          id="zip"
                          value={location?.zip || ""}
                          onChange={(e) => setLocation({ ...location, zip: e.target.value })}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="tags">Tags</FormLabel>
                        <TagMultiSelect
                          selectedTags={location?.tags || []}
                          setSelectedTags={(tags) => {
                            setLocation({
                              ...location,
                              tags
                            });
                          }}
                          type={["location", "tag"]}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {activeTab === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {location && location?.events && location.events.length > 0 ? (
                    <DataGrid
                      rows={location.events}
                      columns={columns}
                      paginationModel={{ page: 0, pageSize: 10 }}
                      autoHeight
                      disableRowSelectionOnClick
                    />
                  ) : (
                    <Typography>No events</Typography>
                  )}
                </Grid>
              </Grid>
            )}
            {activeTab === 2 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">Data Sources</Typography>
                  <DataSourceList
                    dataSources={dataSources}
                    parentId={location?.id || ''}
                    parentType="location"
                    onDataSourceChange={handleDataSourceChange}
                  />
                </Grid>
              </Grid>
            )}
            {activeTab === 3 && (
              <Box sx={{ pt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ImageGallery
                      images={location?.images || []}
                      onDelete={(imageId: string) => {
                        setLocation({
                          ...location,
                          images: location?.images?.filter((image: IImage) => image.id !== imageId),
                        });
                      }}
                      onBulkDelete={(imageIds: string[]) => {
                        setLocation({
                          ...location,
                          images: location?.images?.filter((image: IImage) => !imageIds.includes(image.id)),
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FileDropzone
                      accept={{
                        "image/jpeg": [],
                        "image/png": [],
                      }}
                      onComplete={(result: any[]) => {
                        setLocation({
                          ...location,
                          images: [...(location.images || []), ...result],
                        });
                      }}
                      onUpload={(image: IImage) => {
                        setLocation({
                          ...location,
                          images: [...(location.images || []), image],
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
            {activeTab === 4 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">Video</Typography>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="video">Select Video</FormLabel>
                    <Autocomplete
                      value={location?.video?.id || ""}
                      onChange={(e, value) =>
                        setLocation({
                          ...location,
                          video: videos.find((video: any) => video.id === value),
                        })
                      }
                      options={videos.map((video: any) => video.id)}
                      getOptionLabel={(option) =>
                        videos?.find((video) => video.id === option)?.title || option
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {activeTab === 5 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">Admin</Typography>
                  {id && id !== "create" && (
                    <>
                      <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                        Danger Zone
                      </Typography>
                      <Button
                        disabled={loading}
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                        sx={{ mt: 1 }}
                      >
                        Delete Location
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button disabled={loading} variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Grid>
        <LoadingOverlay loading={searchLoading || contextLoading || loading} />
      </Grid>
    </BasicLayout>
  );
};

export default Location;
