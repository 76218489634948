// Declaring props types for Sidenav
import { ReactNode } from 'react';
import Box from '@mui/material/Box';

interface Props {
  children?: ReactNode;
}

const SingleComponent: React.FC<Props> = ({ children }: Props) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'start', alignContent: 'center', minHeight: '100vh' }}>
      {children}
    </Box>
  )
}

export default SingleComponent;
