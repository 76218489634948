import { API_HOST, API_VERSION } from "../config";
import { IAuthor } from "types/authors";
import axios from "axios";
import TokenHandler from "./base";
import { IPaginationModal } from "types/App";

async function fetchAll(paginationModel: IPaginationModal = {}) {
  const token = TokenHandler.getToken();
  const filteredPagination = { ...paginationModel };
  delete filteredPagination.query;
  delete filteredPagination.sort;

  const url = new URL(`${API_HOST}/news`);
  url.search = new URLSearchParams(filteredPagination).toString();

  const params = {
    ...(paginationModel?.query && { query: paginationModel.query }),
    ...(paginationModel?.sort && { sort: paginationModel.sort }),
    includeDrafts: true,
  };
  const response = await axios.get(url.toString(), {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log("here", response.data);
  return response.data;
}

async function fetch(id: string) {
  const token = TokenHandler.getToken();
  const response = await axios.get(`${API_HOST}/news/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function create(data: any) {
  const token = TokenHandler.getToken();
  const response = await axios.post(`${API_HOST}/news`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function update(id: string, data: any) {
  const token = TokenHandler.getToken();
  const response = await axios.put(`${API_HOST}/news/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function remove(id: string) {
  const token = TokenHandler.getToken();
  const response = await axios.delete(`${API_HOST}/news/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function generate(data: { prompt: string; author: IAuthor; image: string }) {
  const token = TokenHandler.getToken();
  const response = await axios.post(`${API_HOST}/news/generate`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

async function build() {
  return await axios.post("https://api.netlify.com/build_hooks/660ea4b95558d1183144ea97");
}

const newsService = {
  fetch,
  fetchAll,
  create,
  update,
  remove,
  generate,
  build,
};

export default newsService;
  